<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="20" height="20" rx="10" fill="#02117F"/>
    <g clip-path="url(#clip0_6047_10258)">
      <path d="M16.1402 5.95625L12.0596 9.98284L16.1402 14.0094C16.2538 14.1158 16.3443 14.2444 16.4062 14.3872C16.4681 14.5301 16.5 14.6841 16.5 14.8398C16.5 14.9955 16.4681 15.1495 16.4062 15.2923C16.3443 15.4351 16.2538 15.5637 16.1402 15.6701C16.034 15.7755 15.908 15.8589 15.7695 15.9155C15.631 15.9722 15.4828 16.0008 15.3332 16C15.035 15.9987 14.7492 15.8803 14.5375 15.6701L10.4569 11.5867L6.43321 15.6701C6.22149 15.8803 5.93574 15.9987 5.63755 16C5.48796 16.0008 5.33967 15.9722 5.20118 15.9155C5.0627 15.8589 4.93674 15.7755 4.83053 15.6701C4.61883 15.457 4.5 15.1687 4.5 14.8682C4.5 14.5677 4.61883 14.2794 4.83053 14.0663L8.85427 9.98284L4.83053 5.95625C4.64432 5.73866 4.54701 5.45876 4.55806 5.17249C4.56911 4.88622 4.6877 4.61466 4.89013 4.41209C5.09256 4.20951 5.36393 4.09084 5.64999 4.07978C5.93606 4.06872 6.21576 4.1661 6.43321 4.35244L10.4569 8.37903L14.4807 4.35244C14.5859 4.2434 14.7116 4.15617 14.8505 4.09573C14.9894 4.03528 15.1389 4.00282 15.2903 4.00018C15.4418 3.99753 15.5923 4.02477 15.7332 4.08034C15.8742 4.1359 16.0028 4.2187 16.1118 4.32401C16.2207 4.42931 16.3079 4.55507 16.3683 4.69409C16.4287 4.83311 16.4612 4.98267 16.4638 5.13424C16.4664 5.28581 16.4392 5.43641 16.3837 5.57745C16.3282 5.7185 16.2454 5.84721 16.1402 5.95625Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_6047_10258">
        <rect width="12" height="12" fill="white" transform="translate(4.5 4)"/>
      </clipPath>
    </defs>
  </svg>
</template>