<script setup>
import { onMounted } from "vue";
import {useGlobalStore} from "@/stores/global.store"
import {useAuthStore} from "@/stores/auth.store"
import RightPanel from '@/components/RightPanel.vue';
import CookieBar from '@/components/CookieBar.vue'
import ServerConfigurator from '@/components/server-configurator/ServerConfigurator.vue'
import FreeWebeditorModal from '@/components/free-webeditor-modal/FreeWebeditorModal.vue'
const globalStore = useGlobalStore();
const authStore = useAuthStore();

const props = defineProps({
  configId: String | Number,
  componentId: String | Number,
  opts: String | undefined
})

onMounted(async () => {
  // load config
  await globalStore.getConfigObj();

  // check if user is logged in and get user profile
  await authStore.checkIsLoggedIn();

  // load tld list
  await globalStore.getTldList({
    form_name: globalStore.configObj.general.form_name,
    currency: globalStore.configObj.general.currency_code
  });
});

const closeRightPanel = () => {
  globalStore.setRightPanelComp(null);
}
</script>

<template>
  <div class="panel-right" :class="{'panel-right--active':globalStore.rightPanelComp}">
    <RightPanel v-if="globalStore.rightPanelComp" />
    <div class="panel-right__bg" :class="{'panel-right__bg--active':globalStore.rightPanelComp}" @click="closeRightPanel"></div>
  </div>
  <CookieBar />
  <ServerConfigurator />
  <FreeWebeditorModal />
</template>

<style scoped>
</style>
