<script setup>
import { computed } from 'vue';

  const props = defineProps({
    columnDesktop: {
      type: String,
      default: '3'
    },
    columnMobile: {
      type: String,
      default: '1'
    },
    desktopSpacingX: {
      type: String,
      default: '30'
    },
    desktopSpacingY: {
      type: String,
      default: '30'
    },
    mobileSpacingX: {
      type: String,
      default: '30'
    },
    mobileSpacingY: {
      type: String,
      default: '30'
    },
    container: {
      type: String,
      default: 'full'
    },
  });

  const section = computed(() => {
    let styles = {
    '--b-grid--column-desktop': `${props.columnDesktop}`,
    '--b-grid--column-mobile': `${props.columnMobile}`,
    '--b-grid--gap-x-desktop': `${props.desktopSpacingX}px`,
    '--b-grid--gap-y-desktop': `${props.desktopSpacingY}px`,
    '--b-grid--gap-x-mobile': `${props.mobileSpacingX}px`,
    '--b-grid--gap-y-mobile': `${props.mobileSpacingY}px`
  };

  if (props.container !== 'full') {
    styles['--b-grid--container'] = `${props.container}px`;
  }

  return {
    styles
  };
  });
</script>

<template>
  <div class="wp-block-grid">
    <div class="b-grid" :style="section.styles">
      <slot>
        <!-- <MyBigNumberBenefit title="Opakovaná provize" text="z jakéhokoliv virtuálního serveru" text-big="8" text-big-sub="%" linkText="Nabídka serverů" />
        <MyBigNumberBenefit title="Opakovaná provize" text="z e-mailového nástroje Mail Klerk" text-big="25" text-big-sub="%" linkText="Nabídka Mail Klerk" />
        <MyBigNumberBenefit title="Opakovaná provize" text="z objednávky jakéhokoliv webhostingu" text-big="20" text-big-sub="%" linkText="Nabídka webhostingu" /> -->
      </slot>
    </div>
  </div>
</template>

