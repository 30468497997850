import { setElementsScrollPositionFromTop } from "@/utils/dom"

class VueBridge {
  constructor() {}

 setDasComponent = (opts) => {
    const das = document.getElementById(opts?.id)

    if(!das) {
      console.error('Check button and das id settings.')
      return
    }

    const dasWrapper = document.querySelector('.js-wg-das-offerwall');
    
    if (dasWrapper) {
      dasWrapper.classList.remove('d-none');
    }

    setElementsScrollPositionFromTop({ 
      element: das, 
      topOffset: window.innerHeight / 3
    }).then(() => {
      window.webGlobe?.wgCall('setupComponent', { wgComp: 'wg-das', wgOpts: {...opts, fromBridge: true }});
    })
  }

  addToCart = (product) => {
    window.webGlobe?.wgCall('addToCart', {...product});
  }

  openServerConfig = (opts) => {
    window.webGlobe?.wgCall('openServerConfigurator', {...opts})
  }

  openFreeWebeditorModal = () => {
    window.webGlobe?.wgCall('openFreeWebeditorModal')
  }

  openAuthPanel = (opts) => {
    window.webGlobe?.wgCall('openAuthPanel', {...opts})
  }
}

export const vueBridge = new VueBridge();
