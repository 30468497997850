<script setup>
import { onMounted, defineProps, computed } from "vue";
import Button from "@/stories/Button.vue"
import IconInfo from "./icon/info.vue"
import { useCheckoutStore } from "@/stores/checkout.store";
import { useDataLayer } from "@/dataLayer.js";

const props = defineProps({
  payment: {
    type: Object,
    required: true,
  },
});

const $checkout = useCheckoutStore()
const { changePaymentInfo } = useDataLayer()

const isSelected = computed(() => {
  if($checkout.step4EditMode === 'payment') {
    return $checkout.selectedPaymentTypeCache === props.payment.name
  }
  return $checkout.selectedPaymentType === props.payment.name
})

function selectPaymentMethod() {
  if (props.payment.isDisabled) return
  if($checkout.step4EditMode === 'payment') {
    $checkout.selectedPaymentTypeCache = props.payment.name
    return
  }
  changePaymentInfo(props.payment.name, $checkout.selectedPaymentTypeCache)
  $checkout.selectedPaymentTypeCache = props.payment.name
  $checkout.selectedPaymentType = props.payment.name
}

function getSvgDataForImageSrc(src) {
  return window.webGlobe?.global?.getSvgDataForImageSrc(src) || src
}

onMounted(() => {
  window.webGlobe?.tooltip?.init()
})
</script>

<template>
  <div @click="selectPaymentMethod" class="b-checkout-content__item b-checkout-content__item--payment" :class="{'b-checkout-content__item--active' : isSelected, 'b-checkout-content__item--disabled' : payment.isDisabled}">
    <div class="b-checkout-content__item-inner">
      <div class="b-checkout-content__item-header b-checkout-content__item-title">
        <div class="b-checkout-content__item-radio"></div>
        <div class="b-checkout-content__item-title-name">
          {{ payment.title }}
        </div>
        <div class="b-checkout-content__item-payment-logos">
          <div class="b-checkout-content__item-payment-logo">
            <img :src="getSvgDataForImageSrc(payment.icon1)" alt="">
          </div>
          <div v-if="payment.icon2" class="b-checkout-content__item-payment-logo">
            <img :src="getSvgDataForImageSrc(payment.icon2)" alt="">
          </div>
        </div>
      </div>
      <div v-if="isSelected" class="b-checkout-content__item-content">
        <div class="b-checkout-content__item-title-address">
          <p v-if="!$checkout.isLowestPeriodOne || $checkout.isServer" class="b-checkout-content__item-title-address--description">{{ payment.description }}&nbsp;<button v-if="payment.info" type="button" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" :data-bs-title="payment.info"><IconInfo /></button></p>
          <p v-if="$checkout.isLowestPeriodOne && !$checkout.isServer" class="desc-error">{{ $checkout.paymentErrors?.only_card }}<br><a href="#" @click.prevent="$checkout.setStep(1)">{{ $t('checkout.step3.editOrder') }}</a></p>
          <p v-else-if="payment.red_description" class="desc-error">{{ payment.red_description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
