
export const getTldPrice = (tld) => {
  let price = tld.reg_price
  if (tld.customer_discount > 0) {
    price = tld.reg_customer_price
  }
  const minMonths = Math.min(...tld.supported_months.filter(month => month >= 12))
  return price / (minMonths / 12)
}

export const getFullTldPrice = (tld) => {
  const minMonths = Math.min(...tld.supported_months.filter(month => month >= 12))
  return tld.reg_price / (minMonths / 12)
}