export const faq = {
  init() {
  },
  events() {

  }
}

/* export function faqInit() {
  faq.init();
}


export default () => {
  faq.init();
}
 */
