class ScrollSpy {
  constructor() {
  }

  init = () =>  {
    this.smoothScroll();
    this.spyScrolling();
  }

  getElementOffsetPosition = (element) => {
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    const topMenuHeight = document.querySelector('.b-top-menu')?.getBoundingClientRect().height || 0
    const mainMenuHeight = document.querySelector('.b-main-menu')?.getBoundingClientRect().height || 0
    const offsetPosition = elementPosition - topMenuHeight - mainMenuHeight;

    return offsetPosition
  }
  
  smoothScroll = () =>  {
    const scrollLinks = document.querySelectorAll('a[href^="#"]:not([data-bs-toggle])');

    for (const scrollLink of scrollLinks) {
      scrollLink.addEventListener('click', event => {
        event.preventDefault();
        const anchorId = scrollLink.hash;
        if(anchorId) {
            const element = document.querySelector(anchorId);
            if (element) {
                const offsetPosition = this.getElementOffsetPosition(element)

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }
      });
    }
  }

  spyScrolling = () =>  {
    const sections = document.querySelectorAll('.js-anchor-main')
    window.addEventListener('scroll', () => {
      sections.forEach((section, index) => {
        const link = document.querySelector(`a[href*=${section.id}]`)

        const sectionTopOffsetNeeded = this.getElementOffsetPosition(section) - 100

        const nextSectionTopOffsetNeeded = index === sections.length - 1 
          ? document.body.getBoundingClientRect().height 
          : this.getElementOffsetPosition(sections[index + 1]) - 100

        if(window.scrollY >= sectionTopOffsetNeeded && window.scrollY < nextSectionTopOffsetNeeded) {
          link.classList.add('scroll-link--active')
        } else {
          link.classList.remove('scroll-link--active')
        }
      })
    })
  }
}

export const scrollSpy = new ScrollSpy();