import { wpWg } from '@/axios.js'

export async function getBasket() {
  return await wpWg.get('/get-basket');
}

export async function getBasketData(items = undefined) {
  return await wpWg.post('/get-basket-data', {
    items
  });
}

export async function addToBasket(data) {
  return await wpWg.post('/add-to-basket', {
    ...data
  });
}

export async function removeFromBasket(data) {
  return await wpWg.post('/remove-item-from-basket', {
    ...data
  });
}

export async function updatePeriod(data) {
  return await wpWg.post('/update-item-period-from-basket', {
    ...data
  });
}

export async function updatePack(data) {
  return await wpWg.post('/replace-item-from-basket', {
    ...data
  });
}

export async function updateLicenseCount(data) {
  return await wpWg.post('/update-item-count-from-basket', {
    ...data
  });
}

export async function submitOrder(data) {
  return await wpWg.post('/send-order', {
    ...data
  });
}

export async function setCoupon(coupon) {
  return await wpWg.post('/set-coupon', {
    coupon
  });
}
