import { defineStore } from "pinia";
import { nextTick } from 'vue'
import {
  getTldFromDomainInput,
  isValidDomain,
  getClearDomain,
  prepareTldList,
} from "@/utils";
import { useGlobalStore } from "./global.store";
import {checkAvailability, getTransfer} from "@/services";

const dasStoreState = {
  config: {
    delay:1000,
    domain_list: '',
    only_free_toggle: true,
    preferred_domain: '',
    upsale_domains: '',
    placeholder: '',
    cdas: false,
    productType: '',
    productPeriod: '',
    productPack: '',
    productCount: undefined,
    productCpu: undefined,
    productRam: undefined,
    productNvme: undefined,
    content: {}
  },
  searchDomainInput: "",
  searchDomain: "",
  tldList: [],
  lastUpdateLtdList: null,
  resultList: [],
  verifiedDomain: [],
  invalidInput: false,
  activeTldList: [],
  transfer: null,
  onlyFree: false,
  onlyFreeToggle: true,
  currency: "EUR",
  upsaleDomains: [],
  preferred_domain: "",

  ip: "127.0.0.1",
  authToken: "",
  checkingAvailability: 0,
}

export const useDasStore = defineStore("das", {
  state: () => ({
    das: {},
    ip: "127.0.0.1",
    authToken: "",
    checkingAvailability: 0,
    isDomainTransferRequired: false
  }),
  getters: {
    getVerifiedDomain: (state) => {
      return (componentId, getVerifiedDomain) => state.getDas(componentId).verifiedDomain.find((domain) => domain.name === getVerifiedDomain)
    },
    getTldObject: (state) => {
      return (tlName, componentId) => {
        if (!componentId) {
          const globalStore = useGlobalStore();
          return globalStore.tldList[tlName];
        }
        return state.getDas(componentId).tldList.find((tld) => tld.tl_name === tlName)
      }
    },
    getDas: (state) => {
      return (componentId) => state.das[componentId]
    },
    isUpsaleDomain: (state) => {
      return (componentId, domain) => state.getDas(componentId).upsaleDomains.includes(domain)
    },
    getResults: (state) => {
      return (componentId) => {
        const onlyFree = state.getDas(componentId).onlyFree;
        const verifiedDomain = state.getDas(componentId).verifiedDomain;
        if (onlyFree) {
          const domain = state.getDas(componentId).searchDomain;
          if (state.getDas(componentId).resultList.length === 0) {
            return state.getDas(componentId).resultList;
          }
          const results = state.getDas(componentId).resultList.filter((item) => {
            const domainWithTLD = `${domain}.${item.tl_name}`;
            const verified = verifiedDomain.find(verifiedItem => verifiedItem.name === domainWithTLD);
            return (!verified || verified.available) && state.getDas(componentId).preferred_domain !== item.tl_name;
          });
          if (results.length < 7) {
            state.updateResultList(componentId);
          }
          return results;
        }
        return state.getDas(componentId).resultList.filter((item) => state.getDas(componentId).preferred_domain !== item.tl_name);

      }
    },
    isCdas: (state) => {
      return (componentId) => state.getDas(componentId).config.cdas
    },
    isTransfer: (state) => {
      const transferAvailableStatuses = [
        'AVAILABLE_TRANSFER',
      ]

      return (componentId) => transferAvailableStatuses.includes(state.getDas(componentId).transfer?.domain_status)
    },
    isAlreadyHostedInternally: (state) => {
      const alreadyHostedInternallyStatuses = [
        'EXIST_IN_SYSTEM',
        'AVAILABLE_ONLY_TRANSFER'
      ]

      return (componentId) => alreadyHostedInternallyStatuses.includes(state.getDas(componentId).transfer?.domain_status)
    }
  },
  actions: {
    addToDas(id) {
      if (!this.das[id]) {
        this.das[id] = JSON.parse(JSON.stringify(dasStoreState));
      }
    },

    setConfig(componentId, config, opts) {
      const das = this.getDas(componentId);
      das.config = {
        ...config,
        ...opts ? opts : {}
      }
    },

    async setTldList(componentId, preferred_domain) {
      const globalStore = useGlobalStore();
      this.getDas(componentId).tldList = prepareTldList(globalStore.tldList, preferred_domain);
    },

    setUpsaleDomains(componentId, upsaleDomains) {
      if (upsaleDomains !== '') {
        this.getDas(componentId).upsaleDomains = upsaleDomains.split(',').map(item => item.trim());
      }
    },

    setPreferredDomain(componentId, preferred_domain) {
      this.getDas(componentId).preferred_domain = preferred_domain;
    },

    setOnlyFreeToggle(componentId, toggle) {
      this.getDas(componentId).onlyFreeToggle = toggle;
    },

    async searchTld(componentId, tld) {
      const das = this.getDas(componentId);

      if (tld === "") {
        das.resultList = [];
        return
      }

      if (!tld) {
        tld = das.searchDomainInput;
      }

      das.invalidInput = false;
      das.searchDomainInput = tld;

      if (!isValidDomain(tld)) {
        das.invalidInput = true;
        return;
      }
      das.resultList = [];
      await nextTick();
      const tldFromDomainInput = getTldFromDomainInput(tld);
      das.searchDomain = getClearDomain(tld)

      if (tldFromDomainInput !== "") {
        das.activeTldList = das.tldList
          .filter((tld) => tld.tl_name.startsWith(tldFromDomainInput));
          das.resultList = das.activeTldList.slice(0, 20);
          if (das.resultList.length === 0) {
            das.invalidInput = true;
            das.activeTldList = das.tldList
            das.resultList = das.activeTldList.slice(0, 20);
            // das.resultList = das.activeTldList
          }
      } else {
        das.activeTldList = das.tldList
        das.resultList = das.activeTldList.slice(0, 20);
        // das.resultList = das.activeTldList
      }
    },

    updateResultList(componentId) {
      const das = this.getDas(componentId);
      const currentLength = das.resultList.length;
      const activeTldListLength = das.activeTldList.length;

      if (currentLength >= activeTldListLength) {
        return;
      }

      das.resultList = das.activeTldList.slice(0, currentLength + 20);
    },

    async checkAvailability(componentId, domain, tld = null) {
      this.checkingAvailability++;
      let tldData = tld;
      try {
        const {data} = await checkAvailability(domain)
        tldData = {...data, ...tld}

      } catch (error) {
        console.log(error)
        tldData = {...tld, ...{available: false, failed: true}}
        throw error;
      } finally {
        this.checkingAvailability--;
        this.updateVerifiedDomain(componentId, tldData)
      }
    },

    updateVerifiedDomain(componentId, data) {
      const das = this.getDas(componentId);
      const existingIndex = das.verifiedDomain.findIndex((domain) => domain.name === data.name);
      if (existingIndex !== -1) {
        das.verifiedDomain[existingIndex] = data;
      } else {
        das.verifiedDomain.push(data);
      }
    },

    async switchTransfer(componentId, domain, tld) {
      const das = this.getDas(componentId);
      if (!domain || !tld) {
        das.transfer = false;
        return
      }
      const globalStore = useGlobalStore();
      das.transfer = true
      const params = {
        form_name: globalStore.configObj.general.form_name,
        domain_name: `${domain}.${tld}`,
      }
      const {data} = await getTransfer(params)
      das.transfer = data
    },

    closeDas(componentId) {
      const das = this.getDas(componentId);
      das.searchDomainInput = '';
      das.resultList = [];
      das.onlyFree = false;
    }
  },
  /* persist: {
    debug: true,
    paths: [ "das"],
  }, */
});
