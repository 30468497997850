<script setup>
import NxtChoices from '@/components/shared/NxtChoices.vue'

const props =  defineProps(['modelValue', 'v', 'variant', 'helperText', 'classes', 'background'])
const emit = defineEmits('update:modelValue')

const updateModelValue = (value) => {
  emit('update:modelValue', value.toUpperCase())
}
</script>

<template>
    <div
      class="b-form__group"
      :class="{
        'b-form__group--filled': props.modelValue,
        'b-form__group--error': variant === 'error',
        'b-form__group--disabled': variant === 'disabled',
        'b-form__group--success': variant === 'success',
        'b-form__group--background-light-blue': background && background === 'light-blue',
      }, classes"
    >
      <div class="b-form-select b-form-select--country">
        <NxtChoices 
          choiceType="country" 
          :modelValue="props.modelValue" 
          :defaultSelected="props.modelValue ?? config.language"
          @onChange="updateModelValue"
        />
      </div>
      <div v-if="variant === 'error'" class="b-form__helper-text">{{ helperText }}</div>
    </div>
</template>
