<script setup>
</script>

<template>
  <ul
    class="b-domain-list-section__list-row b-domain-list-section__list-row--error"
  >
    <li class="b-domain-list-section__list-row__item">
      <svg class="b-domain-list-section__list-row__item__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#F03A49"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0078 13C11.4555 13 11.0078 12.5523 11.0078 12L11.0078 8C11.0078 7.44772 11.4555 7 12.0078 7C12.5601 7 13.0078 7.44772 13.0078 8L13.0078 12C13.0078 12.5523 12.5601 13 12.0078 13Z" fill="#F03A49"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0078 16C13.0078 16.5523 12.5601 17 12.0078 17L11.9978 17C11.4455 17 10.9978 16.5523 10.9978 16C10.9978 15.4477 11.4455 15 11.9978 15L12.0078 15C12.5601 15 13.0078 15.4477 13.0078 16Z" fill="#F03A49"/>
      </svg>
      <span class="b-domain-list-section__list-row__item__text">{{ $t('domainList.not-found') }}</span>
    </li>
  </ul>
</template>