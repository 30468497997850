<script setup>
import { inject } from 'vue'
import { useDasStore } from '@/stores/das.store.js';
import { useCheckoutStore } from '@/stores/checkout.store'
import { openModal } from "@/utils"
import BlockButtonWrapper from "@/stories/block-button-wrapper/BlockButtonWrapper.vue";
import Button from "@/stories/Button.vue";
import WarningModal from '@/components/shared/WarningModal.vue'

const componentId = inject('componentId')
const dasStore = useDasStore();
const $checkout = useCheckoutStore()

const currentDas = dasStore.getDas(componentId)

const addVirtualDomainWithPackageToCart = async () => {
  try {
    await $checkout.addToCart({
      item_name: `${currentDas.transfer.domain_name}.${currentDas.transfer.toplevel}`,
      item_pack: currentDas.config.productPack,
      item_period: currentDas.config.productPeriod,
      item_count: currentDas.config.productCount,
      item_type: currentDas.config.productType
    })
    $checkout.setStep(1)
  } catch (error) {
    console.log(error);
  }
}

const modalId = 'transferOccupiedNotHostedModal'
</script>

<template>
   <div class="b-das__transfer b-das__transfer--wide">
    <div class="b-das__transfer-title">{{ $t('das.transfer.domainOccupied') }}</div>
    <p class="b-das__transfer-description">{{ !currentDas.config?.disable_transfer_domain ? $t('das.transfer.desc1') : $t('das.transfer.desc1DisableTransfer') }}</p>
    <p class="b-das__transfer-description d-none d-md-block">{{ !currentDas.config?.disable_transfer_domain ? $t('das.transfer.desc2') : $t('das.transfer.desc2DisableTransfer') }}</p>
    <div class="b-das__transfer-actions">
      <BlockButtonWrapper desktopPosition="center" mobilePosition="center">
        <Button :label="!currentDas.config?.disable_transfer_domain ? $t('das.transfer.transfer') : $t('das.transfer.transferDisableTransfer')" variant="primary" color="orange" size="big"
          @click="dasStore.isDomainTransferRequired = true"
        />
        <Button v-if="!currentDas.config?.disable_transfer_domain" :label="$t('das.transfer.continueWithoutTransfer')" variant="outline" color="blue" size="big"
          @click="openModal(modalId)"
        />
      </BlockButtonWrapper>
    </div>
  </div>
  <Teleport to="body">
    <WarningModal
      :id="modalId"
      :title="$t('das.transfer.modal.title')"
      :description="$t('das.transfer.modal.description')"
      severity="warning"
      :primary-button="$t('das.transfer.modal.no')"
      primary-button-color="orange"
      @on-primary-action="dasStore.isDomainTransferRequired = true"
      :secondary-button="$t('das.transfer.modal.yes')"
      @on-secondary-action="addVirtualDomainWithPackageToCart()"
      text-align="left"
    />
  </Teleport>
</template>
