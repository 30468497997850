<script setup>
import { ref, watch } from 'vue'
import { getPriceLabel } from '@/utils/checkout'

const props = defineProps({
  isChanging: {
    type: Boolean,
    required: true
  },
  modelValue: {
    type: Number,
    required: true
  },
  price: {
    type: Number,
    required: true
  },
  unit: {
    type: String,
    required: true
  },
  maxCharactersOfValue: {
    type: Number,
    required: true
  },
  inputHandler: {
    type: Function,
    required: true
  }
})

const input = ref(null)
const isInputActive = ref(false)

const handleFocusInput = () => {
  isInputActive.value = true
  const timeout = setTimeout(() => {
    input.value.focus()
    return clearTimeout(timeout)
  }, 250)
}

const handleBlurInput = () => {
  isInputActive.value = false
}

watch(() => props.isChanging, (isChanging, wasChanging) => {
  if(wasChanging && !isChanging) {
    input.value.blur()
  }
})
</script>

<template>
<div
  class="b-server-configurator__form-controls-params-list-item-title-badge"
>
  <p @click="handleFocusInput" class="b-server-configurator__form-controls-params-list-item-title-badge-value">
    <template v-if="isInputActive">
      <input
        ref="input"
        :style="`--computed-width-base: ${props.maxCharactersOfValue}`" 
        type="text" 
        :value="props.modelValue" 
        @input="inputHandler"
        @blur="handleBlurInput"
      > 
    </template>
    <span v-if="!isInputActive">{{ props.modelValue }} {{ props.unit }}</span>
  </p>
  <div class="b-server-configurator__form-controls-params-list-item-title-badge-separator"></div>
  <p 
    class="b-server-configurator__form-controls-params-list-item-title-badge-price"
    :class="{ pulse: props.isChanging }"
  >
    {{ getPriceLabel(props.price) }}
  </p>
</div>
</template>