<script setup>
</script>

<template>
  <div class="b-das__transfer b-das__transfer--wide">
    <div class="b-das__transfer-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48"><path fill="#F03A49" fill-rule="evenodd" d="M24 6C14.059 6 6 14.059 6 24s8.059 18 18 18 18-8.059 18-18S33.941 6 24 6ZM2 24C2 11.85 11.85 2 24 2s22 9.85 22 22-9.85 22-22 22S2 36.15 2 24Z" clip-rule="evenodd"/><path fill="#F03A49" fill-rule="evenodd" d="M24.02 26a2 2 0 0 1-2-2v-8a2 2 0 0 1 4 0v8a2 2 0 0 1-2 2ZM26.02 32a2 2 0 0 1-2 2H24a2 2 0 0 1 0-4h.02a2 2 0 0 1 2 2Z" clip-rule="evenodd"/></svg>
    </div>
    <div class="b-das__transfer-title" v-html="$t('transfer_wg.title_not_available')"></div>
  </div>
</template>
