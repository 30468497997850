import throttle from "lodash/throttle"


class TabsAutoplay {
  constructor() {}
  init = () => {
    const autoplayWrapper = document.querySelector('[data-tabs-autoplay]');

    if (!autoplayWrapper) {
      return;
    }
    let currentIndex = 0
    const interval = autoplayWrapper?.getAttribute('data-tabs-autoplay-interval', 5000);
    const buttons = autoplayWrapper?.querySelectorAll('.b-tabs-autoplay__button')
    const tabs = [...buttons]?.map(button => document.getElementById(button.getAttribute('data-target')))


    autoplayWrapper?.style?.setProperty('--tabs-autoplay-interval', interval)

    const setWrapperHeight = () => {
      const wrapperHeight = Math.max(...tabs?.map(el => el.offsetHeight) || 0)
      autoplayWrapper.closest('section')?.style?.setProperty('--tabs-autoplay-wrapper-height', wrapperHeight)
    }

    setWrapperHeight()

    const debouncedSetWrapperHeight = throttle(setWrapperHeight, 200, { leading: true, trailing: false })

    window.addEventListener('resize', debouncedSetWrapperHeight)

    let intervalId

    const startInterval = () => {
      intervalId = setInterval(() => {

        currentIndex = currentIndex === buttons?.length - 1 ? 0 : currentIndex + 1

        buttons.forEach((button, idx) => {
          if(idx === currentIndex) {
            button?.classList?.add('active')
          } else {
            button?.classList?.remove('active')
          }
        })

        tabs?.forEach((tab, idx) => {
          if(idx === currentIndex) {
            tab?.classList?.add('active')
          } else {
            tab?.classList?.remove('active')
          }
        })
      }, interval)
    }

    startInterval()

    buttons?.forEach((button, idx) => {
      button.addEventListener('click', () => {

        currentIndex = idx

        buttons?.forEach(button => button.classList.remove('active'));

        tabs?.forEach(tab => tab?.classList?.remove('active'));

        [...tabs]?.[idx]?.classList?.add('active')

        button?.classList?.add('active');

        autoplayWrapper?.classList?.add('freeze')

        clearInterval(intervalId)

      });
    })
  }
}

export const tabsAutoplay = new TabsAutoplay();
