<script setup>
  // core imports
  import { ref, computed, watch } from "vue";
  import { useGlobalStore } from "@/stores/global.store";
  import { useAuthStore } from "@/stores/auth.store";
  import { useCheckoutStore } from "@/stores/checkout.store";
  import { storeToRefs } from "pinia";
  import config from '@/config'
  import { parseOpts, getQueryParam } from "@/utils";
  import CheckoutEmpty from "@/components/checkout/CheckoutEmpty.vue";
  import CheckoutStep0 from "@/components/checkout/CheckoutStep0.vue";
  import CheckoutStep1 from "@/components/checkout/CheckoutStep1.vue";
  import CheckoutStep2 from "@/components/checkout/CheckoutStep2.vue";
  import CheckoutStep3 from "@/components/checkout/CheckoutStep3.vue";
  import CheckoutStep4 from "@/components/checkout/CheckoutStep4.vue";
  import CheckoutStep5 from "@/components/checkout/CheckoutStep5.vue";
  import CheckoutStep2Register from "@/components/checkout/CheckoutStep2Register.vue";
  import LoginWrap from "@/components/auth/LoginWrap.vue";
  import myButton from '@/stories/Button.vue';
  import RegisterWrap from "./components/auth/RegisterWrap.vue"
  import { useDataLayer } from "./dataLayer";
  const props = defineProps({
    configId: String | Number | undefined,
    componentId: String | Number | undefined,
    opts: String | undefined,
  });

  const globalStore = useGlobalStore();
  const $checkout = useCheckoutStore();
  const $auth = useAuthStore();
  const dataLayer = useDataLayer();

  const initComp = async () => {
    let options = {}
    if (props.opts) {
      if (typeof props.opts === 'string') {
        options = parseOpts(props.opts)
      } else {
        options = props.opts
      }
    }
    if(options?.checkout0?.isVMS) {
      $checkout.checkout0 = options.checkout0;
      $checkout.setStep(1)
      await $checkout.addToCart({
        item_name: `${config.vmsNamePlaceholder}${Math.floor(Math.random() * 100) + 1}.virtual`,
        item_pack: $checkout.checkout0.productPack,
        item_type: $checkout.checkout0.productType,
        item_period: $checkout.checkout0.productPeriod,
        item_ram: $checkout.checkout0?.productRam,
        item_cpu: $checkout.checkout0?.productCpu,
        item_nvme: $checkout.checkout0?.productNvme,
      });
      $checkout.isReady = true;
      return
    }
    if (options?.checkout0) {
      $checkout.checkout0 = options.checkout0;
      $checkout.setStep(0);
      $checkout.isReady = true;
      return
    }

    await $checkout.getBasketData()

    let step = getQueryParam('step');
    if ($auth.isLoggedIn) {
      await $checkout.getBillingProfiles()
    }
    if (step) {
      step = Number(step);
      if (step >= 1 && step <= 5) {
        $checkout.setStep(step);
      }
    }
    $checkout.isReady = true;
    window.addEventListener('beforeunload', handleBeforeUnload);
  };

  const handleBeforeUnload = event => {
    if ($checkout.step >= 4) {
      return true
    }
    const activeElement = event.target?.activeElement
    if (activeElement?.tagName === 'A') {
      const obj = {
        internalDestinationName: activeElement.innerText,
        internalDestinationURL: activeElement.href,
      }
      dataLayer.leaveCheckout('internal', obj)
    } else {
      dataLayer.leaveCheckout('exit')
    }
  }

  const { ready } = storeToRefs(globalStore);

  watch(ready, (val) => {
    initComp();
  });

  watch(() => $auth.isLoggedIn, (val) => {
    if (!val) {
      $checkout.setStep(1);
    }
  })

  if (ready.value) {
    initComp();
  }

</script>

<template>
  <div v-if="$checkout.isReady">
    <CheckoutStep0 v-if="$checkout.step === 0" />

    <div v-else>
      <CheckoutEmpty v-if="$checkout.isEmptyCheckout" />
      <CheckoutStep1 v-else-if="$checkout.step === 1" />
      <div v-else-if="$checkout.step === 2">
        <CheckoutStep2 v-if="$auth.isLoggedIn" />
        <CheckoutStep2Register v-else />
      </div>
      <CheckoutStep3 v-else-if="$checkout.step === 3" />
      <CheckoutStep4 v-else-if="$checkout.step === 4" />
      <CheckoutStep5 v-else-if="$checkout.step === 5" />
    </div>
    <Teleport to="body">
      <div class="modal modal--ignore-custom-top-offset modal-login--checkout fade" id="checkoutLoginModal" tabindex="-1" aria-labelledby="Checkout Login" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-sm">
          <div class="modal-content">
            <div class="modal-body modal-login--checkout__body">
              <myButton variant="link" color="gray" size="medium" class="modal-close" data-bs-dismiss="modal" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>' />
              <LoginWrap variant="checkout" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal modal-register--checkout fade" id="checkoutRegisterModal" tabindex="-1" aria-labelledby="Checkout Login" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-sm">
          <div class="modal-content">
            <div class="modal-body modal-register--checkout__body">
              <myButton variant="link" color="gray" size="medium" class="modal-close" data-bs-dismiss="modal" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>' />
              <div class="b-checkout-register__hero">
                <h4 class="b-checkout-register__hero__title">{{ $t('auth.register.title') }}</h4>
                <p class="b-checkout-register__hero__subtitle">{{ $t('auth.register.subtitle') }}</p>
              </div>
              <RegisterWrap variant="checkout" />
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style scoped></style>
