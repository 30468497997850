<script setup>
</script>

<template>
<div class="b-domain-list-section__list">
  <ul class="b-domain-list-section__list-header">
    <li class="b-domain-list-section__list-header__item b-domain-list-section__list-header__item--name">
      Názov domény
    </li>
    <li class="b-domain-list-section__list-header__item b-domain-list-section__list-header__item--country">
      Entita / Krajina
    </li>
    <li class="b-domain-list-section__list-header__item b-domain-list-section__list-header__item--price">
      <span>Cena bez DPH</span> <span class="b-domain-list-section__list-header__item__comment">/ rok (* prvý rok)</span>
    </li>
  </ul>
  <ul v-for="_ of Array.from({ length: 14 })" class="b-domain-list-section__list-row">
    <li class="b-domain-list-section__list-row__item b-domain-list-section__list-row__item--name pulse">
      .sk
    </li>
    <li class="b-domain-list-section__list-row__item b-domain-list-section__list-row__item--country pulse">
      Middle Europe
    </li>
    <li class="b-domain-list-section__list-row__item b-domain-list-section__list-row__item--price">
      <div class="b-domain-list-section__list-row__item-badge pulse">
        -30%
      </div>
      <div class="b-domain-list-section__list-row__item-price">
        <span class="b-domain-list-section__list-row__item-price__old pulse">9,99 <span class="b-domain-list-section__list-row__item-price__old__currency">€</span></span>
      </div>
      <div class="b-domain-list-section__list-row__item-action pulse">
      
      </div>
    </li>
  </ul>
</div>
</template>