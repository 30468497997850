<script setup>
import { ref, computed, onMounted } from 'vue'
import { useElementVisibility } from '@vueuse/core'
import { useCheckoutStore } from '@/stores/checkout.store'
import BillingInformation from './components/BillingInformation.vue'
import PaymentInformation from './components/PaymentInformation.vue'
import Steps from './components/Steps.vue';
import Summary from './components/Summary.vue';
import SummaryOrders from './components/SummaryOrders.vue';
import StatusBar from './components/StatusBar.vue'
import { onBeforeUnmount } from 'vue'
import {generateDatalayerCheckoutItems} from '@/utils'
import { useDataLayer } from '@/dataLayer.js'

const $checkout = useCheckoutStore()
const { checkoutSummary } = useDataLayer()

const billingProfiles = computed(() => ([
  $checkout.billingProfiles?.main_profile ? {
    ...$checkout.billingProfiles?.main_profile,
    isMain: true
  } : {},
  ...$checkout?.billingProfiles?.bill_profiles?.length ? $checkout.billingProfiles.bill_profiles.map(profile => ({
    ...profile,
    isMain: false
  })) : []
]))

const domainOwnerProfiles = computed(() => {
    return [...$checkout?.listOfRegistrants?.length ? $checkout?.listOfRegistrants : [], ...$checkout.listOfTemporaryRegistrants]
      .map(profile => ({
        IDregistrant: profile.IDregistrant,
        temporaryIDregistrant: profile.temporaryIDregistrant,
        customer_name: profile.owner.name  || '',
        customer_phone: profile.contact.phone  || '',
        customer_email: profile.contact.email  || '',
        customer_street: profile.owner.street  || '',
        customer_town: profile.owner.town  || '',
        customer_postcode: profile.owner.postcode  || '',
        customer_state: profile.owner.state  || '',
        customer_province: profile.owner.province || '',
        customer_ico: profile.owner.orgid || '',
        customer_dic: profile.owner.taxid || '',
        customer_icdph: profile.owner.vatid || '',
      }))
})

const nonStickySummary = ref(null)
const isNonStickySummaryVisible = useElementVisibility(nonStickySummary)

onMounted(() => {
  checkoutSummary()
})

onBeforeUnmount(() => {
  $checkout.step4EditMode = null
})
</script>

<template>
  <Steps :step="4" />
  <main class="l-page-main">
    <section class="l-page-content l-page-content--checkout">
      <div class="container">
        <div class="row gy-20 gx-25 isolate">
          <div class="col">
            <div class="l-page-aside-sticky">
              <div class="d-flex flex-column gap-25">
                <StatusBar
                  v-if="$checkout.serverError"
                  status="error"
                  :message="$checkout.serverError"
                />
                <SummaryOrders v-if="!$checkout.step4EditMode" />
                <BillingInformation
                  v-if="$checkout.step4EditMode === 'billProfile'"
                  :title="$t('checkout.step2.selectBillingProfile')"
                  :profiles="billingProfiles"
                />
                <BillingInformation
                  v-if="$checkout.step4EditMode === 'domainOwner'"
                  :title="$t('checkout.step4.selectDomainOwner')"
                  :profiles="domainOwnerProfiles"
                />
                <PaymentInformation v-if="$checkout.step4EditMode === 'payment'" />
                <div ref="nonStickySummary">
                  <Summary :is-mobile-sticky="false" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-checkout-asside">
            <aside class="l-page-aside-sticky">
              <Summary :is-hidden="isNonStickySummaryVisible" />
            </aside>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
