<script setup>
  import { ref, defineProps, computed } from 'vue'
  import Button from '@/stories/Button.vue';
  import UserProfileForm from '@/components/checkout/components/UserProfileForm.vue';
  import { useCheckoutStore } from '@/stores/checkout.store';
  import {generateDatalayerCheckoutItems} from '@/utils'
  import { useDataLayer } from '@/dataLayer.js';

  const $checkout = useCheckoutStore()
  const { shippingInfo } = useDataLayer()

  const props = defineProps({
    profile: {
      type: Object,
      required: true,
    },
  });

const fullAddress = computed(() => {
  return `
    ${props.profile.invoice_street || props.profile.customer_street},
    ${props.profile.invoice_postcode || props.profile.customer_postcode}
    ${props.profile.invoice_town || props.profile.customer_town}`;
});

const handleEditProfile = () => {
  $checkout.isAddProfileOpen = false
  $checkout.setProfileAsActive(props.profile);
  if(props?.profile?.isMain) {
    $checkout.currentProfileEditingOrSeekingID = 'mainProfile'
    return
  }
  $checkout.currentProfileEditingOrSeekingID = props?.profile?.IDbillprofile
    || props?.profile?.IDregistrant
    || props?.profile?.temporaryIDregistrant
}

const handleCloseEditProfile = () => {
  $checkout.currentProfileEditingOrSeekingID = null
  shippingInfo.updateShippingInfo()
}

const isEditProfile = computed(() => {
  if(props?.profile?.isMain) {
    return $checkout.currentProfileEditingOrSeekingID === 'mainProfile'
  }
  return [
    props?.profile?.IDbillprofile,
    props?.profile?.IDregistrant,
    props?.profile?.temporaryIDregistrant
  ].includes($checkout.currentProfileEditingOrSeekingID)
})

const isActiveProfile = computed(() => {
  if($checkout.step4EditMode === 'domainOwner' && props.profile.IDregistrant) {
    return $checkout.domainRegistrantsIDs[$checkout.currentDomainRegistrantEditing] === props.profile.IDregistrant
  }

  if($checkout.step4EditMode === 'domainOwner' && props.profile.temporaryIDregistrant) {
    return $checkout.domainRegistrantsIDs[$checkout.currentDomainRegistrantEditing] === props.profile.temporaryIDregistrant
  }

  if($checkout.step4EditMode === 'domainOwner' && props.profile.IDregistrant) {
    return $checkout.currentDomainOwnerProfileID === props.profile.IDregistrant
  }

  if($checkout.step4EditMode === 'billProfile' && props.profile.isMain) {
    return $checkout.currentBillingProfileIDCache === null
  }
  if($checkout.step4EditMode === 'billProfile' && !props.profile.isMain) {
    return $checkout.currentBillingProfileIDCache === props.profile.IDbillprofile
  }

  if(props.profile.isMain) {
    return $checkout.currentBillingProfileID === null
  }

  return $checkout.currentBillingProfileID === props.profile.IDbillprofile
})

const setProfileIsToDelete = () => {
  $checkout.currentBillingProfileIDToDelete = props.profile.IDbillprofile
}

const isReadOnly = computed(() => {
  return props?.profile?.isMain || props?.profile?.IDregistrant
})

function setProfileAsActive(profile) {
  $checkout.setProfileAsActive(profile)
  shippingInfo.updateShippingInfo()
}

</script>

<template>
  <div
    class="b-checkout-content__item"
    :class="{ 'b-checkout-content__item--active': isActiveProfile }"
    @click="setProfileAsActive(profile)"
  >
    <div class="b-checkout-content__item-inner">
      <div class="b-checkout-content__item-radio"></div>
      <div class="b-checkout-content__item-title">
        <div class="b-checkout-content__item-title-name">
          {{ profile.contact_name || profile.customer_name }}
        </div>
        <div class="b-checkout-content__item-title-address">
          {{ fullAddress }}
        </div>
      </div>
      <div v-if="isReadOnly && !isEditProfile" class="b-checkout-content__item-actions">
        <Button class="eye-button" @click.stop="handleEditProfile" variant="link" size="small" color="blue" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none"><path d="M1.66406 10.5C1.66406 10.5 4.16406 4.66663 9.9974 4.66663C15.8307 4.66663 18.3307 10.5 18.3307 10.5C18.3307 10.5 15.8307 16.3333 9.9974 16.3333C4.16406 16.3333 1.66406 10.5 1.66406 10.5Z" stroke="#2C57DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 13C11.3807 13 12.5 11.8807 12.5 10.5C12.5 9.11929 11.3807 8 10 8C8.61929 8 7.5 9.11929 7.5 10.5C7.5 11.8807 8.61929 13 10 13Z" stroke="#2C57DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/</svg>' />
      </div>
      <div v-else-if="!isReadOnly && !isEditProfile" class="b-checkout-content__item-actions">
        <Button @click.stop="handleEditProfile" variant="link" size="small" color="blue" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none"><path fill="#2C57DE" fill-rule="evenodd" d="M16.552 3.098a1.556 1.556 0 0 0-1.747.44.809.809 0 0 1-.046.051L3.665 14.683l-.808 2.963 2.963-.808L16.913 5.744a.804.804 0 0 1 .06-.054 1.53 1.53 0 0 0 .555-1.1 1.51 1.51 0 0 0-.444-1.145 1.543 1.543 0 0 0-.532-.347Zm-.697-1.77a3.223 3.223 0 0 1 2.407.938 3.19 3.19 0 0 1 .93 2.406 3.178 3.178 0 0 1-1.127 2.277L6.842 18.173a.834.834 0 0 1-.37.214l-4.583 1.25a.834.834 0 0 1-1.024-1.023l1.25-4.583a.834.834 0 0 1 .215-.37L13.557 2.433a3.206 3.206 0 0 1 2.298-1.105Z" clip-rule="evenodd"/><path fill="#2C57DE" fill-rule="evenodd" d="M11.908 4.077a.833.833 0 0 1 1.179 0l3.333 3.334a.833.833 0 0 1-1.179 1.178l-3.333-3.333a.833.833 0 0 1 0-1.179Z" clip-rule="evenodd"/></svg>' />
        <div class="b-checkout-content__item-actions-separator"></div>
        <Button @click.stop="setProfileIsToDelete" data-bs-toggle="modal" data-bs-target="#deleteBillingModal" variant="link" size="small" color="blue" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none"><path fill="#2C57DE" fill-rule="evenodd" d="M1.664 5.5c0-.46.373-.833.833-.833h15a.833.833 0 0 1 0 1.666h-15a.833.833 0 0 1-.833-.833Z" clip-rule="evenodd"/><path fill="#2C57DE" fill-rule="evenodd" d="M4.17 4.667c.46 0 .833.373.833.833v11.667c0 .145.08.36.276.556.196.196.411.277.557.277h8.333c.146 0 .361-.081.557-.277.195-.195.277-.41.277-.556V5.5a.833.833 0 1 1 1.666 0v11.667c0 .687-.335 1.305-.765 1.735-.43.43-1.047.765-1.735.765H5.836c-.688 0-1.306-.336-1.735-.765-.43-.43-.765-1.048-.765-1.735V5.5c0-.46.373-.833.833-.833Z" clip-rule="evenodd"/><path fill="#2C57DE" fill-rule="evenodd" d="M7.78 3.277c-.196.195-.277.41-.277.556V5.5a.833.833 0 1 1-1.667 0V3.833c0-.687.335-1.305.765-1.735.43-.43 1.047-.765 1.735-.765h3.333c.688 0 1.306.336 1.735.765.43.43.765 1.048.765 1.735V5.5a.833.833 0 0 1-1.666 0V3.833c0-.145-.082-.36-.277-.556C12.03 3.08 11.815 3 11.669 3H8.336c-.146 0-.361.081-.557.277ZM8.333 8.833c.46 0 .834.373.834.834v5a.833.833 0 0 1-1.667 0v-5c0-.46.373-.834.833-.834ZM11.67 8.833c.46 0 .833.373.833.834v5a.833.833 0 0 1-1.667 0v-5c0-.46.373-.834.833-.834Z" clip-rule="evenodd"/></svg>' />
      </div>
    </div>
    <div v-if="isEditProfile" class="b-checkout-content__form">
      <UserProfileForm
        @on-close="handleCloseEditProfile"
        :profile="{ ...props.profile, isActive: isActiveProfile }"
        :is-read-only="isReadOnly"
      />
    </div>
  </div>
</template>

<style scoped>
  :deep(.b-button.eye-button path) {
    fill: none;
  }
  :deep(.b-button.eye-button:hover path) {
    fill: none;
  }
</style>
