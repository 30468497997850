<template>
  <select @change="e => emit('onChange', e.target.value)" ref="selectElement"></select>
</template>

<script setup>
import 'choices.js/public/assets/styles/choices.css';
import { ref, watch, onMounted, toRefs, defineEmits } from 'vue';
import { getChoicesInstance } from '@/utils/choices'

const props = defineProps(['modelValue', 'choiceType', 'defaultSelected', 'choicesList'])

const emit = defineEmits(['onChange'])

const { modelValue, choiceType, defaultSelected } = toRefs(props);

const selectElement = ref(null)
const choicesInstance = ref(null)

watch(modelValue, selected => {
  choicesInstance.value.setChoiceByValue(selected.toLowerCase())
})

onMounted(() => {
  choicesInstance.value = getChoicesInstance({
    choiceType: choiceType.value,
    defaultSelected: modelValue.value || defaultSelected.value,
    element: selectElement.value,
    choicesList: props.choicesList
  })
})
</script>
