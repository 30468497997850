import config from '@/config';
import { wpWg } from '@/axios.js'

export async function submitNewsletter(email) {
  return await wpWg.post('/general', {
    endpoint: '/newsletter/subscribe',
    method: 'POST',
    content: {
      email,
      lang: config.language,
      form_name: config.formName,
    }
  });
}
