<script setup>
import { openModal } from '@/utils'
import { useCheckoutStore } from '@/stores/checkout.store';
import Button from '@/stories/Button.vue';

const $checkout = useCheckoutStore()

const props = defineProps({
  productId: {
    type: Number,
    required: true
  },
  isHandlingPeriods: {
    type: Boolean,
    default: false
  },
  isHandlingLicences: {
    type: Boolean,
    default: false
  },
  isHandlingRemoval: {
    type: Boolean,
    default: true
  },
  isHandlingPack: {
    type: Boolean,
    default: false
  },
})

const calendarIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16406 4.16667C3.70383 4.16667 3.33073 4.53976 3.33073 5V16.6667C3.33073 17.1269 3.70383 17.5 4.16406 17.5H15.8307C16.291 17.5 16.6641 17.1269 16.6641 16.6667V5C16.6641 4.53976 16.291 4.16667 15.8307 4.16667H4.16406ZM1.66406 5C1.66406 3.61929 2.78335 2.5 4.16406 2.5H15.8307C17.2114 2.5 18.3307 3.61929 18.3307 5V16.6667C18.3307 18.0474 17.2114 19.1667 15.8307 19.1667H4.16406C2.78335 19.1667 1.66406 18.0474 1.66406 16.6667V5Z" fill="#2C57DE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3333 0.833008C13.7936 0.833008 14.1667 1.2061 14.1667 1.66634V4.99967C14.1667 5.45991 13.7936 5.83301 13.3333 5.83301C12.8731 5.83301 12.5 5.45991 12.5 4.99967V1.66634C12.5 1.2061 12.8731 0.833008 13.3333 0.833008Z" fill="#2C57DE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66927 0.833008C7.12951 0.833008 7.5026 1.2061 7.5026 1.66634V4.99967C7.5026 5.45991 7.12951 5.83301 6.66927 5.83301C6.20903 5.83301 5.83594 5.45991 5.83594 4.99967V1.66634C5.83594 1.2061 6.20903 0.833008 6.66927 0.833008Z" fill="#2C57DE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66406 8.33333C1.66406 7.8731 2.03716 7.5 2.4974 7.5H17.4974C17.9576 7.5 18.3307 7.8731 18.3307 8.33333C18.3307 8.79357 17.9576 9.16667 17.4974 9.16667H2.4974C2.03716 9.16667 1.66406 8.79357 1.66406 8.33333Z" fill="#2C57DE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83594 11.6663C5.83594 11.2061 6.20903 10.833 6.66927 10.833H6.6776C7.13784 10.833 7.51094 11.2061 7.51094 11.6663C7.51094 12.1266 7.13784 12.4997 6.6776 12.4997H6.66927C6.20903 12.4997 5.83594 12.1266 5.83594 11.6663Z" fill="#2C57DE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16406 11.6663C9.16406 11.2061 9.53716 10.833 9.9974 10.833H10.0057C10.466 10.833 10.8391 11.2061 10.8391 11.6663C10.8391 12.1266 10.466 12.4997 10.0057 12.4997H9.9974C9.53716 12.4997 9.16406 12.1266 9.16406 11.6663Z" fill="#2C57DE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 11.6663C12.5 11.2061 12.8731 10.833 13.3333 10.833H13.3417C13.8019 10.833 14.175 11.2061 14.175 11.6663C14.175 12.1266 13.8019 12.4997 13.3417 12.4997H13.3333C12.8731 12.4997 12.5 12.1266 12.5 11.6663Z" fill="#2C57DE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83594 15.0003C5.83594 14.5401 6.20903 14.167 6.66927 14.167H6.6776C7.13784 14.167 7.51094 14.5401 7.51094 15.0003C7.51094 15.4606 7.13784 15.8337 6.6776 15.8337H6.66927C6.20903 15.8337 5.83594 15.4606 5.83594 15.0003Z" fill="#2C57DE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16406 15.0003C9.16406 14.5401 9.53716 14.167 9.9974 14.167H10.0057C10.466 14.167 10.8391 14.5401 10.8391 15.0003C10.8391 15.4606 10.466 15.8337 10.0057 15.8337H9.9974C9.53716 15.8337 9.16406 15.4606 9.16406 15.0003Z" fill="#2C57DE"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 15.0003C12.5 14.5401 12.8731 14.167 13.3333 14.167H13.3417C13.8019 14.167 14.175 14.5401 14.175 15.0003C14.175 15.4606 13.8019 15.8337 13.3417 15.8337H13.3333C12.8731 15.8337 12.5 15.4606 12.5 15.0003Z" fill="#2C57DE"/>
</svg>`

const licenceIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_13454_90424)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0026 2.49967C5.86047 2.49967 2.5026 5.85754 2.5026 9.99967C2.5026 14.1418 5.86047 17.4997 10.0026 17.4997C14.1447 17.4997 17.5026 14.1418 17.5026 9.99967C17.5026 5.85754 14.1447 2.49967 10.0026 2.49967ZM0.835938 9.99967C0.835938 4.93706 4.93999 0.833008 10.0026 0.833008C15.0652 0.833008 19.1693 4.93706 19.1693 9.99967C19.1693 15.0623 15.0652 19.1663 10.0026 19.1663C4.93999 19.1663 0.835938 15.0623 0.835938 9.99967Z" fill="#2C57DE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83594 10.0003C5.83594 9.54009 6.20903 9.16699 6.66927 9.16699H13.3359C13.7962 9.16699 14.1693 9.54009 14.1693 10.0003C14.1693 10.4606 13.7962 10.8337 13.3359 10.8337H6.66927C6.20903 10.8337 5.83594 10.4606 5.83594 10.0003Z" fill="#2C57DE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9974 5.83301C10.4576 5.83301 10.8307 6.2061 10.8307 6.66634V13.333C10.8307 13.7932 10.4576 14.1663 9.9974 14.1663C9.53716 14.1663 9.16406 13.7932 9.16406 13.333V6.66634C9.16406 6.2061 9.53716 5.83301 9.9974 5.83301Z" fill="#2C57DE"/>
  </g>
  <defs>
    <clipPath id="clip0_13454_90424">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>`

const deleteIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66406 5.00033C1.66406 4.54009 2.03716 4.16699 2.4974 4.16699H17.4974C17.9576 4.16699 18.3307 4.54009 18.3307 5.00033C18.3307 5.46056 17.9576 5.83366 17.4974 5.83366H2.4974C2.03716 5.83366 1.66406 5.46056 1.66406 5.00033Z" fill="#F03A49"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16927 4.16699C4.62951 4.16699 5.0026 4.54009 5.0026 5.00033V16.667C5.0026 16.8126 5.08381 17.028 5.27936 17.2236C5.4749 17.4191 5.6903 17.5003 5.83594 17.5003H14.1693C14.3149 17.5003 14.5303 17.4191 14.7258 17.2236C14.9214 17.028 15.0026 16.8126 15.0026 16.667V5.00033C15.0026 4.54009 15.3757 4.16699 15.8359 4.16699C16.2962 4.16699 16.6693 4.54009 16.6693 5.00033V16.667C16.6693 17.3547 16.3338 17.9726 15.9044 18.4021C15.4749 18.8315 14.857 19.167 14.1693 19.167H5.83594C5.14824 19.167 4.5303 18.8315 4.10085 18.4021C3.67139 17.9726 3.33594 17.3547 3.33594 16.667V5.00033C3.33594 4.54009 3.70903 4.16699 4.16927 4.16699Z" fill="#F03A49"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.77936 2.77643C7.58381 2.97198 7.5026 3.18737 7.5026 3.33301V4.99967C7.5026 5.45991 7.12951 5.83301 6.66927 5.83301C6.20903 5.83301 5.83594 5.45991 5.83594 4.99967V3.33301C5.83594 2.64531 6.17139 2.02737 6.60085 1.59792C7.0303 1.16846 7.64824 0.833008 8.33594 0.833008H11.6693C12.357 0.833008 12.9749 1.16846 13.4044 1.59792C13.8338 2.02737 14.1693 2.64531 14.1693 3.33301V4.99967C14.1693 5.45991 13.7962 5.83301 13.3359 5.83301C12.8757 5.83301 12.5026 5.45991 12.5026 4.99967V3.33301C12.5026 3.18737 12.4214 2.97198 12.2258 2.77643C12.0303 2.58089 11.8149 2.49967 11.6693 2.49967H8.33594C8.1903 2.49967 7.97491 2.58089 7.77936 2.77643Z" fill="#F03A49"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33333 8.33301C8.79357 8.33301 9.16667 8.7061 9.16667 9.16634V14.1663C9.16667 14.6266 8.79357 14.9997 8.33333 14.9997C7.8731 14.9997 7.5 14.6266 7.5 14.1663V9.16634C7.5 8.7061 7.8731 8.33301 8.33333 8.33301Z" fill="#F03A49"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6693 8.33301C12.1295 8.33301 12.5026 8.7061 12.5026 9.16634V14.1663C12.5026 14.6266 12.1295 14.9997 11.6693 14.9997C11.209 14.9997 10.8359 14.6266 10.8359 14.1663V9.16634C10.8359 8.7061 11.209 8.33301 11.6693 8.33301Z" fill="#F03A49"/>
</svg>`
</script>

<template>
<div 
  class="dropdown dropdown--actions d-xl-none"
  :class="{ 'pe-none': $checkout.updatingBasket }"
>
  <Button
    data-bs-toggle="dropdown"
    aria-expanded="false"
    variant="link"
    size="small"
    color="blue"
    class="p-0 d-xl-none"
    iconLeft='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9.9974 10.8337C10.4576 10.8337 10.8307 10.4606 10.8307 10.0003C10.8307 9.54009 10.4576 9.16699 9.9974 9.16699C9.53716 9.16699 9.16406 9.54009 9.16406 10.0003C9.16406 10.4606 9.53716 10.8337 9.9974 10.8337Z" stroke="#2C57DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.9974 4.99967C10.4576 4.99967 10.8307 4.62658 10.8307 4.16634C10.8307 3.7061 10.4576 3.33301 9.9974 3.33301C9.53716 3.33301 9.16406 3.7061 9.16406 4.16634C9.16406 4.62658 9.53716 4.99967 9.9974 4.99967Z" stroke="#2C57DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.9974 16.6667C10.4576 16.6667 10.8307 16.2936 10.8307 15.8333C10.8307 15.3731 10.4576 15 9.9974 15C9.53716 15 9.16406 15.3731 9.16406 15.8333C9.16406 16.2936 9.53716 16.6667 9.9974 16.6667Z" stroke="#2C57DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
  />
  <div class="dropdown-menu">
    <Button 
      v-if="isHandlingPack"
      :iconLeft="calendarIcon" 
      :label="$t('checkout.periods.title')" 
      variant="link" 
      color="black"
      @click="openModal(`mobilePacks-${props.productId}`)"
    />
    <Button 
      v-if="isHandlingPeriods"
      :iconLeft="calendarIcon" 
      :label="$t('checkout.periods.title')" 
      variant="link" 
      color="black"
      @click="openModal(`mobilePeriods-${props.productId}`)"
    />
    <Button
      v-if="isHandlingLicences"
      :iconLeft="licenceIcon" 
      :label="$t('checkout.numberOfLicences')" 
      variant="link" 
      color="black" 
      @click="openModal(`licencesModal-${props.productId}`)"
    />
    <Button
      v-if="isHandlingRemoval"
      :iconLeft="deleteIcon" 
      label="Odstraniť" 
      variant="link" 
      color="black" 
      class="deleteAction"  
      @click="openModal(`removeProductModal-${props.productId}`)" 
    />
  </div>
</div>
</template>