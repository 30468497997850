<script setup>
import { computed, ref, shallowRef } from 'vue'
import { getPriceLabel } from '@/utils/checkout'
import { simpleClone } from '@/utils'
import { getTldPrice } from '@/utils/das'
import ListItem from '@/components/domain-list/ListItem.vue'
import HeaderListItem from '@/components/domain-list/HeaderListItem.vue'
import ListItemError from '@/components/domain-list/ListItemError.vue'
import { useGlobalStore } from '@/stores/global.store'
import { t } from '@/i18n-instance'

const props = defineProps({
  tldList: {
    type: Object,
    required: true
  },
  currentActiveTld: {
    type: Object,
    default: null,
  },
  currentSearchedTld: {
    type: String,
    default: ''
  }
})

const $global = useGlobalStore()

const emit = defineEmits(['onSetActiveTld', 'onUnsetActiveTld'])

const preferredDomains = simpleClone($global.getConfig('das_default')?.domain_list ?? [])

const tldArray = Object.values(props.tldList)

const isSortingDirty = ref(false)

const listSortingModel = ref({
  name: {
    title: t('domainList.name'),
    isActive: true,
    isReversed: true
  },
  country: {
    title: t('domainList.country'),
    isActive: false,
    isReversed: false
  },
  price: {
    title: `<span>${t('domainList.dph')}</span> <span class='b-domain-list-section__list-header__item__comment'>/ ${t('domainList.year')}</span>`,
    isActive: false,
    isReversed: false
  }
})

const handleChangeSortingModel = ({ sortingKey }) => {
  Object.keys(listSortingModel.value).forEach(key => {
    listSortingModel.value[key].isActive = false
  })
  listSortingModel.value[sortingKey].isActive = true
}

const handleChangeSortingModelDirection = ({ sortingKey, direction = 'up' }) => {
  if(!isSortingDirty.value) {
    isSortingDirty.value = true
  }

  handleChangeSortingModel({ sortingKey })

  if(direction === 'up') {
    listSortingModel.value[sortingKey].isReversed = true
    }

  if(direction === 'down') {
    listSortingModel.value[sortingKey].isReversed = false
  }
}

const tldArraySorted = computed(() => {
  let resultArray = tldArray.sort((a, b) => {
    const aPriority = preferredDomains.indexOf(a.tl_name);
    const bPriority = preferredDomains.indexOf(b.tl_name);

    if (aPriority !== -1 && bPriority !== -1) {
        return aPriority - bPriority;
    }
    if (aPriority !== -1) {
        return -1;
    }
    if (bPriority !== -1) {
        return 1;
    }
    return a.tl_name.localeCompare(b.tl_name);
  })

  if(props.currentActiveTld) {
    resultArray = resultArray.filter(tldItem => tldItem.tl_name === props.currentActiveTld.tl_name)
  }

  if (props.currentSearchedTld) {
    const search = props.currentSearchedTld.replace(/^\./, '');
    const normalizedSearch = normalizeString(search);
    resultArray = resultArray.filter(tldItem => {
      const normalizedTlName = normalizeString(tldItem.tl_name);
      const normalizedCountry = normalizeString(tldItem.country || '');
      return normalizedTlName.includes(normalizedSearch) || normalizedCountry.includes(normalizedSearch);
    });
  }

  if(isSortingDirty.value) {
    if(listSortingModel.value.name.isActive) {
      if(listSortingModel.value.name.isReversed) {
        resultArray = resultArray.sort((tldA, tldB) => tldA.tl_name.localeCompare(tldB.tl_name))
      } else {
        resultArray = resultArray.sort((tldA, tldB) => tldB.tl_name.localeCompare(tldA.tl_name))
      }
    }

    if(listSortingModel.value.country.isActive) {
      if(listSortingModel.value.country.isReversed) {
        resultArray = resultArray.sort((tldA, tldB) => tldB.country?.localeCompare(tldA.country))
      } else {
        resultArray = resultArray.sort((tldA, tldB) => tldA.country?.localeCompare(tldB.country))
      }
    }

    if(listSortingModel.value.price.isActive) {
      if(!listSortingModel.value.price.isReversed) {
        resultArray = resultArray.sort((tldA, tldB) => getTldPrice(tldB) - getTldPrice(tldA))
      } else {
        resultArray = resultArray.sort((tldA, tldB) => getTldPrice(tldA) - getTldPrice(tldB))
      }
    }
  }

  return resultArray
})

function normalizeString(str) {
  return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
</script>

<template>
<div class="b-domain-list-section__list">
  <ul class="b-domain-list-section__list-header">
    <HeaderListItem
      v-for="sortingKey of Object.keys(listSortingModel)"
      :key="sortingKey"
      :title="listSortingModel[sortingKey].title"
      :type="sortingKey"
      :is-active="listSortingModel[sortingKey].isActive"
      :is-reversed="listSortingModel[sortingKey].isReversed"
      @on-direction-up="handleChangeSortingModelDirection({ sortingKey, direction: 'up' })"
      @on-direction-down="handleChangeSortingModelDirection({ sortingKey, direction: 'down' })"
    />

  </ul>
  <template v-if="tldArraySorted.length">
    <ListItem
      v-for="tldItem of tldArraySorted"
      :key="tldItem.tl_name"
      :tld-item="tldItem"
      :current-active-tld="props.currentActiveTld"
      @on-set-active="tldItem => emit('onSetActiveTld', tldItem)"
      @on-set-unactive="emit('onUnsetActiveTld')"
    />
  </template>
  <ListItemError v-else />
</div>
</template>
