<script setup>
// core imports
import { provide, ref, computed} from "vue";
import { usePricingTableStore } from "@/stores/pricing-table.store";
import {useGlobalStore} from "@/stores/global.store"
import TldRow from './TldRow.vue'
import TldSearch from './TldSearch.vue'
const pricingTableStore = usePricingTableStore();

const showLoadMoreBtn = computed(() => {
  const results = pricingTableStore.getResults().length
  return results && results < pricingTableStore.tldList.length && pricingTableStore.searchTldInput.length === 0 && pricingTableStore.selectedTld === undefined
})

</script>

<template>
  <div class="tld-content">
    <div v-if="pricingTableStore.config.show_searchbar" class="tld-search" :class="pricingTableStore.config.search_bar_position">
      <TldSearch />
    </div>
    <div class="tld-row">
      <div class="tld-row-item tld-row-header">
        <div class="tld-col tld-col__name">Název</div>
        <div class="tld-col tld-col__sale"></div>
        <div class="tld-col tld-col__price">Cena bez DPH / rok</div>
        <div class="tld-col tld-col__action"></div>
      </div>
    </div>
    <TldRow v-for="tld in pricingTableStore.getResults()" :key="tld.tl_name" :tld="tld"></TldRow>
  </div>

</template>

<style lang="scss" scoped>
.text-wrap {
  max-width: 1060px;
  text-align: center;
  margin: 0 auto 48px;
  @media (max-width: $md) {
    margin: 0 auto 24px;
  }
  h2 {
    font-size: 40px;
    margin-bottom: 24px;
    @media (max-width: $md) {
      font-size: 28px;
      margin-bottom: 12px;
    }
  }
  p {
    font-size: 20px;
    @media (max-width: $md) {
      font-size: 16px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.tld-search {
  width: 100%;
  max-width: 585px;
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;
  &.left {
    margin-left: 0;
  }
  &.right {
    margin-right: 0;
  }
}
.tld-content {
  max-width: 991px;
  margin: 36px auto 0;
  :deep(.tld-row) {
    &:nth-child(odd) {
      .tld-row-item {
        background: #FAFCFF;
      }
    }
    .tld-row-item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 50px;
      border-bottom: 1px solid #B6B6B6;
      font-size: 20px;
      @media (max-width: $md) {
        font-size: 16px;
        grid-template-columns: 90px 1fr 1fr 38px;
      }
      &.tld-row-header {
        background: none;
        border-bottom: 2px solid #02117F;
        font-weight: 700;
          @media (max-width: $md) {
          grid-template-columns: 90px 1fr;
          }
        .tld-col__sale,
        .tld-col__action {
          @media (max-width: $md) {
            display: none;
          }
        }
      }
      .tld-col {
        padding: 12px 16px;
        @media (max-width: $md) {
          padding: 12px 4px;
        }
      }
    }
  }
}
.tld-show-more {
  text-align: center;
  padding: 12px 0;
  button {
    min-height: 28px;
    padding: 4px 8px;
    border: 1px solid #02117F;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: none;
    font-size: 11px;
    font-weight: 600;
    @include anim(all);
    &:hover {
      color: #fff;
      background: #02117F;
    }
  }
}
</style>
