<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_18434_214231)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5564 0.75 17.25 4.44365 17.25 9C17.25 13.5564 13.5564 17.25 9 17.25C4.44365 17.25 0.75 13.5564 0.75 9Z" fill="#2C57DE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 8C9.55228 8 10 8.3731 10 8.83333V12.1667C10 12.6269 9.55228 13 9 13C8.44772 13 8 12.6269 8 12.1667V8.83333C8 8.3731 8.44772 8 9 8Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 5C8 4.44772 8.44549 4 8.99502 4H9.00498C9.55451 4 10 4.44772 10 5C10 5.55228 9.55451 6 9.00498 6H8.99502C8.44549 6 8 5.55228 8 5Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_18434_214231">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</template>