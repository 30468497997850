// import '/node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import "/node_modules/flag-icons/css/flag-icons.min.css";

import * as components from './components'
import * as pages from './pages'
// console.log('components', components);

window.bootstrap = bootstrap;

const wegGlobe = {
  ...components,
  ...pages,
}

// register wegGlobe to window object
window.webGlobe = wegGlobe;

window.dataLayer = window.dataLayer || [];
window.gtag = function gtag(){dataLayer.push(arguments);}
export default wegGlobe;
