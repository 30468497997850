<script setup>
import { computed } from "vue";
import SummaryItem from './SummaryItem.vue';

import {getPeriodLabel, getPriceLabel} from '@/utils/checkout';

const props = defineProps({
  product: Object,
  isSubproduct: Boolean,
})

const getDesciptionHtml = computed(() => `${getPeriodLabel(props.product.period)} - ${props.product.description}`)

</script>

<template>
  <div class="b-checkout-summary__product" :class="{'b-checkout-summary__product--sub': isSubproduct }">
    <div class="b-checkout-summary__product-name">
      <p
        class="b-checkout-summary__product-name-title"
        v-html="product.name"
      >
      </p>
      <div class="b-checkout-summary__product-name-description" v-html="getDesciptionHtml"></div>
    </div>
    <div class="b-checkout-summary__product-price">{{ getPriceLabel(product.price_final) }}</div>
  </div>
  <template v-for="subproduct in product?.subitems" :key="subproduct">
    <SummaryItem v-if="subproduct.in_basket" :product="subproduct" :is-subproduct="true" />
  </template>
</template>
