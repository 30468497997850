<script setup></script>

<template>
  <div class="b-checkout-order skeleton">
    <div class="b-checkout-order__header">
      <div class="b-checkout-order__header-title">
        <h2 class="b-checkout-order__header-title-h2 pulse mb-5">title</h2>
        <p class="b-checkout-order__p pulse">description</p>
      </div>
      <div class="b-checkout-order__options d-none d-xl-block">
        <div class="button-pulse pulse">periods</div>
      </div>
      <div class="b-checkout-order__price">
        <div class="button-pulse pulse">actions</div>
      </div>
    </div>
    <div class="b-checkout-order__content b-checkout-order__content-main">
      <div class="b-checkout-order__subservices">
        <div class="b-checkout-order__subservice subservices-skeleton">
          <div class="b-checkout-order__subservice-item mt-10"></div>
          <div class="b-checkout-order__subservice-item"></div>
          <div class="b-checkout-order__subservice-item"></div>
        </div>
      </div>
    </div>
  </div>
</template>
