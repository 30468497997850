import 'choices.js/public/assets/styles/choices.css';
import { getChoicesInstance } from '@/utils/choices'
import config from '@/config'

const init = () => {
  const selectElements = document.querySelectorAll('.js-choices');
  
  selectElements.forEach(selectElement => {
    const dataChoice = selectElement.getAttribute('data-selected-choices');

    const defaultSelected = (() => {
      if(dataChoice === 'country') {
        return config.language.toUpperCase()
      }
      if(dataChoice === 'phone') {
        return config.phonePrefix
      }
    })()

    return getChoicesInstance({ choiceType: dataChoice, defaultSelected, element: selectElement })
  });
}

export const choices = {
  init,
}

