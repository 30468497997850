<script setup>
// core imports
import { provide, ref, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useGlobalStore } from "@/stores/global.store";
import { useAuthStore } from "@/stores/auth.store";
import { parseOpts } from "@/utils";
// import components
import LoginWrap from "@/components/auth/LoginWrap.vue";
import RegisterWrap from "@/components/auth/RegisterWrap.vue";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import MyAccount from "@/components/auth/MyAccount.vue";

const props = defineProps({
  configId: String | Number | undefined,
  componentId: String | Number | undefined,
  opts: String | undefined,
});
const conf = ref(null);

const globalStore = useGlobalStore();
const authStore = useAuthStore();

const activeComponent = computed(() => {
  const comp = authStore.activeComponent;
  switch (comp) {
    case "login":
      return LoginWrap;
    case "register":
      return RegisterWrap;
    case "forgot-password":
      return ForgotPassword;
    case "myAccount":
      return MyAccount;
    case "otp":
      return MyAccount;
    default:
      throw new Error(`Unknown component id: ${comp}`);
  }
});

const initComp = () => {
  conf.value = globalStore.getConfig('auth');
  const opts = parseOpts(props.opts);
  authStore.setConfig(conf.value, opts);
  if (authStore.isLoggedIn) {
    authStore.setActiveComponent("myAccount");
  } else {
    authStore.setActiveComponent("login");
  }
};

const { ready } = storeToRefs(globalStore);
watch(ready, (val) => {
  initComp();
});

if (ready.value) {
  initComp();
}

// provide config data to all child components
provide("config", conf);
provide("componentId", props.componentId);
</script>

<template>
  <component :is="activeComponent" />
</template>

<style scoped></style>
