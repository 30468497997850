import config from '@/config';
import {wpWg} from '@/axios.js'
import { getClearDomain, getTldFromDomainInput } from '@/utils'


export async function getWhoIs(domainWithTld) {
  try {
    const res = await wpWg.post('/general', {
      endpoint: `/order/whois`,
      method: 'GET',
      withFormName: true,
      content: {
        domain: getClearDomain(domainWithTld),
        tld: getTldFromDomainInput(domainWithTld),
        form_name: config.formName
      }
    });

    return res.data
  } catch (e) {
    return e.response.data
  }
}