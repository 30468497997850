<script setup>
import {computed} from 'vue';
import { useGlobalStore } from '@/stores/global.store';
import { useAuthStore } from '@/stores/auth.store'
import WgAuth from '@/WgAuth.vue';
import WgCart from '@/WgCart.vue';
const globalStore = useGlobalStore();
import IconClose from './auth/svg/iconClose.vue'

const activeComponent = computed(() => {
  const comp = globalStore.rightPanelComp;
  switch(comp) {
    case 'WgAuth':
      return WgAuth;
    case 'WgCart':
      return WgCart;
    default:
      return null;
  }
})

const authStore = useAuthStore()

const closeRightPanel = () => {
  globalStore.setRightPanelComp(null);
}

const isSubStep = computed(() => ['forgot-password', 'register'].includes(authStore.activeComponent))
</script>

<template>
  <div class="panel-right__inner">
    <button v-if="!isSubStep" class="panel-right__close" @click="closeRightPanel"><IconClose /></button>
    <component :is="activeComponent" :isRightPanel="true" />
  </div>
</template>
