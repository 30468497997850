import { defineStore } from "pinia";
import { closeAllModals, simpleClone } from "@/utils";
import {
  login, 
  getProfile, 
  logout, 
  lostPassword, 
  lostLogin, 
  register, 
  getBillProfiles, 
  foxentryCompany, 
  foxentryEmailValidation, 
  foxentryNameValidation, 
  foxentryPhoneValidation
} from "@/services"
import { t } from '@/i18n-instance';
import config from "@/config"
import { useCheckoutStore } from "./checkout.store";
import { useGlobalStore } from "./global.store";

const formRegister = {
  isCompany: false,
  invoice_name:  '',
  invoice_companyid: '',
  invoice_tax: '',
  invoice_vat: '',
  name: '',
  surname: '',
  contact_name: '',
  contact_phone: '',
  invoice_street: '',
  invoice_town: '',
  invoice_postcode: '',
  invoice_state: config.invoiceState.toUpperCase(),
  contact_email: '',
  password: '',
  invoice_currency: config.currency,
  invoice_language: config.invoiceLanguage,
  legal_form: '',
  termsAcceptance: false,
  marketingAcceptance: false,
  foxentryValidator: {
    email: {
      isValid: true,
      value: ''
    },
    phone: {
      isValid: true,
      value: ''
    },
    name: {
      isValid: true,
      value: ''
    },
    surname: {
      isValid: true,
      value: ''
    }
  }
}

export const useAuthStore = defineStore("auth", {
  state: () => ({
    config: {},
    errorMsg: '',
    errorRegisterMsg: '',
    activeComponent: 'login',
    pending: false,
    isLoggedIn: false,
    user: {},
    twoFA: null,
    form: {
      register: simpleClone(formRegister)
    }
  }),
  getters: {
    isFormValid: (state) => {
      return (formName) => Object.values(state.form[formName].foxentryValidator).every(item => item.isValid);
    },
    isLogged: (state) => window.vueLoginData || state.isLoggedIn,
  },
  actions: {
    setConfig(config, opts) {
      this.config = {
        ...config,
        ...opts ? opts : {}
      }
    },
    setActiveComponent(component) {
      this.resetRegisterForm()
      this.activeComponent = component;
      this.errorMsg = '';
    },

    async getUser() {
      try {
        const {data} = await getBillProfiles()
        if (data.data?.main_profile) {
          this.user = data.data?.main_profile;
          this.isLoggedIn = true;
        }
      } catch (error) {
        console.log('error', error);
      }
    },

    async checkIsLoggedIn() {
      if (window.vueLoginData) {
        await this.getProfile();
        this.setActiveComponent('myAccount')
      }
    },

    async login({ username, password, otp, sms, isCheckout }) {
      try {
        this.errorMsg = '';
        this.pending = true;
        const {data} = await login({ username, password, otp, sms });
        if (data['2fa']) {
          this.twoFA = data['2fa'];
          this.pending = false;
          return;
        }
        window.webGlobe.dataLayer.sendData({
          event: 'web_login_success',
          user: {
            type: 'logged in',
            id: data.id_customer,
          },
        })
        this.isLoggedIn = true;
        this.setActiveComponent('myAccount')
        const $global = useGlobalStore();
        $global.setRightPanelComp(null);
        await this.getProfile();
        const $checkout = useCheckoutStore();
        $checkout.getBasketData(true)
        if (isCheckout) {
          closeAllModals();
          $checkout.setStep(2)
          this.resetRegisterForm();
        }
        const menuElement = window.document.querySelector('.b-top-menu-mobile2');
        if (menuElement) {
          menuElement.classList.add('d-none');
        }
      } catch (error) {
        console.log(error)
        this.errorMsg = t('auth.login.errors.wrongCredentials')
      } finally {
        this.pending = false;
      }
    },

    async logout() {
      try {
        await logout();
        const $checkout = useCheckoutStore();
        $checkout.getBasketData(true)
        const config = simpleClone(this.config);
        this.$reset();
        this.setConfig(config);
        this.isLoggedIn = false;
        window.vueLoginData = null;
        const menuElement = window.document.querySelector('.b-top-menu-mobile2');
        if (menuElement) {
          menuElement.classList.remove('d-none');
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getProfile() {
      try {
        this.pending = true;
        const {data} = await getProfile();
        this.user = data.data;
        this.isLoggedIn = true;
      } catch (error) {
        console.log(error)
        this.isLoggedIn = false;
      } finally {
        this.pending = false;
      }
    },

    async lostPassword({ login }) {
      this.errorMsg = ''
      this.pending = true;

      const [lostPassData, lostLoginData] = await Promise.all([
        lostPassword({ login }),
        lostLogin({ domain: login })
      ])

      this.pending = false

      if(lostPassData?.data?.error?.message && lostLoginData?.data?.error?.message) {
        this.errorMsg = t('auth.lost_password.errors.wrongLogin')
        return [null]
      }

      if(lostPassData?.data?.success || lostLoginData?.data?.success) {
        return [lostPassData.data, lostLoginData.data].filter(data => data.success)
      }
    },

    async register(resetForm = true) {
      try {
        this.errorRegisterMsg = '';
        this.pending = true;

        const registerFormObject = simpleClone(this.form.register)

        if (!registerFormObject.isCompany) {
          delete registerFormObject.invoice_name
          delete registerFormObject.invoice_companyid
          delete registerFormObject.invoice_tax
          delete registerFormObject.invoice_vat
        }

        const customerPostCodeSanitized = registerFormObject.invoice_postcode
          .replace(' ', '')

        const customerPhoneSanizited = registerFormObject.contact_phone
          .replace(' ', '')

        registerFormObject.contact_phone = customerPhoneSanizited
        registerFormObject.invoice_postcode = customerPostCodeSanitized

        const {data} = await register(registerFormObject);

        if (resetForm) {
          this.resetRegisterForm();
        }
        return data;
      } catch (error) {
        const errorData = error?.response?.data?.error
        this.errorRegisterMsg = t(`auth.register.errors[${errorData?.code}]`)
        throw error
      } finally {
        this.pending = false;
      }
    },

    resetRegisterForm() {
      this.$patch({
        form: {
          register: simpleClone(formRegister)
        }
      })
    },

    async foxentryEmailValidation(email) {
      const query = {
        email
      }
      const options = {
        validationType: 'extended',
        acceptDisposableEmails: false,
        acceptFreemails: true
      }
      try {
        const {data} = await foxentryEmailValidation(query, options);
        this.form.register.foxentryValidator.email.isValid = data?.response?.result?.isValid;
        this.form.register.foxentryValidator.email.value = email;
        return data?.response?.result;
      } catch (error) {
        console.log(error)
      }
    },

    async foxentryPhoneValidation(number, prefix) {
      const query = {
        number,
        prefix
      }
      const options = {
        validationType: 'extended',
        formatNumber: false
      }
      try {
        const {data} = await foxentryPhoneValidation(query, options);
        this.form.register.foxentryValidator.phone.isValid = data?.response?.result?.isValid;
        this.form.register.foxentryValidator.phone.value = prefix+number;
        return data?.response?.result;
      } catch (error) {
        console.log(error)
      }
    },

    async foxentryNameValidation({name, surname}) {
      const query = {
        name,
        surname
      }
      const options = {
        dataScope: 'basic',
        acceptDegrees: false,
        acceptContext: false,
        validationDepth: 'minimal',
        smartMode: false
      }
      try {
        const {data} = await foxentryNameValidation(query, options);
        const type = name ? 'name' : 'surname';
        const value = name || surname;
        this.form.register.foxentryValidator[type].isValid = data?.response?.result?.isValid;
        this.form.register.foxentryValidator[type].value = value;
        return data?.response?.result;
      } catch (error) {
        console.log(error)
      }
    },

    async foxentryCompany(search, type, source) {
      if (type === 'registrationNumber') {
        search = search.replace(/\s/g, '');
      }
      const query = {
        type: type || 'name',
        value: search,
      }
      const options = {
        dataScope: 'basic',
        dataSource: [`${source.toUpperCase()}`],
        resultsLimit: 15,
        includeTerminatedSubjects: false,
        filterMode: 'limit',
        filterAcceptFormat: true,
        filterAcceptAlternatives: true,
        filterExactMatch: false
      }
      try {
        const {data} = await foxentryCompany(query, options);
        return data?.response?.results || [];
      } catch (error) {
        console.log(error)
      }
    },
  },
  /*persist: {
    debug: false,
    paths: [ 'isLoggedIn'],
  },*/
});
