<script setup>
import { ref } from 'vue'

const props = defineProps({
  message: String,
  status: {
    type: String,
    validator: status => ['error', 'info', 'success'].includes(status),
    default: 'info'
  },
})

const errorIcon = window.webGlobe.global.getSvgDataForImageSrc(`
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11.9985 9.00022V13.0002M11.9985 17.0002H12.0085M21.7285 18.0002L13.7285 4.00022C13.554 3.69243 13.3011 3.43641 12.9954 3.25829C12.6897 3.08017 12.3423 2.98633 11.9885 2.98633C11.6347 2.98633 11.2872 3.08017 10.9815 3.25829C10.6759 3.43641 10.4229 3.69243 10.2485 4.00022L2.24846 18.0002C2.07215 18.3056 1.97969 18.6521 1.98047 19.0047C1.98125 19.3573 2.07524 19.7035 2.25291 20.008C2.43058 20.3126 2.68561 20.5648 2.99216 20.7391C3.29871 20.9133 3.64587 21.0034 3.99846 21.0002H19.9985C20.3494 20.9999 20.694 20.9072 20.9977 20.7315C21.3015 20.5558 21.5537 20.3033 21.729 19.9993C21.9043 19.6954 21.9965 19.3506 21.9964 18.9997C21.9963 18.6488 21.9039 18.3041 21.7285 18.0002Z" stroke="#FD5D18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`)

const infoIcon = window.webGlobe.global.getSvgDataForImageSrc(`
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#2C57DE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11Z" fill="#2C57DE"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 8C11 7.44772 11.4477 7 12 7H12.01C12.5623 7 13.01 7.44772 13.01 8C13.01 8.55228 12.5623 9 12.01 9H12C11.4477 9 11 8.55228 11 8Z" fill="#2C57DE"/>
  </svg>
`)

const successIcon = window.webGlobe.global.getSvgDataForImageSrc(`
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 5.92472 5.92472 1 12 1C18.0753 1 23 5.92472 23 12C23 18.0753 18.0753 23 12 23C5.92472 23 1 18.0753 1 12ZM12 3C7.02928 3 3 7.02928 3 12C3 16.9707 7.02928 21 12 21C16.9707 21 21 16.9707 21 12C21 7.02928 16.9707 3 12 3Z" fill="#49C380"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z" fill="#49C380"/>
  </svg>
`)

</script>

<template>
  <div
    :class="`b-status-bar b-status-bar--${props.status}`"
  >
    <div class="b-status-bar__icon">
      <img v-if="props.status === 'info'" :src="infoIcon" alt="">
      <img v-if="props.status === 'error'" :src="errorIcon" alt="">
      <img v-if="props.status === 'success'" :src="successIcon" alt="">
    </div>
    <span class="b-status-bar__text">
      {{ props.message }}
    </span>
  </div>
</template>