<script setup>
import { ref, computed } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import NxtInputSlide from '@/components/shared/NxtInputSlide.vue'
import ServerConfiguratorItemBadge from './ServerConfiguratorItemBadge.vue'

const props = defineProps({
  modelValue: {
    type: Number,
    required: true
  },
  id: {
    type: String,
  },
  label: {
    type: String,
    required: true
  },
  default: {
    type: Number,
    required: true
  },
  unit: {
    type: String,
    required: true
  },
  min: {
    type: Number,
    required: true
  },
  max: {
    type: Number,
    required: true
  },
  step: {
    type: Number,
    default: 1,
  },
  isCollapsed: {
    type: Boolean,
    default: true
  },
  v: {
    type: Object,
    required: true
  },
  price: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['onChange'])

const isChanging = ref(false)

const total = ref(props.default)

const onChange = value => {
  props.v?.$touch();

  const valueNumerized = Number(value);

  if (isNaN(valueNumerized)) {
    total.value = props.min
    emit('onChange', total.value);
    isChanging.value = false
    return;
  }
    
  if (valueNumerized === 0) {
    total.value = props.min;
    emit('onChange', total.value);
    isChanging.value = false
    return;
  }

  if (valueNumerized < props.min) {
    total.value = props.min;
    emit('onChange', total.value);
    isChanging.value = false
    return;
  }
    
  if (valueNumerized > props.max) {
    total.value = props.max;
    emit('onChange', total.value);
    isChanging.value = false
    return;
  }

  total.value = valueNumerized;
  emit('onChange', total.value);
  isChanging.value = false
};

const debouncedOnChange = useDebounceFn(onChange, 1000);

const handleBadgeInput = (e) => {
  isChanging.value = true
  debouncedOnChange(e.target.value)
}

const maxCharactersOfValue = computed(() => props.modelValue.toString().split('').length)
</script>

<template>
<li class="b-server-configurator__form-controls-params-list-item">
   <div 
      v-if="v?.$errors.length" 
      class="b-server-configurator__form-controls-params-list-item-errors"
    >
      <p
        v-for="{ $message: errorMessage } of v.$errors"
        class="b-server-configurator__form-controls-params-list-item-errors-item"
      >
        {{ errorMessage }}
      </p>
    </div>
  <div
    class="b-server-configurator__form-controls-params-list-item-title"
  >
    <p class="b-server-configurator__form-controls-params-list-item-title-text">{{ props.label }}</p>
    <ServerConfiguratorItemBadge 
      :model-value="modelValue"
      :price="price"
      :unit="props.unit"
      :is-changing="isChanging"
      :max-characters-of-value="maxCharactersOfValue"
      :input-handler="handleBadgeInput"
    />
  </div>
  <div 
    class="b-server-configurator__form-controls-params-list-item-collapsible-container"
  >
    <div class="b-server-configurator__form-controls-params-list-item-collapsible-container-body">
      <NxtInputSlide
        :model-value="modelValue"
        :min="props.min" 
        :max="props.max" 
        :step="props.step" 
        @on-change="onChange" 
      />
      <div class="b-server-configurator__form-controls-params-list-item-collapsible-container-body-values">
        <div 
          class="b-server-configurator__form-controls-params-list-item-collapsible-container-body-value-min"
        >
          {{ props.min }} {{ props.unit }}
        </div>
        <div 
          class="b-server-configurator__form-controls-params-list-item-collapsible-container-body-value-max"
        >
          {{ props.max }} {{ props.unit }}
        </div>
      </div>
     
    </div>
  </div>
</li>
</template>