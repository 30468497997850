<script setup>
  import { ref, defineProps } from 'vue'
  import Button from '@/stories/Button.vue';
  import UserProfile from "./UserProfile.vue";
  import UserProfileForm from "./UserProfileForm.vue";
  import BlockButtonWrapper from "@/stories/block-button-wrapper/BlockButtonWrapper.vue"
  import { useCheckoutStore } from '@/stores/checkout.store'
  import { t } from '@/i18n-instance'
  const $checkout = useCheckoutStore()

  const props = defineProps({
    title: {
      type: String,
      required: true
    },
    profiles: {
      type: Array,
      required: true
    },
  })

  const handleOpenAddingNewProfile = () => {
    $checkout.isAddProfileOpen = true
    $checkout.currentProfileEditingOrSeekingID = null
  }

  const handleEditSave = () => {
    $checkout.currentBillingProfileID = $checkout.currentBillingProfileIDCache
    $checkout.step4EditMode = null
  }

  const handleEditCancel = () => {
    $checkout.currentBillingProfileIDCache = $checkout.currentBillingProfileID
    $checkout.step4EditMode = null
  }
</script>

<template>
  <div class="b-checkout-content">
    <div class="b-checkout-content__header">
      <div class="b-checkout-content__header-title">
        <h2 class="b-checkout-content__header-title-h2 h6">{{ props.title }} </h2>
      </div>
    </div>
    <div class="b-checkout-content__content">
      <UserProfile
        v-for="(profile, idx) of props.profiles"
        :key="`userProfileItem-${idx}`"
        :profile="profile"
      />
      <div class="b-checkout-content__add">
        <Button @click="handleOpenAddingNewProfile" class="b-checkout-content__add-button" variant="outline" size="big" color="blue" :label="$t('checkout.newBillingData')" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="#2C57DE" fill-rule="evenodd" d="M17.903 10c0 .46-.373.833-.834.833H2.927a.833.833 0 0 1 0-1.666H17.07c.46 0 .834.373.834.833Z" clip-rule="evenodd"/><path fill="#2C57DE" fill-rule="evenodd" d="M9.998 2.096c.46 0 .834.373.834.833v14.142a.833.833 0 0 1-1.667 0V2.93c0-.46.373-.833.833-.833Z" clip-rule="evenodd"/></svg>' />
        <div v-if="$checkout.isAddProfileOpen" class="b-checkout-content__form">
          <UserProfileForm @on-close="$checkout.isAddProfileOpen = false" :is-adding-new-profile="true" />
        </div>
      </div>
      <div v-if="['billProfile', 'domainOwner'].includes($checkout.step4EditMode)" class="b-checkout-content__footer mt-1">
        <BlockButtonWrapper desktopPosition="left" mobilePosition="center">
          <Button @click="handleEditSave" :label="t('save_data')" variant="primary" color="blue" size="big" />
          <Button @click="handleEditCancel" :label="t('dismiss')" variant="outline" color="blue" size="big" />
        </BlockButtonWrapper>
      </div>
    </div>
  </div>
</template>
