import config from '@/config';
import {wpWg} from '@/axios.js'
import { simpleClone } from '@/utils'
import {
  getSeparatedNumberSanitized,
  getPostCodeByCountryCode
} from '@/utils/user';

export async function login({ username, password, otp, sms }) {
  return await wpWg.post('/login', { username, password, otp, sms });
}

export const mojeId = {
  url: `${config.apiWp}/login-moje-id`,
  redirect_to: window.location.href
}

export async function logout() {
  try {
    await wpWg.post('/logout');
  } catch (e) {
    console.log(e)
  }
}

export async function lostPassword({ login }) {
  try {
     return await wpWg.post('/general', {
      endpoint: `/auth/lost?lang=${config.invoiceLanguage}`,
      method: 'POST',
      content: {
        login
      }
    });
  } catch (e) {
    return e.response
  }
}

export async function lostLogin({ domain }) {
  try {
    return await wpWg.post('/general', {
      endpoint: `/auth/lost-login?lang=${config.invoiceLanguage}`,
      method: 'POST',
      content: {
        domain
      }
    });
  } catch (e) {
    return e.response
  }
}

export async function register(data) {
  return await wpWg.post('/register', data);
}

export async function getProfile() {
  return await wpWg.post('/general', {
    endpoint: '/profile',
    method: 'GET',
  });
}

export async function getBillProfiles() {
  try {
    const res = await wpWg.get('/bill-profiles');
    const billProfilesTransformed = simpleClone(res)

    billProfilesTransformed.data.data.main_profile.contact_phone = getSeparatedNumberSanitized(
      billProfilesTransformed.data.data.main_profile.contact_phone
    )
    billProfilesTransformed.data.data.main_profile.invoice_postcode = getPostCodeByCountryCode(
      billProfilesTransformed.data.data.main_profile.invoice_postcode,
      billProfilesTransformed.data.data.main_profile.invoice_state
    )

    billProfilesTransformed.data.data.bill_profiles = billProfilesTransformed.data.data.bill_profiles.map(profile => {
      return {
        ...profile,
        customer_phone: `${getSeparatedNumberSanitized(profile.customer_phone)}`,
        customer_postcode: getPostCodeByCountryCode(profile.customer_postcode, profile.customer_state),
        customer_post_postcode: getPostCodeByCountryCode(profile.customer_postcode, profile.customer_state),
      }
    })

    billProfilesTransformed.data.data.bill_profiles = billProfilesTransformed.data.data.bill_profiles
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

    return billProfilesTransformed
  } catch (e) {
    console.log(e)
  }
}

export async function createBillProfile(data) {
  return await wpWg.post('/general', {
    endpoint: '/user-bill-profiles',
    method: 'POST',
    content: data
  });
}

export async function updateBillProfile(id, data) {
  return await wpWg.post('/general', {
    endpoint: `/user-bill-profiles/${id}?cache=0`,
    method: 'PUT',
    content: {
      ...data
    }
  });
}

export async function removeBillProfile(id) {
  return await wpWg.post('/general', {
    endpoint: `/user-bill-profiles/${id}?cache=0`,
    method: 'DELETE',
  });
}

export async function getListOfRegistrants() {
  const res = await wpWg.post('/general', {
    endpoint: `/order/listRegistrants`,
    method: 'GET',
    withFormName: true,
    content: {}
  });

  return res?.data?.list?.map(registrant => {
    return {
      ...registrant,
      contact: {
        ...registrant.contact,
        phone: getSeparatedNumberSanitized(registrant.contact.phone)
      }
    }
  })
}

