<script setup>
// core imports
import { onMounted } from 'vue'
import {useGlobalStore} from '@/stores/global.store'
import {useAuthStore} from '@/stores/auth.store'
import iconUser from './svg/iconUser.vue'
import { useDataLayer } from '@/dataLayer.js';
const globalStore = useGlobalStore();
const authStore = useAuthStore();
const { userAccountLoginClick } = useDataLayer();

const toggleRightPanel = () => {
  const rigthPanel = globalStore.rightPanelComp ? null : 'WgAuth'
  globalStore.setRightPanelComp(rigthPanel)
  userAccountLoginClick()
}

onMounted(() => {
  window.webGlobe.wgEvent('openAuthPanel', () => {
    toggleRightPanel()
  })
})

</script>

<template>
  <a class="b-main-menu-actions__auth" href="javascript:;" @click="toggleRightPanel">
    <iconUser v-show="!globalStore.rightPanelComp" class="icon-user" />
    <iconUser v-show="globalStore.rightPanelComp" class="icon-close" />
    <div class="nav-title">{{ authStore.isLogged ? $t('auth.myAccount.title') : $t('auth.top.title') }}</div>
  </a>
</template>

<style scoped></style>
