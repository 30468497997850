<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useCheckoutStore } from '@/stores/checkout.store';
import { closeAllModals, openModal } from '@/utils';
import WarningModal from '@/components/shared/WarningModal.vue';
import WgDomainFinder from '@/WgDomainFinder.vue';
import { useDataLayer } from '@/dataLayer.js';

const $ = useCheckoutStore();
const { precheckout } = useDataLayer();

const availableLinks = ref([])

const onLeaveModalId = 'leaveCheckoutBeforeAddToCart'

const onLeaveModalContent = $.checkout0.modal_add_to_basket

const onLeaveModalLink = ref('')

const handleUserLeave = event => {
  event.preventDefault();
  let target = event.target;

  while (target && target.tagName !== 'A') {
    target = target.parentElement;
  }

  if (target && target.tagName === 'A') {
    onLeaveModalLink.value = target.href;
    openModal(onLeaveModalId);
  } else {
    console.log('No anchor tag found');
  }
}

const handleBeforeUnload = event => {
  event.preventDefault()
  return event.returnValue = ''
}

const onLeaveModalSecondaryAction = () => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
  window.location.href = onLeaveModalLink.value
}

const onLeaveModalPrimaryAction = () => {
  closeAllModals()
}

onMounted(() => {
  precheckout()
  window.webGlobe?.tooltip?.init()
  availableLinks.value = [...document.querySelectorAll('a:not([href^="#"]):not([href^="javascript"]):not([data-bs-toggle="collapse"])')]
  availableLinks.value.forEach(link => {
    link.addEventListener('click', handleUserLeave)
  })
  window.addEventListener('beforeunload', handleBeforeUnload);
})

onUnmounted(() => {
  availableLinks.value.forEach(link => {
    link.removeEventListener('click', handleUserLeave)
  })
  window.removeEventListener('beforeunload', handleBeforeUnload);
})
</script>

<template>
  <main class="l-page-main">
    <section class="section section-main has-light-beige-background-color pt-60 pb-60">
			<div class="container">
        <div class="b-checkout0">
          <h1 class="b-checkout0__title">
            <span class="d-lg-none" v-html="$.checkout0.general?.title_mobile"></span>
            <span class="d-none d-lg-block" v-html="$.checkout0.general?.title_desktop"></span>
          </h1>
          <p class="b-checkout0__subtitle paragraph-L">
             <span class="d-lg-none" v-html="$.checkout0.general?.subtitle_mobile"></span>
            <span class="d-none d-lg-block" v-html="$.checkout0.general?.subtitle_desktop"></span>
          </p>
          <WgDomainFinder componentId="wg-cdas" :opts="$.checkout0.das" />
        </div>
      </div>
		</section>
  </main>
  <Teleport to="body">
    <WarningModal
      :id="onLeaveModalId"
      :title="onLeaveModalContent.title"
      :description="onLeaveModalContent.text"
      severity="warning"
      :primary-button="onLeaveModalContent.button_text_add"
      :secondary-button="onLeaveModalContent.button_text_cancel"
      @on-secondary-action="onLeaveModalSecondaryAction"
      @on-primary-action="onLeaveModalPrimaryAction"
    />
  </Teleport>
</template>

