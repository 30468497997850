<script setup>
</script>

<template>
<button
  class="b-cart-panel-item__remove-icon"
>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="20" 
    height="20" 
    fill="none" 
    viewBox="0 0 20 20"
  >
    <path 
      fill="#2C57DE" 
      fill-rule="evenodd" 
      d="M1.668 5c0-.46.373-.833.833-.833h15a.833.833 0 0 1 0 1.666h-15A.833.833 0 0 1 1.668 5Z" 
      clip-rule="evenodd"
    />
    <path 
      fill="#2C57DE" 
      fill-rule="evenodd" 
      d="M4.165 4.167c.46 0 .834.373.834.833v11.667c0 .145.08.36.276.556.196.196.411.277.557.277h8.333c.146 0 .361-.081.557-.277.196-.195.277-.41.277-.556V5a.833.833 0 1 1 1.666 0v11.667c0 .687-.335 1.305-.764 1.735-.43.43-1.048.765-1.736.765H5.832c-.688 0-1.306-.336-1.735-.765-.43-.43-.765-1.048-.765-1.735V5c0-.46.373-.833.833-.833Z" 
      clip-rule="evenodd"
    />
    <path 
      fill="#2C57DE" 
      fill-rule="evenodd" 
      d="M7.775 2.777c-.195.195-.276.41-.276.556V5a.833.833 0 0 1-1.667 0V3.333c0-.687.335-1.305.765-1.735.43-.43 1.047-.765 1.735-.765h3.333c.688 0 1.306.336 1.736.765.429.43.764 1.048.764 1.735V5A.833.833 0 0 1 12.5 5V3.333c0-.145-.082-.36-.277-.556-.196-.196-.411-.277-.557-.277H8.332c-.146 0-.361.081-.557.277ZM8.333 8.333c.46 0 .834.373.834.834v5a.833.833 0 1 1-1.667 0v-5c0-.46.373-.834.833-.834ZM11.665 8.333c.46 0 .834.373.834.834v5a.833.833 0 1 1-1.667 0v-5c0-.46.373-.834.833-.834Z" 
      clip-rule="evenodd"
    />
  </svg>
</button>
</template>