import { defineStore } from "pinia";
import {getPhonePrefixes as getPhonePrefixesApi, getConfigObj, getTldList} from "@/services"
import {FetchData} from '@/utils/fetchData'
import config from '@/config';

export const useGlobalStore = defineStore("global", {
  state: () => ({
    configObj: {},
    lastUpdateConfigObj: null,

    tldList: null,
    lastUpdateLtdList: null,

    countriesObj: [],
    lastUpdateCountriesObj: null,

    rightPanelComp: null,

    ip: '127.0.0.1',
    authToken: '',
    callsCounter: 270,
  }),
  getters: {
    getConfig: (state) => {
      return (slug) => state.configObj[slug] || undefined;
    },
    getPhonePrefixesOptions: (state) => {
      const languageKey = `localName${config.language.toUpperCase()}`;
      return state.countriesObj.map(country => {
        return {
          ...country,
          localName: country[languageKey] || country.localName
        }
      }).sort((a, b) => {
        if (a.isoCountryCode === config.language.toUpperCase()) {
          return -1
        } else if (b.isoCountryCode === config.language.toUpperCase()) {
          return 1
        }
        return 0
      })
    },
    getConfigBySlug: (state) => {
      return (slug) => state.configObj[slug] || undefined;
    },
    getFullConfig: (state) => {
      return (id) => {
        return state.configObj.find(conf => conf.id == id)
      }
    },
    ready: (state) => {
      return Object.keys(state.configObj).length > 0 && state.tldList !== null
    },
    getDomainPriceForPeriod: (state) => {
      return (domainTld, period) => {
        try {
          return state.tldList[domainTld].supported_months_prices[period];
        } catch (error) {
          return null;
        }
      }
    },
    isCz: (state) => {
      return state.configObj.general.form_name === 'webglobe_cz'
    }
  },
  actions: {
    async getConfigObj() {
      const ONE_HOUR = 60 * 60 * 1000; // in miliseconds
      const now = Date.now();
      this.tldList = window?.tldList || null
      this.configObj['tldList'] = window?.tldList || null
      this.configObj['das_default'] = window?.dasDefault || null

      if (!this.lastUpdateConfigObj || !this.configObj || now - this.lastUpdateConfigObj > ONE_HOUR) {
        await new FetchData()
          .onSuccess(({ data }) => (this.configObj = data))
          .call(getConfigObj)
      } else {
        return new Promise((resolve) => {
          resolve(this.configObj)
        })
      }
    },
    async getPhonePrefixes() {
      const ONE_HOUR = 60 * 60 * 1000; // in miliseconds
      const now = Date.now();

      if (!this.lastUpdateCountriesObj || !this.countriesObj || now - this.lastUpdateCountriesObj > ONE_HOUR) {
        await new FetchData()
          .onSuccess(({ data }) => (this.countriesObj = data))
          .call(getPhonePrefixesApi)
      } else {
        return new Promise((resolve) => {
          resolve(this.countriesObj)
        })
      }
    },
    async getTldList(tldParams) {
      const ONE_HOUR = 60 * 60 * 1000; // in miliseconds
      const now = Date.now();
      if (!this.lastUpdateLtdList || !this.tldList || now - this.lastUpdateLtdList > ONE_HOUR) {
        try {
          const tldList = window?.tldList
          if (tldList) {
            this.tldList = tldList;
            this.lastUpdateLtdList = now;
            return
          }
          const {data} = await getTldList();
          this.tldList = data.data
          this.lastUpdateLtdList = now;
        } catch (error) {
          console.log(error);
        }
      } else {
        return new Promise((resolve) => {
          resolve(this.tldList)
        })
      }
    },

    setRightPanelComp(comp) {
      if(comp) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }

      this.rightPanelComp = comp;
    }
  },
  /*persist: {
    debug: true,
    paths: ['configObj'],
  },*/
});

const preventDefaultListener = (e) => e.preventDefault()
