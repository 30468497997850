export const specificationCustomExtend = {
  init() {
    const packageGroups = document.querySelectorAll("#packages")

    packageGroups.forEach(packageGroup => {
      const collapses = packageGroup.querySelectorAll(".b-collapse-wrapper")
      let hasAnyOpened = false
      let openCount = 0

      collapses.forEach(collapseWrapper => {
        collapseWrapper.addEventListener('show.bs.collapse', () => {
          if (!hasAnyOpened) {
            collapseWrapper.style.transition = `height .7s ease-in-out`
            hasAnyOpened = true
          } else {
            collapseWrapper.style.transition = `height .7s ease-in-out .35s`
          }
          openCount++
        })

        collapseWrapper.addEventListener('hide.bs.collapse', () => {
          collapseWrapper.style.transition = 'height .35s ease-in-out'
          openCount--

          if (openCount === 0) {
            hasAnyOpened = false
          }
        })
      })
    })
  }
}


