<script setup>
import debounce from 'lodash.debounce'
import { ref, toRefs, watch } from 'vue'
import NxtInput from './NxtInput.vue'
import { onClickOutside } from '@vueuse/core'
import { useAuthStore } from '@/stores/auth.store'
import config from '@/config'

const props = defineProps(['modelValue', 'v', 'typeObj', 'source', 'background', 'isDisabled', 'isRequired', 'isContainered', 'isRightAligned', 'isFull'])
const emit = defineEmits(['update:modelValue', 'onFillData', 'onSourceChange'])

const authStore = useAuthStore()

const mainInput = ref(null)
const wasChanged = ref(false)
const results = ref([])
const resultsCache = ref([])

const updateModelValue = (newValue) => {
  emit('update:modelValue', newValue)
  wasChanged.value = true
}

const handleInput = debounce((_event) => {
  foxentryCompanySearch(source.value)
}, 300)

const { source } = toRefs(props)

watch(source, newSource => {
  if(!['cz', 'sk'].includes(newSource)) {
    results.value = []
    resultsCache.value = null
  }
})

const changeSource = async (newSource) => {
  resultsCache.value = null
  emit('onSourceChange', newSource)
  await foxentryCompanySearch(newSource)
}

const foxentryCompanySearch = async (source) => {
  if(['cz', 'sk'].includes(source)) {
    try {
      const newResults = await authStore.foxentryCompany(props.modelValue, props.typeObj, source)
      results.value = newResults
    } catch (error) {
      results.value = []
    }
    return
  }
  results.value = []
  resultsCache.value = null
}

const fillData = (data) => {
  emit('onFillData', data)
  results.value = []
}

const handleFocus = () => {
  if(!results.value?.length && resultsCache.value?.length) {
    results.value = resultsCache.value
  }
}

const countryHandler = ref(null)

onClickOutside(mainInput, (event) => {
  if (event.target === countryHandler.value) {
    return
  }
  resultsCache.value = results.value
  results.value = []
})
</script>

<template>
  <NxtInput
    ref="mainInput"
    v-bind="$attrs"
    :v="v"
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    @input="handleInput"
    @onFocus="handleFocus"
    :background="props.background"
    :is-disabled="isDisabled"
    :is-required="isRequired"
    class="b-autocomplete-input"
    :class="{
      'relative z-4': results?.length,
      'b-autocomplete-input--container': props.isContainered,
      'b-autocomplete-input--right-aligned': props.isRightAligned,
      'b-autocomplete-input--full': props.isFull,
    }"
  >
    <template #autocomplete>
      <div v-if="results?.length" class="fx-autocomplete">
        <div class="result-content">
          <div ref="countryHandler" @click.stop="changeSource(source === 'cz' ? 'sk' : 'cz')" class="item item--country">
            <div class="b-form__group">
              <div class="b-form-checkbox">
                <input :checked="config.language !== source.toLowerCase()" type="checkbox" id="checkbox">
                <label class="b-form-checkbox__label" for="checkbox" >
                  <span>
                    <span class="item--country__flag fi"
                    :class="`fi fi-${config.language === 'cz' ? 'sk' : 'cz'}`"
                    ></span> {{ config.language === 'cz' ? 'Slovenské' : 'České' }} firmy
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="item item--line"></div>

          <div
            v-for="{data} in results"
            :key="data.name"
            @click="fillData(data)"
            class="item"
            :class="{'country-sk': data.country === 'SK', 'country-cz': data.country === 'CZ'}"
          >
            {{data.name}}
            <span>{{$t('auth.register.form.invoice_companyid')}}: {{data.registrationNumber}}</span>
          </div>
        </div>
      </div>
    </template>
  </NxtInput>

</template>
