import { createI18n } from 'vue-i18n';
import messages from './i18n';
import config from './config.js';

export const i18n = createI18n({
  locale: config.language,
  globalInjection: true,
  messages,
});

export const t = i18n.global.t
