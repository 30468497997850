<script setup>
import { computed } from 'vue'
import Button from '@/stories/Button.vue'
import { useCheckoutStore } from '@/stores/checkout.store'
const $checkout = useCheckoutStore()

const props = defineProps({
  payment: {
    type: Object,
    required: true
  }
})

const paymentLogos = computed(() => {
  return [props.payment?.icon1, props.payment?.icon2]
    .filter(Boolean)
    .map(logo => window.webGlobe.global.getSvgDataForImageSrc(logo))
})

const handleEditPayment = () => {
  $checkout.step4EditMode = 'payment'
}
</script>

<template>
  <div class="b-checkout-content__summary-order">
    <div class="b-checkout-content__summary-order-title">
      <div>{{ $t('checkout.step4.paymentMethod') }}</div>
      <Button @click="handleEditPayment" class="b-checkout-content__summary-order-edit" variant="link" iconLeft='<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.55 2.598a1.557 1.557 0 0 0-1.747.44.837.837 0 0 1-.047.051L3.663 14.183l-.808 2.963 2.962-.808L16.911 5.244c.019-.019.039-.037.06-.054a1.528 1.528 0 0 0 .554-1.099 1.512 1.512 0 0 0-.444-1.146 1.543 1.543 0 0 0-.531-.347Zm-.698-1.77a3.223 3.223 0 0 1 2.408.938 3.19 3.19 0 0 1 .93 2.407 3.178 3.178 0 0 1-1.127 2.276L6.839 17.673a.833.833 0 0 1-.37.215l-4.583 1.25a.833.833 0 0 1-1.023-1.024l1.25-4.583a.834.834 0 0 1 .215-.37L13.555 1.934A3.208 3.208 0 0 1 15.852.828Z" fill="#2C57DE"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.91 3.578a.833.833 0 0 1 1.18 0l3.333 3.333a.833.833 0 0 1-1.179 1.178l-3.333-3.333a.833.833 0 0 1 0-1.178Z" fill="#2C57DE"/></svg>' />
    </div>
    <div class="b-checkout-content__summary-order-payment">
      <div class="b-checkout-content__item-payment-logos">
        <div v-for="logo of paymentLogos" class="b-checkout-content__item-payment-logo">
          <img :src="logo" :alt="payment.title">
        </div>
        <div class="b-checkout-content__item-payment-title">
          {{ props.payment.title }}
        </div>
      </div>
    </div>
  </div>
</template>
