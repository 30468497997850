import {api, instance, wpWg} from '@/axios.js'
import config from '@/config';

export async function getConfigObj() {
  return await wpWg.get('/get-config')
}
// POVODNE API VOLANIE - teraz vyzivame WP get-tld
/* export async function getTldList(params = {}) {
  const defaultParams = {
    form_name: config.formName,
    currency: config.currency,
    with_price: 1,
    apply_discounts: true,
    all_periods_prices: 1
  }
  params = {
    ...defaultParams,
    ...params
  }

  return await wpWg.post('/general', {
    endpoint: '/order/listTld',
    method: 'GET',
    content: params
  });
} */

export async function getTldList() {
  return await wpWg.get('/get-tld');
}

export async function checkAvailability(domain) {
  return await instance.get(`https://das.domena.cz/${domain}`);
}

export async function getTransfer(params) {
  const defaultParams = {
    form_name: config.formName,
    transfer: 1,
    cache: 1,
  }
  params = {
    ...defaultParams,
    ...params
  }
  return await api.get(
    `/order/checkDomainName`,
    {params}
  );
}

export async function getPhonePrefixes() {
  return await wpWg.get('/countries');
}

export async function getPremiumPrice(domain_name, toplevel) {
  return await wpWg.post('/general', {
    endpoint: '/order/priceDomain',
    method: 'GET',
    content: {
      domain_name,
      toplevel,
      lang: config.language,
      form_name: config.formName,
      apply_discounts: true,
      currency: config.currency,
    }
  });
}
