<script setup>
// core imports
import { ref, computed, onMounted, onUnmounted} from "vue";
import { useAuthStore } from "@/stores/auth.store";
import { useVuelidate } from '@vuelidate/core'
import {
  required,
  email,
  numeric,
  startsWithCZ,
  startsWithSK,
  alphaNum,
  city,
  maxLength,
  minLength,
  companyName,
  firstName,
  lastName,
  getCustomerPostcodeValidation
} from '@/utils/i18n-validators'
import NxtInput from '@/components/shared/NxtInput.vue'
import NxtInputPassword from '@/components/shared/NxtInputPassword.vue'
import NxtInputPhone from '@/components/shared/NxtInputPhone.vue'
import NxtInputCountries from '@/components/shared/NxtInputCountries.vue'
import NxtInputCompanyAutocomplete from '@/components/shared/NxtInputCompanyAutocomplete.vue'
import iconBack from './svg/iconBack.vue'
import { closeAllModals, openModal, simpleClone } from "@/utils";
import config from '@/config'
import { getCountryPrefix } from '@/utils/choices'
import { getLegalFormFromProfile } from '@/utils/checkout'
import Button from '@/stories/Button.vue'
import Checkbox from '@/stories/form/Checkbox.vue'
import { watchDebounced } from "@vueuse/core"
import { getCssVariable, setCssVariable } from "@/utils/dom"

const props = defineProps({
  isRightPanel: Boolean | undefined,
  variant: String | undefined,
});

const authStore = useAuthStore();

const step = ref(1)

const isUsingIcDph = ref(authStore.form.register.invoice_state === 'SK')

const rules = computed(() => ({
  invoice_name: authStore.form.register.isCompany ? {
    required,
    companyName,
    minLength: minLength(3),
    maxLength: maxLength(128),
  } : {},
  invoice_companyid: authStore.form.register.isCompany ? (() => {
    if(['SK', 'CZ'].includes(authStore.form.register.invoice_state)) {
      return {
        required,
        numeric,
      }
    }

    return {
      required,
    }
  })() : {},
  invoice_tax: authStore.form.register.isCompany ? (() => {
    if(isUsingIcDph.value) {
      return {
        required,
        numeric
      }
    }
    if(authStore.form.register.invoice_state === 'CZ') {
      return {
        required,
        startsWithCZ
      }
    }
    return {
      required
    }
  })() : {},
  invoice_vat: authStore.form.register.isCompany ? (() => {
    if(
      authStore.form.register.invoice_vat.trim() &&
      isUsingIcDph.value
    ) {
      return {
        startsWithSK
      }
    }
    return {}
  })() : {},
  name: {
    required,
    firstName,
    minLength: minLength(2),
    maxLength: maxLength(128),
  },
  surname: {
    required,
    lastName,
    minLength: minLength(2),
    maxLength: maxLength(128),
  },
  contact_phone: {
    required,
   },
  invoice_street: {
    required,
    alphaNum,
    minLength: minLength(2),
    maxLength: maxLength(128),
  },
  invoice_town: {
    required,
    city,
    minLength: minLength(2),
    maxLength: maxLength(128),
  },
  invoice_postcode: getCustomerPostcodeValidation(authStore.form.register.invoice_state),
  invoice_state: {
    required
  },
  contact_email: {
    required,
    email
  },
  password: {
    required,
    minLength: (value) => (value && value.length >= 8),
    hasLowerCase: (value) => /[a-z]/.test(value),
    hasUpperCase: (value) => /[A-Z]/.test(value),
    hasNumber: (value) => /[0-9]/.test(value)
  },
  termsAcceptance: {
    required,
    checked: v => v === true
  },
  marketingAcceptance: {
    required,
    checked: v => v === true
  }
}))

const v$ = useVuelidate(rules, authStore.form.register)

const isCheckout = computed(() => props.variant === 'checkout')

function openLogin($event) {
  $event.preventDefault()
  if (isCheckout.value) {
    closeAllModals()
    return openModal('checkoutLoginModal')
  }
  return authStore.setActiveComponent('login')

}

const isPhoneValidating = ref(false)
const isPhoneValid = ref(null)

const submit = async () => {
  const result = await v$.value.$validate()

  if (!result || isPhoneValid.value !== null && !isPhoneValid.value || isPhoneValidating.value || !authStore.isFormValid('register')) return
  authStore.form.register.contact_name = `${simpleClone(authStore.form.register.name)} ${simpleClone(authStore.form.register.surname)}`
  if (result) {
    try {
      const username = simpleClone(authStore.form.register.contact_email)
      const password = simpleClone(authStore.form.register.password)

      await authStore.register(false)
      await authStore.login({
        username,
        password,
        otp: undefined,
        sms: undefined,
        isCheckout: isCheckout.value
      })
    } catch (error) {
      console.error(error)
    }
  }
}

const foxentrySource = computed(() => authStore?.form?.register?.invoice_state.toLowerCase() || config.language)

const onFillDataHandler = (data) => {
  isUsingIcDph.value = data.country === 'SK'

  const contactPhone = authStore.form.register.contact_phone
  const isContactPhoneDirty = contactPhone.split(' ').filter(Boolean)

  authStore.$patch({
    form: {
      register: {
        ...authStore.form.register,
        invoice_name: data.name,
        invoice_companyid: data.registrationNumber,
        invoice_tax: isUsingIcDph.value ? data.taxNumber : data.vatNumber,
        invoice_vat: isUsingIcDph.value ? data.vatNumber ?? '' : '',
        invoice_street: data.addressOfficial.data.streetWithNumber,
        invoice_town: data.addressOfficial.data.city,
        invoice_postcode: data.addressOfficial.data.zip,
        invoice_state: data.country,
        contact_phone: !isContactPhoneDirty ? getCountryPrefix(data.country.toUpperCase()) : contactPhone
      }
    }
  })
  
}

const onSourceChange = (newSource) => {
  isUsingIcDph.value = newSource.toUpperCase() === 'SK'
  authStore.form.register.invoice_state = newSource.toUpperCase()
}

watchDebounced(
  authStore.form.register,
  register => {
    const legalForm = getLegalFormFromProfile({
      companyName: register?.invoice_name,
      ico: register?.invoice_companyid
    })

    register.legal_form = legalForm
  },
  { debounce: 500, maxWait: 1000 }
)

const changeCountries = (selected) => {
  isUsingIcDph.value = selected === 'SK'
  return authStore.form.register.invoice_state = selected
}

const registerWrapper = ref(null)

const setRegisterWrapperWidthCssVariable = () => {
  const element = registerWrapper.value;

  if (!element || element.offsetWidth === 0) {
    return;
  }

  setCssVariable({
    element,
    variable: '--regster-wrapper-width',
    value: element.getBoundingClientRect().width
  })
}

const observeModalVisibility = () => {
  const observer = new MutationObserver(() => {
    if (registerWrapper.value && registerWrapper.value.offsetParent !== null) {
      setRegisterWrapperWidthCssVariable();
      observer.disconnect()
    }
  });
  observer.observe(registerWrapper.value, {
    attributes: true,
    childList: true,
    subtree: true,
  });
};

onMounted(() => {
  setRegisterWrapperWidthCssVariable()
  window.addEventListener('resize', setRegisterWrapperWidthCssVariable)
  observeModalVisibility()
  // TODO: DATALAYER - user_registration_process stale sa spusta
  /* window.webGlobe.dataLayer.sendData({
    event: 'user_registration_process',
  }) */
})

onUnmounted(() => {
  window.removeEventListener('resize', setRegisterWrapperWidthCssVariable)
})
</script>

<template>
  <div>
    <div
      v-if="step === 1"
      ref="registerWrapper"
      class="form-login form-register"
    >
      <a v-if="!isCheckout" href="#" @click.prevent="authStore.setActiveComponent('login')" class="panel-right__close"><iconBack /></a>
      <div v-if="authStore.config.registration_title && !isCheckout" class="panel-right__title">
        <h4 v-if="authStore.config.registration_title" class="panel-right__title-title">{{ authStore.config.registration_title }}</h4>
        <div v-if="authStore.config.registration_desc" class="panel-right__title-desc">{{ authStore.config.registration_desc }}</div>
      </div>

      <div v-if="authStore.errorRegisterMsg" class="b-alert b-alert--danger mb-20">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#F03A49"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289Z" fill="#F03A49"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z" fill="#F03A49"/>
        </svg>
        <div class="b-alert__inner">
          {{ authStore.errorRegisterMsg  }}
        </div>
      </div>
      <form @submit.prevent="submit" class="panel-right__form" :class="{ 'md': isCheckout }">
        <Checkbox
          v-model="authStore.form.register.isCompany"
          @on-change="checked => authStore.form.register.isCompany = checked"
          :label="$t('auth.register.isCompany')"
          inputId="registerWrapIsCompany"
          class="panel-right__is-company-checkbox"
        />
        <template v-if="authStore.form.register.isCompany">
          <div class="row" :class="[ isCheckout ? 'form-register__row--col-6 row-gap-10 mb-10' : 'panel-right--form-register__row--col-6 row-gap-15' ]">
            <div :class="{ 'col-md-12': isCheckout }">
              <NxtInputCompanyAutocomplete
                v-model="authStore.form.register.invoice_name"
                typeObj="name"
                :source="foxentrySource"
                @onFillData="onFillDataHandler"
                @onSourceChange="onSourceChange"
                :label="$t('auth.register.form.invoice_name')"
                name="invoice_name"
                :v="v$.invoice_name"
                :is-required="authStore.form.register.isCompany"
                :is-full="true"

              />
            </div>
            <div class="col-6 ps-20 pe-5 ps-md-20 pe-md-5">
              <NxtInputCompanyAutocomplete
                v-model="authStore.form.register.invoice_companyid"
                typeObj="registrationNumber"
                :source="foxentrySource"
                @onFillData="onFillDataHandler"
                @onSourceChange="onSourceChange"
                :label="$t('auth.register.form.invoice_companyid')"
                name="invoice_companyid"
                :v="v$.invoice_companyid"
                :is-required="authStore.form.register.isCompany"
              />
            </div>
            <div v-if="isUsingIcDph" class="col-6 ps-5 pe-20 pe-md-20 ps-md-5">
              <NxtInput
                v-model="authStore.form.register.invoice_tax"
                :label="$t('auth.register.form.invoice_tax')"
                name="invoice_tax"
                :v="v$.invoice_tax"
                :is-required="true"
              />
            </div>
            <div :class="isUsingIcDph ? 'col-12' : 'col-6 ps-5 pe-20 pe-md-20 ps-md-5'">
              <NxtInputCompanyAutocomplete
                v-if="isUsingIcDph"
                v-model="authStore.form.register.invoice_vat"
                typeObj="vatNumber"
                :source="foxentrySource"
                @onFillData="onFillDataHandler"
                @onSourceChange="onSourceChange"
                :label="$t('auth.register.form.invoice_vat')"
                name="invoice_companyid"
                :v="v$.invoice_vat"
                :is-right-aligned="!isUsingIcDph"
              />
              <NxtInputCompanyAutocomplete
                v-else
                v-model="authStore.form.register.invoice_tax"
                typeObj="vatNumber"
                :source="foxentrySource"
                @onFillData="onFillDataHandler"
                @onSourceChange="onSourceChange"
                :label="$t('auth.register.form.invoice_tax')"
                name="invoice_companyid"
                :v="v$.invoice_tax"
                :is-right-aligned="true"
                :is-required="true"
              />
            </div>
          </div>
        </template>

        <div
          class="row"
          :class="[
            isCheckout ? 'form-register__row--col-6 row-gap-10 mt-md-n10' : 'panel-right--form-register__row--col-6 row-gap-15',
          ]"
        >
          <div :class="[ isCheckout ? 'col-md-6 pe-md-5' : 'col-6 ps-20 pe-5' ]">
            <NxtInput
              v-model="authStore.form.register.name"
              :label="$t('auth.register.form.first_name')"
              name="name"
              :isRequired="true"
              :v="v$.name"
              :autofocus="true"
              :foxentry="'name'"

              :is-value-capitalized="true"
            />
          </div>
          <div :class="[ isCheckout ? 'col-md-6 ps-md-5' : 'col-6 pe-20 ps-5' ]">
            <NxtInput
              v-model="authStore.form.register.surname"
              :label="$t('auth.register.form.last_name')"
              name="surname"
              :isRequired="true"
              :v="v$.surname"
              :foxentry="'surname'"

              :is-value-capitalized="true"
            />
          </div>
          <div :class="{ 'col-md-6 pe-md-5': isCheckout }">
            <NxtInputPhone
              :model-value="authStore.form.register.contact_phone"
              :label="$t('auth.register.form.contact_phone')"
              @update="fullNumberWithPrefix => authStore.form.register.contact_phone = fullNumberWithPrefix"
              @on-validate-handler="isValid => isPhoneValid = isValid"
              @on-validating="isValidating => isPhoneValidating = isValidating"
              :v="v$.contact_phone"
              :is-required="true"
            />
          </div>
          <div :class="{ 'col-md-6 ps-md-5': isCheckout }">
            <NxtInput
              v-model="authStore.form.register.invoice_street"
              :label="$t('auth.register.form.invoice_street')"
              name="invoice_street"
              :isRequired="true"
              :v="v$.invoice_street"
              :is-value-capitalized="true"
            />
          </div>
          <div :class="[ isCheckout ? 'col-6 pe-5 ps-20 ps-md-20 pe-md-5' : 'col-7 pe-0' ]">
            <NxtInput
              v-model="authStore.form.register.invoice_town"
              :label="$t('auth.register.form.invoice_town')"
              name="invoice_town"
              :isRequired="true"
              :v="v$.invoice_town"
              :is-value-capitalized="true"
            />
          </div>
          <div :class="[ isCheckout ? 'col-6 ps-5 pe-20 pe-md-20 ps-md-5' : 'col-5 ps-10' ]">
            <NxtInput
              v-model="authStore.form.register.invoice_postcode"
              :label="$t('auth.register.form.invoice_postcode')"
              name="invoice_postcode"
              :isRequired="true"
              :v="v$.invoice_postcode"
            />
          </div>
          <div class="col-md-12">
            <NxtInputCountries
              name="contact_state"
              :model-value="authStore.form.register.invoice_state"
              @update:model-value="changeCountries"
            />
          </div>
        </div>

        <h6
          class="panel-right__login-title text-center"
          :class="isCheckout ? 'my-20 mt-md-25 mb-md-5' : 'mt-25 mb-5'"
        >
          {{ $t('auth.register.setLoginCredentials') }}
        </h6>

        <div class="panel-right__email-password-wrapper">
          <NxtInput v-model="authStore.form.register.contact_email" :label="$t('auth.register.form.contact_email')" name="contact_email" :isRequired="true" :v="v$.contact_email" :foxentry="'email'" />
          <NxtInputPassword v-model="authStore.form.register.password" :label="$t('auth.register.form.password')" name="password" :isRequired="true" :v="v$.password" :hideErrors="true" :showIndicators="true" :variant="props.variant" />
        </div>

        <div class="mt-20 row gap-10">
          <Checkbox
            v-model="termsAcceptance"
            @on-change="checked => authStore.form.register.termsAcceptance = checked"
            :label="$t('auth.register.form.terms')"
            inputId="acceptConditions"
            :v="v$.termsAcceptance"
            :is-required="true"
          />
          <Checkbox
            v-model="marketingAcceptance"
            @on-change="checked => authStore.form.register.marketingAcceptance = checked"
            :label="$t('auth.register.form.marketingTerms')"
            inputId="marketingConsent"
            :v="v$.marketingAcceptance"
            :is-required="true"
          />
        </div>

        <Button
          btnType="button"
          :label="$t('auth.register.form.submitBtn')"
          type="submit"
          variant="primary"
          size="big"
          :class="isCheckout ? 'mt-20 mt-md-25' : 'mt-25'"
          :disabled="authStore.pending"
          :loading="authStore.pending"
        />
      </form>

      <div class="panel-right__register-text mt-20">{{ $t('auth.register.loginQuestion') }} <a href="#" @click.prevent="openLogin">{{ $t('auth.register.loginBtn') }}</a></div>
    </div>
  </div>
</template>
