class CopyClipboard {
  constructor() {
  }

  init = () => {
    this.copyToClipboard();
  }

  copyToClipboard = () => {
    const clipboardInnerPending = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"><g><path fill="#00D081" fill-rule="evenodd" d="M10 19.167A9.167 9.167 0 1 0 10 .834a9.167 9.167 0 0 0 0 18.333ZM13.863 9.16a.927.927 0 0 0 0-1.375 1.095 1.095 0 0 0-1.473 0l-3.43 3.201L7.612 9.73a1.095 1.095 0 0 0-1.473 0 .927.927 0 0 0 0 1.375l2.083 1.945c.407.38 1.067.38 1.474 0l4.166-3.89Z" clip-rule="evenodd"/></g><defs></defs></svg><span style="pointer-events: none"></span>`;
    const clipboardLinks = document.querySelectorAll('.js-copy-to-clipboard');
    for (const clipboardLink of clipboardLinks) {
      clipboardLink.addEventListener('click', event => {
        const target = event.target;
        event.stopPropagation();
        navigator.clipboard.writeText(location.href);
        const replaceText = clipboardLink.getAttribute('data-replace-text') || 'Copied';
        const currentHtml = clipboardLink.innerHTML;

       target.innerHTML = clipboardInnerPending.replace('</span>', `${replaceText}</span>`);
        const pendingTimeout = setTimeout(() => {
         target.innerHTML = currentHtml;
          return clearTimeout(pendingTimeout)
        }, 3000)
      });
    }
  }
}

export const copyClipboard = new CopyClipboard();
