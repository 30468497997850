<script setup>
import { ref, shallowRef, watch } from 'vue'
import { storeToRefs } from 'pinia'
import SearchBar from '@/components/domain-list/SearchBar.vue'
import List from '@/components/domain-list/List.vue'
import ListSkeleton from '@/components/domain-list/ListSkeleton.vue'
import WgDomainFinder from './WgDomainFinder.vue'
import { useGlobalStore } from './stores/global.store'
import { simpleClone } from './utils'
import { getCssVariable } from './utils/dom'

const container = ref(null)

const $global = useGlobalStore()
const currentSearchValue = shallowRef('')
const currentActiveTld = ref(null)
const isReady = ref(false)

const { ready } = storeToRefs($global);
watch(ready, (val) => {
  isReady.value = val
}, { immediate: true });

watch(currentSearchValue, currentSearchValue => {
  handleSetActiveTld(null)
})

const handleScrollBackToResults = () => {
  const demandedOffset = getCssVariable({ 
    element: document.documentElement, 
    variable: '--header-height-for-top-offset' 
  })
  const containerRect = container.value.getBoundingClientRect()
  const containerOffsetFromTopOfThePage = containerRect.top + window.scrollY
  const demandedScrollPosition = containerOffsetFromTopOfThePage - demandedOffset;

  window.scrollTo({
    top: demandedScrollPosition,
    behavior: 'smooth'
  })
}

watch(currentActiveTld, _currentActiveTld => {
  if(currentActiveTld.value !== null && window.innerWidth <= 768) {
    handleScrollBackToResults()
  }
}, { immediate: false })

function handleSetActiveTld(tldItem) {
  currentActiveTld.value = tldItem
}
</script>

<template>
  <section class="b-domain-list-section">
    <div class="b-domain-list-section__search-bar-container">
      <SearchBar
        :model-value="currentSearchValue"
        @on-search="value => currentSearchValue = value"
      />
    </div>
    <div ref="container" class="container pt-20 pb-80 pt-md-40">
      <div class="b-domain-list-section__list-container">
        <List
          v-if="isReady"
          :tld-list="simpleClone($global.tldList)"
          :current-active-tld="currentActiveTld"
          :current-searched-tld="currentSearchValue"
          @on-set-active-tld="handleSetActiveTld"
          @on-unset-active-tld="handleSetActiveTld(null)"
        />
        <ListSkeleton v-else />
      </div>
      <div v-if="currentActiveTld" class="b-domain-list-section__das-container">
        <WgDomainFinder
          :opts="{
            preferred_domain: currentActiveTld.tl_name
          }"
        />
      </div>
    </div>
  </section>
</template>
