<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2144 2.27734H5.68848V10.16C6.58238 9.50985 7.51692 8.98163 8.49209 8.49405V5.12159H11.2144C13.4086 5.12159 15.1964 6.9094 15.1964 9.10353C15.1964 11.2977 13.4086 13.0855 11.2144 13.0855H8.49209V10.6475C8.49209 10.5663 8.41082 10.5663 8.41082 10.6069C7.39502 11.3383 6.46049 12.1916 5.68848 13.1667V15.9297H11.2144C14.9526 15.9297 18 12.8823 18 9.14416C18 5.40601 14.9526 2.31798 11.2144 2.27734Z" fill="#818284"/>
<path d="M8.49209 8.49405L5.68848 10.16V2.27734L8.49209 5.12159V8.49405Z" fill="url(#paint0_linear_6570_29310)"/>
<path d="M2.96614 6.46094H0.284424V15.8469H2.96614V6.46094Z" fill="url(#paint1_linear_6570_29310)"/>
<path d="M14.0182 7.23396C14.0182 7.15269 13.9775 7.07143 13.9369 7.0308C13.8556 6.94953 13.7337 6.9089 13.6118 6.94953C7.96399 7.55901 2.96624 10.8908 0.243896 15.848H2.92561C5.76986 10.8096 9.99559 8.53418 13.7744 7.47775C13.9775 7.43712 14.0182 7.31522 14.0182 7.23396Z" fill="url(#paint2_linear_6570_29310)"/>
<path d="M1.62528 2.11328C2.51919 2.11328 3.25056 2.84466 3.25056 3.73856C3.25056 4.63247 2.51919 5.36385 1.62528 5.36385C0.731377 5.36385 0 4.63247 0 3.73856C0 2.84466 0.731377 2.11328 1.62528 2.11328Z" fill="#FFCB00"/>
<defs>
<linearGradient id="paint0_linear_6570_29310" x1="7.80147" y1="9.1598" x2="4.79445" y2="2.71101" gradientUnits="userSpaceOnUse">
<stop offset="0.006" stop-color="#BFBFBD"/>
<stop offset="0.046" stop-color="#C7C7C5"/>
<stop offset="0.276" stop-color="#EFEFEF"/>
<stop offset="0.393" stop-color="white"/>
</linearGradient>
<linearGradient id="paint1_linear_6570_29310" x1="4.48054" y1="14.5869" x2="-1.25517" y2="7.75165" gradientUnits="userSpaceOnUse">
<stop stop-color="#DF8200"/>
<stop offset="0.392" stop-color="#F0A800"/>
<stop offset="0.713" stop-color="#FFCB00"/>
<stop offset="1" stop-color="#FFCB00"/>
</linearGradient>
<linearGradient id="paint2_linear_6570_29310" x1="0.2634" y1="11.4045" x2="14.0216" y2="11.4045" gradientUnits="userSpaceOnUse">
<stop stop-color="#FCC200"/>
<stop offset="1" stop-color="#FFCB00"/>
</linearGradient>
</defs>
</svg>

</template>