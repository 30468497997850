import {wpWg} from '@/axios.js'

export async function foxentryEmailValidation(query, options) {
  return await wpWg.post('/foxentry', {
    endpoint: '/email/validate',
    method: 'POST',
    content: {
      request: {
        query,
        options
      }
    }
  });
}

export async function foxentryNameValidation(query, options) {
  return await wpWg.post('/foxentry', {
    endpoint: '/name/validate',
    method: 'POST',
    content: {
      request: {
        query,
        options
      }
    }
  });
}

export async function foxentryPhoneValidation(query, options) {
  return await wpWg.post('/foxentry', {
    endpoint: '/phone/validate',
    method: 'POST',
    content: {
      request: {
        query,
        options
      }
    }
  });
}

export async function foxentryCompany(query, options) {
  return await wpWg.post('/foxentry', {
    endpoint: '/company/search',
    method: 'POST',
    content: {
      request: {
        query,
        options
      }
    }
  });
}


