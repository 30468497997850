<script setup>
import { inject } from 'vue'
import { useDasStore } from '@/stores/das.store.js'

const emit = defineEmits(['onSearchTld'])

const handleSearchTld = async () => {
  await dasStore.searchTld(componentId)
  emit('onSearchTld')
}

const componentId = inject('componentId')
const dasStore = useDasStore()

const handleGoBack = () => {
  if(dasStore.isDomainTransferRequired) {
    dasStore.isDomainTransferRequired = false
    return
  }
  dasStore.switchTransfer(componentId)
}

</script>

<template>

  <button class="b-button b-button--primary b-button--orange b-button--big b-button--if-label" @click="handleSearchTld" v-if="!dasStore.getDas(componentId).transfer && !dasStore.getResults(componentId).length">
    <div class="b-button__icon b-button__icon--left">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"><path fill="#fff" fill-rule="evenodd" d="M9.166 3.333a5.833 5.833 0 1 0 0 11.667 5.833 5.833 0 0 0 0-11.667Zm-7.5 5.833a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0Z" clip-rule="evenodd"/><path fill="#fff" fill-rule="evenodd" d="M13.328 13.328a.833.833 0 0 1 1.179 0l3.583 3.583a.833.833 0 0 1-1.179 1.178l-3.583-3.583a.833.833 0 0 1 0-1.178Z" clip-rule="evenodd"/></svg>
    </div>
    <span class="b-button__label d-none d-md-block">{{ $t('search_btn') }}</span>
  </button>

  <button class="b-button b-button--primary b-button--orange b-button--big" v-if="dasStore.getResults(componentId).length && !dasStore.getDas(componentId).transfer" @click="dasStore.closeDas(componentId);">
    <div class="b-button__icon b-button__icon--left">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28"><path fill="#fff" fill-rule="evenodd" d="M21.826 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#fff" fill-rule="evenodd" d="M6.176 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 0 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>
    </div>
  </button>

  <button class="b-button b-button--primary b-button--orange b-button--big" v-if="dasStore.getDas(componentId).transfer" @click="handleGoBack">
    <div class="b-button__icon b-button__icon--left">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28"><path fill="#fff" fill-rule="evenodd" d="M14.824 5.008a1.167 1.167 0 0 1 0 1.65L7.483 14l7.341 7.341a1.167 1.167 0 0 1-1.65 1.65l-8.166-8.166a1.167 1.167 0 0 1 0-1.65l8.166-8.167a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#fff" fill-rule="evenodd" d="M4.666 14c0-.644.522-1.166 1.167-1.166h16.333a1.167 1.167 0 1 1 0 2.333H5.833A1.167 1.167 0 0 1 4.666 14Z" clip-rule="evenodd"/></svg>
    </div>
  </button>

</template>
