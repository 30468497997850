<script setup>
// core imports
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth.store";
import { useGlobalStore } from "@/stores/global.store";
import iconBack from './svg/iconBack.vue'
import NxtInput from '@/components/shared/NxtInput.vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredLoginUsername } from '@/utils/i18n-validators'
import MyButton from '@/stories/Button.vue'
import { closeAllModals } from '@/utils'

const props = defineProps({
  isRightPanel: {
    type: Boolean,
    default: true
  },
  isCheckout: Boolean | undefined,
});

const emit = defineEmits(['onBackToLogin'])

const authStore = useAuthStore();
const globalStore = useGlobalStore();
const step = ref(1);
const res = ref(null);
const form = ref({
  login: '',
});

const rules = {
  login: { 
    required: requiredLoginUsername
  },
}

const v$ = useVuelidate(rules, form.value);

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) {
    return
  }

  const [result] = await authStore.lostPassword({ login: form.value.login })
  res.value = result

  if (res?.value?.success) {
    step.value = 2
  }
  
}

const openLoginOrClose = (event) => {
  event.preventDefault()
  if (props.isCheckout) {
    closeAllModals()
  } else {
    authStore.setActiveComponent('login')
  }
  step.value = 1
}

</script>

<template>
  <div class="form-login">
    <a v-if="isRightPanel" href="#" @click.prevent="authStore.setActiveComponent('login')" class="panel-right__close"><iconBack /></a>

    <div v-if="step === 1">
      <div class="panel-right__title">
        <h4 class="panel-right__title-title">{{ globalStore.getConfigBySlug('auth')?.lost_title }}</h4>
        <div class="panel-right__title-desc">{{ globalStore.getConfigBySlug('auth')?.lost_desc }}</div>
      </div>

      <div v-if="authStore.errorMsg" class="b-alert b-alert--danger b-alert--center mb-20">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#F03A49"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289Z" fill="#F03A49"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z" fill="#F03A49"/>
        </svg>
        <div class="b-alert__inner">
          {{ authStore.errorMsg }}
        </div>
      </div>

      <form @submit.prevent="submit" class="panel-right__form">
        <NxtInput v-model="form.login" :label="$t('auth.lost_password.form.login')" name="login" :isRequired="true" :v="v$.login" :autofocus="true" />

        <MyButton btnType="button" :label="$t('auth.lost_password.submitBtn')" type="submit" variant="primary" size="big" class="btn-md btn-block mt-10 forgot-btn" :disabled="authStore.pending" :loading="authStore.pending" />
      </form>
      <div v-if="!isCheckout" class="panel-right__register-text mt-20">{{ $t('auth.login.registerQuestion') }} <a href="#" @click.prevent="authStore.setActiveComponent('register')">{{ $t('auth.login.registerBtn') }}</a></div>
      <div v-else class="panel-right__register-text mt-20">{{ $t('auth.register.loginQuestion') }} <a href="#" @click.prevent="emit('onBackToLogin')">{{ $t('auth.register.loginBtn') }}</a></div>
    </div>

    <div v-if="step === 2">
      <div class="panel-right__title">
        <h4 class="panel-right__title-title">{{ globalStore.getConfigBySlug('auth')?.lost_title }}</h4>
        <div class="panel-right__title-desc">{{ $t('auth.lost_password.emailSent') }}</div>
      </div>
      <div class="row gy-10">
        <template v-if="res?.emails?.length">
          <NxtInput class="col-12" v-for="email of res.emails" :value="email" :disabled="true" :label="''" />
        </template>
      </div>
      <div class="panel-right__form">
        <MyButton @click="openLoginOrClose" btnType="button" :label="$t('close')" type="button" variant="primary" size="big" class="btn-md btn-block mt-10 forgot-btn" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.b-button--primary.b-button--primary.forgot-btn {
  margin-top: 1.25rem !important
}
</style>
