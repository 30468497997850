export const promobar = {
  init() {
    const promobar = document.querySelector('.b-promobar');
    const promobarSticky = document.querySelector('.js-b-promobar--sticky');
    if (!promobar) return;


    const mainMenu = document.querySelector(".b-main-menu");
    const aside    = document.querySelector(".l-page-aside-sticky");

    // hide promobar
    const closeButton = document.querySelector(".js-b-promobar__close");
    closeButton.addEventListener('click', function() {
      const now = new Date();
      const item = {
        value: 'true',
        expiry: now.getTime() + 1000 * 60 * 60 * 24 * 14, // 2 weeks from now
      };
      localStorage.setItem('promobarClosed', JSON.stringify(item));
      promobar.classList.add('d-none');

      if (promobarSticky) elementTopPosition();
    });

    const itemStr = localStorage.getItem('promobarClosed');
    if (itemStr) {
      const item = JSON.parse(itemStr);
      const now = new Date();

      if (now.getTime() > item.expiry) {
        localStorage.removeItem('promobarClosed');
      } else {
        promobar.classList.add('d-none');
      }
    }

    if (promobarSticky) elementTopPosition();

    // sticky
    if (!promobarSticky) return;
    function elementTopPosition() {
      var promobarHeight = promobar.offsetHeight;
      var mainMenuHeight = mainMenu.offsetHeight;

      mainMenu.style.top = promobarHeight + 'px';
      if(aside){
        aside.style.top = promobarHeight + mainMenuHeight + 20 + 'px';
      }
    }
    // init
    elementTopPosition();
    // init after resize
    window.addEventListener('resize', setWindowSize);
    function setWindowSize() {
      elementTopPosition();
    }
  },
}
