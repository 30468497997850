export const getNumberWithoutPrefix = (fullNumber) => {
  return fullNumber.slice(-9)
}

export const getNumberPrefix = (fullNumber) => {
  const numberWithoutPrefix = getNumberWithoutPrefix(fullNumber)
  return fullNumber.replace(numberWithoutPrefix, '')
}

export const getSeparatedNumberSanitized = (number) => {
  if(!number) {
    return number
  }
  const numberSanitized = number.replace('.', '').replace(' ', '')
  return `${getNumberPrefix(numberSanitized)} ${getNumberWithoutPrefix(numberSanitized)}`
}

export const getPostCodeByCountryCode = (postCode, countryCode) => {
  if(!postCode) {
    return postCode
  }

  const postCodeSanitized = postCode.replace('-', '').replace(' ', '')
  
  if(['PL'].includes(countryCode)) {
    const postCodeBeforeDash = postCodeSanitized.slice(0, 2)
    const postCodeAfterDash = postCodeSanitized.slice(2)
    return `${postCodeBeforeDash}-${postCodeAfterDash}`
  }

  return postCode
}

export const getInvoiceLanguageFromSource = (languageSource) => {
  if(!languageSource) {
    return languageSource
  }
  if(['CZ', 'cz'].includes(languageSource)) {
    return 'cs'
  }

  return languageSource.toLowerCase()
}