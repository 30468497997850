<script setup></script>

<template>
  <div class="transfer-loading">
    <button class="b-button b-button--primary b-button--white b-button--small b-button--if-label b-button--loading">
      <span class="b-button__label"></span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.transfer-loading {
  min-height: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
