<template>
  <component
    :is="element"
    :class="classes"
    :style="style"
    :href="href"
    v-bind="collapseAttributes">
    <div
      v-if="iconLeft"
      class="b-button__icon b-button__icon--left"
      :class="{ 'b-button__icon--rotation': rotationIcon }"
      v-html="iconLeft"
    ></div>
    <span v-if="label" class="b-button__label" v-html="label"></span>
    <div
      v-if="iconRight"
      class="b-button__icon b-button__icon--right"
      :class="{ 'b-button__icon--rotation': rotationIcon }"
      v-html="iconRight"
    ></div>
  </component>
</template>

<script>
import { reactive, computed } from "vue";

export default {
  name: "my-button",

  props: {
    href: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    collapseExpanded: {
      type: Boolean,
      default: false,
    },
    class: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      validator: function (value) {
        return (
          ["primary", "secondary", "outline", "link"].indexOf(value) !== -1
        );
      },
    },
    color: {
      type: String,
      validator: function (value) {
        return (
          [
            "blue",
            "dark-blue",
            "black",
            "orange",
            "azure",
            "yellow",
            "white",
            "green",
          ].indexOf(value) !== -1
        );
      },
    },
    size: {
      type: String,
      validator: function (value) {
        return (
          ["small", "medium", "big", "big-large-text"].indexOf(value) !== -1
        );
      },
    },
    mobileSize: {
      type: String,
      validator: function (value) {
        return (
          [
            "default",
            "small",
            "medium",
            "big",
            "big-large-text",
          ].indexOf(value) !== -1
        );
      },
    },
    iconLeft: {
      type: String,
    },
    iconRight: {
      type: String,
    },
    rotationIcon: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        "b-button": true,
        [`b-button--${props.variant || "primary"}`]: true,
        [`b-button--${props.color || "blue"}`]: true,
        [`b-button--mobile-${props.mobileSize}`]: props.mobileSize !== "default",
        [`b-button--${props.size || "medium"}`]: true,
        [`b-button--loading`]: props.loading,
        [`b-button--if-label`]: props.label,
        [props.class]: true,
      })),
      element: computed(() => (props.collapse ? "button" : (props.href ? "a" : "button"))),
      collapseAttributes: computed(() => {
      let attributes = {};
      if(props.collapse){
        attributes.type = "button";
        attributes['data-bs-toggle'] = 'collapse';
        attributes['data-bs-target'] = props.href;
        attributes['aria-expanded'] = props.collapseExpanded;
        attributes['aria-controls'] = props.href.slice(1);
      }
      return attributes;
    }),
    };
  },
};
</script>
