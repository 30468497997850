import * as components from './components'

const autoruns = [
  components.global,
  components.modals,
  components.teleportToBody,
  components.cookies,
  components.dataLayer,
  components.megaMenu,
  components.vueBridge,
  components.faq,
  components.countBox,
  components.swiper,
  components.tooltip,
  components.scrollSpy,
  components.copyClipboard,
  components.promobar,
  components.promobarCountdown,
  components.tabsAutoplay,
  components.newsletter,
  components.videoHandler,
  components.choices,
  components.contactForms,
  components.collapseBouncer,
  components.collapseScroller,
  components.headerHeight,
  components.specificationCustomExtend,
  components.randomBanners
];

function domLoaded() {
  document.addEventListener("DOMContentLoaded", function(event) {
    initAutoruns();
  });
}

function initAutoruns(components = null) {
  const comps = components || autoruns;
  Object.values(comps).forEach(fullObj => {
    const initFunc = fullObj?.init;
    if (typeof initFunc === 'function') {
      initFunc();
    }
  });
}

export const autorun = {
  initAutoruns,
  domLoaded
}
