<template>
  <div
    class="b-form__group"
    :class="{
      'b-form__group--error': error,
      'b-form__group--disabled': disabled,
      'b-form__group--switch-right': switchRight,
    }
  ">
    <div class="b-form-switch">
      <input type="checkbox" :checked="checked" :id="computedId" @change="onChange" />
      <label class="b-form-switch__label" :for="computedId">
        <span v-if="label">
          {{ label }}
        </span>
      </label>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'

const props = defineProps({
  label: {
    type: String,
    default: 'Label'
  },
  switchRight: {
    type: Boolean,
    default: false
  },
  checked: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['onChange'])

const onChange = (e) => {
  emit('onChange', e.target.checked)
}

const computedId = computed(() => {
  return `switch-${uuidv4()}`;
})
</script>
