import {CountUp} from "countup.js";

const defaultAnimationFn = (t, b, c, d) => c*((t=t/d-1)*t*t*t*t*t*t + 1) + b;

const options = {
  startVal: 0,
  decimalPlaces: 0,
  duration: 4,
  useEasing: true,
  easingFn: defaultAnimationFn,
  enableScrollSpy: true,
  scrollSpyOnce: true,
  formattingFn: (num) => num.toString().replace('.', ',') // Format number with comma as decimal separator
};

function startCounting(element) {
  if(element.classList.contains('js-is-visible')) return;
  const normalizeNumber = (numStr) => parseFloat(numStr.replace(',', '.'));
  const textContent = normalizeNumber(element.textContent);
  const endValue = normalizeNumber(element.dataset.endval) || textContent;
  const startVal = normalizeNumber(element.dataset.startval) || options.startVal;
  const duration = parseInt(element.dataset.duration, 10) || options.duration;
  const animation = element.dataset.animation || options.easingFn;
  const decimalPlaces = (endValue.toString().split('.')[1] || []).length;

  const opts = {
    ...options,
    startVal,
    duration,
    easingFn: animation,
    decimalPlaces
  };

  const countUp = new CountUp(element, endValue, opts);
  countUp.start();
  element.classList.add('js-is-visible');
}

const intersectionCallback = entries => {
  entries.forEach( entry => {
    const el = entry.target
    if ( entry.isIntersecting && !el.classList.contains('js-is-visible') ) {
      startCounting(el);
    }
  })
}

export const countBox = {
  init() {
    const spanElements = document.querySelectorAll(".js-b-count-box__counter");
    if (!spanElements.length) return;

    spanElements.forEach((element) => {
      const IO = new IntersectionObserver(intersectionCallback, {threshold: .5})
      IO.observe( element )
    })
  },
  CountUp,
  startCounting
}
