export const isElementFullyVisible = ({ element, boundaries = { top: 0, bottom: 0 } }) => {
    const rect = element.getBoundingClientRect()
    const viewportHeight = window.innerHeight
    const viewportWidth = window.innerWidth

    const isFullyVisible = rect.top >= boundaries.top
      && rect.left >= 0 
      && rect.bottom <= viewportHeight - boundaries.bottom
      && rect.right <= viewportWidth;

    const isHiddenFromTop = rect.top < boundaries.top

    const isHiddenFromBottom = rect.bottom > viewportHeight - boundaries.bottom

    return {
      isFullyVisible,
      isHiddenFromTop,
      isHiddenFromBottom
    };
}

export const setElementsScrollPositionFromTop = ({ element, topOffset }) => {
  return new Promise((resolve) => {
    const targetScrollTop = element.getBoundingClientRect().top + window.scrollY - topOffset;

    window.scrollTo({
      top: targetScrollTop,
      behavior: "smooth"
    });

    const checkIfScrollEnded = () => {
      const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

      if (Math.abs(currentScrollTop - targetScrollTop) < 20) {
        resolve();
      } else {
        requestAnimationFrame(checkIfScrollEnded);
      }
    };

    requestAnimationFrame(checkIfScrollEnded);
  });
};


export const setElementsScrollPositionFromBottom = ({ element, bottomOffset }) => {
  const viewportHeight = window.innerHeight
  const elementBottom = element.getBoundingClientRect().bottom
  const distanceFromBottom = viewportHeight - elementBottom;

  window.scrollTo({
    top: window.scrollY + (bottomOffset - distanceFromBottom),
    behavior: "smooth"
  })
}

export const setElementVisibleWithOffset = ({ element, topOffset, bottomOffset }) => {
  const {
      isFullyVisible,
      isHiddenFromTop, 
      isHiddenFromBottom
  } = isElementFullyVisible({ element, boundaries: { top: topOffset, bottom: bottomOffset }})

  if(isFullyVisible) {
    return
  }

  if(isHiddenFromTop) {
    setElementsScrollPositionFromTop({ element, topOffset })
    return
  }

  if (isHiddenFromBottom) {
    setElementsScrollPositionFromBottom({ element, bottomOffset })
    return
  }
}

export const getCssVariable = ({ element, variable }) => {
  if(!element) {
    throw Error(`Element does not exist`)
  }

  const desiredVariable = getComputedStyle(element)
    .getPropertyValue(variable)

  if(!desiredVariable) {
    throw Error(`Couldn't find any ${desiredVariable} at ${element}`)
  }

  return desiredVariable
}

export const setCssVariable = ({ element, variable, value }) => {
  if(!element) {
    throw Error(`Missing variable element`)
  }
  
  if(!variable) {
    throw Error(`Missing variable parameter`)
  }

  if(value !== 0 && !value) {
    throw Error(`Missing value parameter`)
  }

  element.style.setProperty(variable, value)
}