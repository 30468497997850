<script setup>
import { ref, onMounted } from 'vue';
import Button from "@/stories/Button.vue";
import BlockCollapseWrapper from "@/stories/block-collapse-wrapper/BlockCollapseWrapper.vue";
import Switch from "@/stories/form/Switch.vue";
import { simpleClone } from '@/utils';
import { wgCall } from '@/assets/js/components';

const storagePermittedCookiesKey = 'permittedCookies'

const storagePermittedCookies = localStorage.getItem(storagePermittedCookiesKey)

const isSettingsVisible = ref(false)
const isCookiebarVisible = ref(true)

onMounted(() => {
  isCookiebarVisible.value = !Boolean(storagePermittedCookies)
  window.webGlobe.wgEvent('openCookieSettings', () => {
    isSettingsVisible.value = true
  })
})

const defaultPermittedCookies = storagePermittedCookies
  ? JSON.parse(storagePermittedCookies)
  : {
    default: {
      disabled: true,
      permitted: true
    },
    analytics_storage: {
      disabled: false,
      permitted: false
    },
    ad_storage: {
      disabled: false,
      permitted: false
    },
    ad_user_data: {
      disabled: false,
      permitted: false
    },
    ad_personalization: {
      disabled: false,
      permitted: false
    },
  }

const permittedCookies = ref({
  ...simpleClone(defaultPermittedCookies)
})

const submitAllPermitted = () => {
  Object.keys(defaultPermittedCookies).forEach(cookie => {
    permittedCookies.value[cookie].permitted = true
  })

  localStorage.setItem(storagePermittedCookiesKey, JSON.stringify(permittedCookies.value))
  isCookiebarVisible.value = false
  isSettingsVisible.value = false
  window.webGlobe?.wgCall('manageCookies')
}

const submitPermissions = () => {
  localStorage.setItem(storagePermittedCookiesKey, JSON.stringify(permittedCookies.value))
  isCookiebarVisible.value = false
  isSettingsVisible.value = false
  window.webGlobe?.wgCall('manageCookies')
}

const refusePermissions = () => {
  Object.keys(defaultPermittedCookies).forEach(cookie => {
    if(!permittedCookies.value[cookie].disabled) {
      permittedCookies.value[cookie].permitted = false
    }
  })

  localStorage.setItem(storagePermittedCookiesKey, JSON.stringify(permittedCookies.value))
  isCookiebarVisible.value = false
  isSettingsVisible.value = false
  window.webGlobe?.wgCall('manageCookies')
}
</script>

<template>
  <div class="b-cookiebar">
    <div v-if="isCookiebarVisible" class="b-cookiebar__bar b-cookiebar__bar--active">
      <div class="b-cookiebar__inner">
        <div class="b-cookiebar__content">
          <h5 class="b-cookiebar__title">{{ $t('cookies.title') }}</h5>
          <div class="b-cookiebar__desc" v-html="$t('cookies.description')"></div>
        </div>
        <div class="b-cookiebar__actions">
          <Button @click="submitAllPermitted" :label="$t('cookies.accept')" variant="primary" size="medium" color="blue" />
          <Button @click="refusePermissions" :label="$t('cookies.reject')" variant="primary" size="medium" color="gray" class="px-20" />
          <Button @click="isSettingsVisible = true" :label="$t('cookies.settings')" variant="link" size="medium" color="gray" class="p-0" />
        </div>
      </div>
    </div>
    <div class="b-cookiebar__settings" :class="{ 'b-cookiebar__settings--active' : isSettingsVisible }">
      <div class="b-cookiebar__settings-inner">
        <div class="b-cookiebar__settings-content">
          <h4 class="b-cookiebar__settings-title">{{ $t('cookies.settingsTitle') }}</h4>
          <div class="b-cookiebar__settings-data">
            <div v-for="cookieKey of Object.keys(defaultPermittedCookies)" :key="cookieKey" class="b-cookiebar__settings-item">
              <div class="b-cookiebar__settings-item-title">
                <Button :label="$t(`cookies.${cookieKey}.title`)" variant="link" size="medium" color="black" collapse="false" class="p-0" collapseExpanded="false" :href="`#collapsible-cookies-${cookieKey}`" :rotationIcon="true" iconRight='<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20"><path fill="#2EF5E9" fill-rule="evenodd" d="M4.91 7.744a.833.833 0 0 1 1.18 0l4.41 4.41 4.41-4.41a.833.833 0 1 1 1.18 1.179l-5 5a.833.833 0 0 1-1.18 0l-5-5a.833.833 0 0 1 0-1.179Z" clip-rule="evenodd"/></svg>' />
                <Switch label="" @onChange="val => permittedCookies[cookieKey].permitted = val" :checked="permittedCookies[cookieKey].permitted" :error="false" :disabled="permittedCookies[cookieKey].disabled" />
              </div>
              <BlockCollapseWrapper :id="`collapsible-cookies-${cookieKey}`" :show="false" spaceBetween="sm">
                <div class="b-cookiebar__settings-item-desc">
                  <p>{{ $t(`cookies.${cookieKey}.description`) }}</p>
                </div>
              </BlockCollapseWrapper>
            </div>
          </div>
        </div>
        <div class="b-cookiebar__settings-actions">
          <Button
            :label="$t('cookies.allowAll')"
            variant="primary"
            size="medium"
            color="blue"
            @click="submitAllPermitted"
          />
          <Button
            :label="$t('cookies.allowSelection')"
            variant="secondary"
            size="medium"
            color="black"
            @click="submitPermissions"
          />
        </div>
      </div>
    </div>
    <div v-if="isCookiebarVisible && !isSettingsVisible" class="b-cookiebar__bar-bg"></div>
    <div class="b-cookiebar__settings-bg" :class="{ 'b-cookiebar__settings-bg--active' : isSettingsVisible }"></div>
  </div>
</template>
