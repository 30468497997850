<script setup>
import { ref, computed } from 'vue';
import MyButton from '@/stories/Button.vue';
import NxtInput from '@/components/shared/NxtInput.vue';
import { useCheckoutStore } from '@/stores/checkout.store';
import { t } from '@/i18n-instance'

const $checkout = useCheckoutStore();
const coupon = ref($checkout.data.coupon?.coupon_results?.coupon_codes)

const errorMsg = computed(() => {
  const couponMessageCode = $checkout.data.coupon?.coupon_results?.coupon_message_code
  if(typeof couponMessageCode === 'number' && couponMessageCode === 6) {
    return t(`checkout.couponMessage.${couponMessageCode}`)
  }
  return null
})

const isCouponSubmitted = computed(() => $checkout.data.coupon?.active )

const handleSetCoupon = async () => {
  if ($checkout.isCouponLoading) return
  try {
    await $checkout.setCoupon(coupon.value)
  } catch (error) {
    console.error(error)
  }
}
const handleCouponRemove = () => {
  $checkout.setCoupon('')
}

const iconPlusBtn = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18" fill="none">
  <path fill="#fff" fill-rule="evenodd" d="M16.114 9a.75.75 0 0 1-.75.75H2.636a.75.75 0 0 1 0-1.5h12.728a.75.75 0 0 1 .75.75Z" clip-rule="evenodd"/>
  <path fill="#fff" fill-rule="evenodd" d="M9 1.886a.75.75 0 0 1 .75.75v12.728a.75.75 0 0 1-1.5 0V2.636a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd"/>
</svg>`;

const iconRemoveBtn = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#8E98A7"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#8E98A7"/>
</svg>
`;

const isSkeletonShown = computed(() => {
  return $checkout.data.coupon?.active && $checkout.isCouponLoading
})

const discountAmountApplied = computed(() => {
  return $checkout.data?.coupon?.coupon_results?.discounts?.[0]?.percentage
})
</script>

<template>
  <div style="position: relative; isolation: isolate;">
    <div v-if="isSkeletonShown" class="couponRemovalSkeleton pulse"></div>
    <NxtInput
      v-model="coupon"
      name="input_coupon"
      :is-disabled="$checkout.isCouponLoading || isCouponSubmitted"
      :class="{ 
        'is-submit': isCouponSubmitted
      }"
      class="checkoutCouponInput"
      :placeholder="$t('checkout.summary.inputCoupon')"
      :errorMsg="errorMsg"
    >
      <template v-slot:iconRight>
        <span
          v-if="discountAmountApplied && isCouponSubmitted"
          class="checkoutCouponInput__discount-amount text-alert-red"
        >
          -{{discountAmountApplied}}%
        </span>
        <MyButton
          v-if="!isCouponSubmitted"
          @click="handleSetCoupon"
          size="medium"
          mobile-size="small"
          color="orange"
          :iconRight="iconPlusBtn"
          :loading="$checkout.isCouponLoading"
        />
        <MyButton
          v-else="isCouponSubmitted"
          @click="handleCouponRemove"
          size="medium"
          mobile-size="small"
          color="gray"
          :iconRight="iconRemoveBtn"
          variant="link"
        />
      </template>
    </NxtInput>
  </div>
</template>

<style lang="scss">
  .couponRemovalSkeleton {
    height: 24px; 
    position: absolute; 
    left: 0px; 
    top: 24px; 
    right: 40px; 
    z-index: 2;

    @media screen and (min-width: 768px) {
      top: 28px;
    }
  }
</style>

