import { t } from '@/i18n-instance'

export const promobarCountdown = {
  init() {
    const promobarCountdown = document.querySelector('.js-b-promobar__countdown');
    if (!promobarCountdown) return;
    const countDownDate = new Date(promobarCountdown.getAttribute('data-countdown')).getTime();
    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      for (let i = 0; i < document.getElementsByClassName("js-b-promobar__countdown").length; i++) {
        document.getElementsByClassName("js-b-promobar__countdown")[i].innerHTML = (
          `
          <div class="b-promobar__countdown-item">${days} <span class="b-promobar__countdown-item-desc">${
            (() => {
              if(days === 0) return t('date.day.moreOrEqualToFive')
              if(days === 1) return t('date.day.one')
              if(days >= 2 && days <= 4) return t('date.day.moreThenOneLessThanFive')
              else return t('date.day.moreOrEqualToFive')
            })()
          }</span></div> <span class="b-promobar__countdown-separator">:</span> 
          <div class="b-promobar__countdown-item">${hours} <span class="b-promobar__countdown-item-desc">${
            (() => {
              if(hours === 0) return t('date.hour.moreOrEqualToFive')
              if(hours === 1) return t('date.hour.one')
              if(hours >= 2 && hours <= 4) return t('date.hour.moreThenOneLessThanFive')
              else return t('date.hour.moreOrEqualToFive')
            })()
          }</span></div> <span class="b-promobar__countdown-separator">:</span> 
          <div class="b-promobar__countdown-item">${minutes} <span class="b-promobar__countdown-item-desc">${
            (() => {
              if(minutes === 0) return t('date.minute.moreOrEqualToFive')
              if(minutes === 1) return t('date.minute.one')
              if(minutes >= 2 && minutes <= 4) return t('date.minute.moreThenOneLessThanFive')
              else return t('date.minute.moreOrEqualToFive')
            })()
          }</span></div>
          `
        );
        if (distance < 0) {
          clearInterval(x);
          document.getElementsByClassName("js-b-promobar__countdown")[i].innerHTML = "EXPIRED";
        }
      }

    }, 1000);

  },
}
