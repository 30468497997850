<script setup>
// core imports
import { useCheckoutStore } from "@/stores/checkout.store";
import { useGlobalStore } from "@/stores/global.store"
const globalStore = useGlobalStore();
const $checkout = useCheckoutStore();
$checkout.getBasketData()

const toggleRightPanel = () => {
  if ($checkout.isReady && $checkout.step !== 0 && !$checkout.isEmptyCheckout) {
    return window.location.href = '/checkout'
  }
  const rigthPanel = globalStore.rightPanelComp ? null : 'WgCart'
  globalStore.setRightPanelComp(rigthPanel)
}
</script>

<template>
  <a class="b-main-menu-actions__basket" href="javascript:;" @click="toggleRightPanel">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_7574_15157)">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M5 17.5C5 16.5795 5.74619 15.8333 6.66667 15.8333C7.58714 15.8333 8.33333 16.5795 8.33333 17.5C8.33333 18.4205 7.58714 19.1667 6.66667 19.1667C5.74619 19.1667 5 18.4205 5 17.5Z"
          fill="#315EED" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M14.168 17.5C14.168 16.5795 14.9142 15.8333 15.8346 15.8333C16.7551 15.8333 17.5013 16.5795 17.5013 17.5C17.5013 18.4205 16.7551 19.1667 15.8346 19.1667C14.9142 19.1667 14.168 18.4205 14.168 17.5Z"
          fill="#315EED" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.875 1.70833C0.875 1.2481 1.2481 0.875 1.70833 0.875H3.375C3.76798 0.875 4.10756 1.14955 4.18985 1.53382L4.94113 5.04167H18.4083C18.6611 5.04167 18.9002 5.15637 19.0583 5.35351C19.2165 5.55066 19.2766 5.80892 19.2218 6.05566L17.8472 12.2458C17.8471 12.2461 17.8472 12.2456 17.8472 12.2458C17.7248 12.8012 17.4165 13.299 16.9735 13.6556C16.5303 14.0124 15.9786 14.2074 15.4097 14.2083L7.26669 14.2083C6.68888 14.2188 6.12515 14.0288 5.67151 13.6705C5.21521 13.31 4.89884 12.8017 4.77687 12.2331L3.4606 6.08724C3.45422 6.06294 3.44891 6.0382 3.44472 6.01309L2.70124 2.54167H1.70833C1.2481 2.54167 0.875 2.16857 0.875 1.70833ZM5.29808 6.70833L6.40646 11.8835C6.40644 11.8834 6.40648 11.8836 6.40646 11.8835C6.44716 12.073 6.55265 12.2425 6.70468 12.3626C6.85677 12.4828 7.04601 12.5462 7.2398 12.5419L7.25833 12.5417L15.407 12.5417C15.4068 12.5417 15.4071 12.5417 15.407 12.5417C15.5965 12.5413 15.7806 12.4763 15.9282 12.3574C16.076 12.2385 16.1787 12.0727 16.2195 11.8875L17.3696 6.70833H5.29808Z"
          fill="#315EED" />
      </g>
      <defs>
        <clipPath id="clip0_7574_15157">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <span v-if="$checkout.data.cart_count" class="b-main-menu-actions__basket-badge">{{ $checkout.data.cart_count }}</span>
    <div class="nav-title">{{ $t('cart') }}</div>
  </a>
</template>

<style scoped></style>
