<script setup>
// core imports
import { ref, computed, watch, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth.store";
import {mojeId} from "@/services"
import IconMojeId from './svg/mojeId.vue'
import IconInfo from './svg/info.vue'
import Holub from './svg/holub.vue'
import NxtInput from '@/components/shared/NxtInput.vue'
import NxtInputPassword from '@/components/shared/NxtInputPassword.vue'
import ChevronBack from './svg/ChevronBack.vue'
import ForgotPassword from './ForgotPassword.vue'

import { useVuelidate } from '@vuelidate/core'
import {requiredIf} from '@vuelidate/validators'
import {requiredLoginUsername, requiredLoginPassword, required} from '@/utils/i18n-validators'
import config from '@/config'
import MyButton from '@/stories/Button.vue'
import { useCheckoutStore } from "@/stores/checkout.store";
import {closeAllModals, openModal} from "@/utils"
import { useDataLayer } from '@/dataLayer.js';
const $checkout = useCheckoutStore()
const { userLoginProcess, userRegistrationProcess, userForgotPasswordProcess } = useDataLayer()

const props = defineProps({
  isRightPanel: Boolean | undefined,
  variant: String | undefined,
});

const authStore = useAuthStore();

const isTwoFA = computed(() => authStore.twoFA)

const form = ref({
  username: '',
  password: '',
  otp: '',
  sms: '',
});

const showForgotPassword = ref(false)

const dynamicRules = computed(() => {
  return {
    username: { required: requiredLoginUsername },
    password: { required: requiredLoginPassword },
    otp: isTwoFA.value === 'otp' ? { required } : {},
    sms: isTwoFA.value === 'sms' ? { required } : {},
  };
});

const v$ = useVuelidate(dynamicRules, form);

const resendSms = ref(false)
const lastResendSms = ref(null)

const isCheckout = computed(() => props.variant === 'checkout')

const openRegister = (event) => {
  event.preventDefault()
  if (isCheckout.value) {
    closeAllModals()
    if(window.innerWidth < 768) {
      userRegistrationProcess()
      return openModal('checkoutRegisterModal')
    }
    userRegistrationProcess()
    return $checkout.setStep(2)
  }
  userRegistrationProcess()
  return authStore.setActiveComponent('register')
}

const openForgotPassword = (event) => {
  event.preventDefault()
  if (isCheckout.value) {
    showForgotPassword.value = true
  }
  userForgotPasswordProcess()
  return authStore.setActiveComponent('forgot-password')
}

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) {
    return
  }
  resendSms.value = false
  await authStore.login({
    username: form.value.username,
    password: form.value.password,
    otp: form.value.otp || undefined,
    sms: form.value.sms || undefined,
    isCheckout: isCheckout.value || undefined
  })
}

const resendSmsAction = async () => {
  const currentTime = new Date().getTime();

  if (lastResendSms.value !== null && currentTime - lastResendSms.value < 15000) {
    return
  }
  lastResendSms.value = currentTime
  resendSms.value = true
  await authStore.login({
    username: form.value.username,
    password: form.value.password,
    otp: undefined,
    sms: undefined,
  })
}

watch(isTwoFA, _ => {
  v$.value.$reset();
});

onMounted(() => {
  if (!isCheckout.value) {
    userLoginProcess()
  }

  window.webGlobe?.tooltip?.init()

  const myModalEl = document.getElementById('checkoutLoginModal')
  if (myModalEl) {
    myModalEl.addEventListener('hidden.bs.modal', event => {
      showForgotPassword.value = false
    })
    myModalEl.addEventListener('show.bs.modal', event => {
      userLoginProcess()
    })
  }
})

</script>

<template>
  <div v-if="!showForgotPassword">
    <div v-if="!authStore.twoFA">
      <div class="panel-right__title">
        <h4 class="panel-right__title-title">{{ authStore.config.login_title || $t('auth.login.title') }}</h4>
        <div v-if="isCheckout" class="panel-right__title-desc">
          <span v-html="$t('checkout.auth.login.description')"></span>
          &nbsp;<a href="#" @click="openRegister">{{ $t('checkout.auth.login.registrationBtn') }}</a>.
        </div>
      </div>
      <div v-if="authStore.errorMsg" class="b-alert b-alert--danger b-alert--center mb-20">
        <svg class="b-alert__icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#F03A49"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289Z" fill="#F03A49"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z" fill="#F03A49"/>
        </svg>
        <div class="b-alert__inner">
          {{ authStore.errorMsg  }}
        </div>
      </div>
      <form @submit.prevent="submit" class="panel-right__form">
        <NxtInput v-model="form.username" :label="$t('auth.login.form.username')" name="username" :v="v$.username" :autofocus="true"  />
        <NxtInputPassword v-model="form.password" :label="$t('auth.login.form.password')" name="password" :v="v$.password" />
        <div class="panel-right__form--row row g-0 align-items-center">
          <div v-if="isCheckout" class="col-7 d-none d-md-flex">
            <form v-if="config.language === 'cz'" :action="mojeId.url" method="POST">
              <button type="submit" class="panel-right__link"><IconMojeId /><span>{{ $t('auth.login.mojeIdBtn') }}</span></button>
            </form>
          </div>
          <div class="col-7 login-save-pass">
            <div class="b-form__group">
              <div class="b-form-checkbox">
                <input type="checkbox" id="checkbox" />
                <label class="b-form-checkbox__label" for="checkbox">
                  <span>{{ $t('auth.login.rememberMe') }}</span>
                  <button
                    v-if="false"
                    type="button"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-title="Toto je tooltip s kratším a dlhším textom. Nájdeš tu vysvetlivky ako aj dobré rady. Tak nezabudni, že viac info nájdeš pod tooltipom."
                  >
                    <IconInfo />
                  </button>
                </label>
              </div>
            </div>
          </div>
          <div class="col-5 text-end login-lost-pass">
            <a href="#" class="panel-right__link" @click.prevent="openForgotPassword">{{ $t('auth.login.forgotPassword') }}</a>
          </div>
        </div>
        <MyButton btnType="button" :label="$t('auth.login.submitBtn')" type="submit" variant="primary" size="big" class="btn-md mt-25" :disabled="authStore.pending" :loading="authStore.pending" />
      </form>
      <form v-if="config.language === 'cz'" :action="mojeId.url" method="POST" :class="{ 'd-md-none': isCheckout }">
        <button type="submit" class="b-button b-button--secondary b-button--blue b-button--big b-button--if-label w-100 mt-10">
          <IconMojeId />
          <span class="b-button__label">{{ $t('auth.login.mojeIdBtn') }}</span>
        </button>
      </form>

      <div class="panel-right__register-text mt-20 login-bottom-panel"><span>{{ $t('auth.login.registerQuestion') }}</span>&nbsp;<a href="#" @click="openRegister">{{ $t('auth.login.registerBtn') }}</a></div>
    </div>
    <div v-else class="form-login">
      <a href="#" @click.prevent="authStore.twoFA = null" class="panel-right__close"><ChevronBack /></a>
      <div class="panel-right__title">
        <h4 class="panel-right__title-title">{{ authStore.config['2fa_title'] }}</h4>
        <div class="panel-right__title-desc">{{ authStore.config['2fa_desc'] }}</div>
      </div>
      <div v-if="authStore.errorMsg" class="b-alert b-alert--danger b-alert--center mb-20">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#F03A49"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289Z" fill="#F03A49"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z" fill="#F03A49"/>
        </svg>
        <div class="b-alert__inner">
          {{ authStore.errorMsg }}
        </div>
      </div>
      <form @submit.prevent="submit" class="panel-right__form">
        <div v-if="resendSms" class="check-email">
          <div class="icon">
            <Holub />
          </div>
          <div class="info">
            <div class="name">{{ $t('auth.login.smsResent') }}</div>
            <div class="text">{{ $t('auth.login.smsResentDesc') }}</div>
          </div>
        </div>

        <div v-if="isTwoFA === 'otp'">
          <NxtInput v-model="form.otp" :label="$t('auth.login.form.otp')" name="otp" :isRequired="true" :v="v$.otp" :autofocus="true"/>
        </div>
        <div v-if="isTwoFA === 'sms'">
          <NxtInput v-model="form.sms" :label="$t('auth.login.form.sms')" name="sms" :isRequired="true" :v="v$.sms" :autofocus="true" />
        </div>

        <MyButton btnType="button" :label="$t('auth.login.submitBtn')" type="submit" variant="primary" size="big" class="btn-md mt-10" :disabled="authStore.pending && !resendSms" :loading="authStore.pending && !resendSms" />

        <div v-if="isTwoFA === 'sms'" class="register-text">
          {{ $t('auth.login.smsResendQuestion') }} <a href="#" @click.prevent="resendSmsAction">{{ $t('auth.login.smsResendBtn') }}</a>
        </div>
      </form>
    </div>

    <div v-if="!authStore.twoFA" class="mt-40 text-center login-footer">
      <h6>{{ authStore.config.login_services_title }}</h6>
      <div class="panel-right__form-services mt-20">
        <a
          v-for="(service, index) in authStore.config.login_services"
          :href="service.link.url"
          :key="index"
          target="_blank"
          class="panel-right__form-services__item"
        >
          <img class="panel-right__form-services__item-img" :src="service.img.url" :alt="service.img.alt">
          {{ service.name }}
        </a>
      </div>
    </div>
  </div>
  <ForgotPassword v-else :isCheckout="isCheckout" :isRightPanel="false" @onBackToLogin="showForgotPassword = false" />
</template>

<style lang="scss" scoped>
.form-login {
  .tooltip-info {
    position: relative;
    cursor: pointer;
    &:hover {
      .tooltip {
        opacity: 1;
      }
    }
    .tooltip {
      width: 120px;
      position: absolute;
      bottom: calc(100% + 8px);
      left: 50%;
      transform: translate(-50%, 0);
      opacity: 0;
      pointer-events: none;
      @include anim(opacity);
      .tooltip-arrow {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}
</style>
