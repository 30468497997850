<script setup>
// core imports
import { provide, ref } from "vue";
// import components
import CartTop from "@/components/cart/CartTop.vue";
import AuthTopToggle from "@/components/auth/AuthTopToggle.vue";

const props = defineProps({
  configId: String | Number | undefined,
  componentId: String | Number | undefined,
  opts: String | undefined
})
const conf = ref(null)

// provide config data to all child components
provide('config', conf)
provide('componentId', props.componentId)
</script>

<template>
  <AuthTopToggle />
  <CartTop />
</template>

<style scoped></style>
