import axios from 'axios';
import config from '@/config';

const wpWg = axios.create({
  baseURL: config.apiWp,
  withCredentials: true,
});

const api = axios.create({
  baseURL: 'https://api.webglobe.com'
});

const instance = axios.create({});

export {wpWg, instance, api }
export default instance;
