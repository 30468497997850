import { t } from '@/i18n-instance';
import { useCheckoutStore } from '@/stores/checkout.store';
import config from '@/config'
import { getInvoiceLanguageFromSource } from '@/utils/user'

export function getPeriodLabel(period) {
  let label = t('periods.months');
  let periodInt = parseInt(period);

  if (periodInt === 1) {
    label = t('periods.month');
  } else if (periodInt >= 2 && periodInt <= 4) {
    label = t('periods.months24');
  } else if (periodInt >= 5 && periodInt <= 11) {
    label = t('periods.months');
  }

  if (periodInt >= 12) {
    periodInt = Math.floor(periodInt / 12);
    if (periodInt === 1) {
      label = t('periods.year');
    } else if (periodInt >= 2 && periodInt <= 4) {
      label = t('periods.years24');
    } else {
      label = t('periods.years');
    }
  }

  return `${periodInt} ${label}`;
}

export function getPriceLabel(price, opts) {
  let currency = 'Kč'
  if (opts?.currency) {
    currency = opts.currency;
  } else {
    const $checkout = useCheckoutStore();
    currency = $checkout.data.currency;
  }

  let float = 2;
  if (currency === 'Kč') {
    float = 0;
  }
  if(opts?.float) {
    float = opts.float;
  }

  const returnPrice = Number(price).toFixed(float);
  return `${returnPrice} ${currency}`;
}

export const getCustomRoundedNumber = (number) => {
  const wholePart = Math.floor(number)
  const decimalPart = number - wholePart
  
  return decimalPart > 0.5 ? wholePart + 1 : wholePart
}

export const getFormProfileFromProfile = (profile) => {
  const name = profile?.contact_name || profile?.customer_name || ''

  const [firstName, lastName] = name.split(' ')

  const customerIco = profile?.ico || profile?.customer_ico || profile?.invoice_companyid || ''

  const customerPhoneRaw = profile?.contact_phone || profile?.customer_phone || ''
  const customerPhoneFormated = customerPhoneRaw.replace('.', ' ')

  const companyName = profile.customer_company_name || profile.invoice_name || name

  return {
    customer_company_name: companyName,
    customer_first_name: profile.customer_first_name || firstName || '',
    customer_last_name: profile.customer_last_name || lastName || '',
    customer_street: profile?.invoice_street || profile?.customer_street || '',
    customer_town: profile?.invoice_town || profile?.customer_town || '',
    customer_postcode: profile?.invoice_postcode || profile?.customer_postcode || '',
    customer_state: profile?.invoice_state || profile?.customer_state || config.invoiceState.toUpperCase() || '',
    customer_province: profile?.customer_province || '',
    customer_ico: customerIco,
    customer_dic: profile?.dic || profile?.customer_dic || profile?.invoice_tax || '',
    customer_icdph: profile?.icdph || profile?.customer_icdph || profile?.invoice_vat || '',
    customer_email: profile?.contact_email || profile?.customer_email || '',
    customer_phone: customerPhoneFormated,
  }
}

export const getRegistrantFromProfile = (profile) => {
  const profileNormalized = getFormProfileFromProfile(profile)

  const legalForm = getLegalFormFromProfile({ 
    companyName: profileNormalized?.customer_company_name,
    ico: profileNormalized.customer_ico
  })
  
  return {
    contact: {
      first_name: profileNormalized.customer_first_name || profileNormalized.customer_company_name || '',
      last_name: profileNormalized.customer_last_name || profileNormalized.customer_company_name || '',
      language: getInvoiceLanguageFromSource(profileNormalized.customer_state),
      phone: profileNormalized.customer_phone,
      email: profileNormalized.customer_email
    },
    owner: {
      legal_form: legalForm,
      name: profileNormalized.customer_company_name || `${profileNormalized.customer_first_name} ${profileNormalized.customer_last_name}`,
      postcode: profileNormalized.customer_postcode,
      state: profileNormalized.customer_state,
      street: profileNormalized.customer_street,
      town: profileNormalized.customer_town,
      orgid: profileNormalized.customer_ico,
      taxid: profileNormalized.customer_dic,
      vatid: profileNormalized.customer_icdph
    }
  }
}

export const getLegalFormFromProfile = ({ companyName, ico }) => {
  const SROFlags = ['s.r.o.', 's. r. o.']
  const ASFlags = ['a.s.', 'a. s.', 'akciová spoločnosť', 'akciová společnost']

  if(!ico) {
    return 'FO'
  }

  if(
    ico &&
    ![...ASFlags, ...SROFlags].some(flag => companyName.includes(flag))
  ) {
    return 'Z'
  }

  if(SROFlags.some(flag => companyName.includes(flag))) {
    return 'SRO'
  }

  if(ASFlags.some(flag => companyName.includes(flag))) {
    return 'AS'
  }

  return 'OTHER'
}

