<script setup>
import { onMounted } from 'vue'
import IconInfoDark from '@/components/server-configurator/icon/InfoDark.vue';
import { getPriceLabel } from '@/utils/checkout'
defineProps({
  level: {
    type: String,
    validator: (extension) => ['total-without-vat', 'total-discount', 'total'].includes(extension)
  },
  text: {
    type: String,
    required: true,
  },
  price: {
    type: String,
    required: true,
  },
  info: {
    type: String,
    default: '',
  },
})

onMounted(() => {
  window.webGlobe?.tooltip?.init()
})
</script>

<template>
<div :class="`b-server-configurator__form-actions-${level}`">
  <span :class="`b-server-configurator__form-actions-${level}-text`">
    {{ text }}
  </span>
  <span :class="`b-server-configurator__form-actions-${level}-price`">
    {{ getPriceLabel(price) }}
  </span>
  <button
    v-if="info"
    :class="`b-server-configurator__form-actions-${level}-tooltip`"
    type="button"
    data-bs-toggle="tooltip"
    data-bs-placement="left"
    data-bs-custom-class="custom-tooltip"
    :data-bs-title="info"
  >
    <IconInfoDark />
  </button>
</div>
</template>