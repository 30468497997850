<script setup>
import { useDebounceFn } from '@vueuse/core'

const props = defineProps(['modelValue'])
const emit = defineEmits(['onSearch', 'onLoading', 'onLoadingEnd'])

const handleDebouncedOnSearch = useDebounceFn((value) => {
  emit('onSearch', value)
  emit('onLoadingEnd')
}, 500)

const handleOnSearch = (value) => {
  emit('onLoading')
  handleDebouncedOnSearch(value)
}
</script>

<template>
  <div class="b-domain-list-section__search-bar b-domain-list-section__search-bar--tiny">
  	<div class="b-domain-list-section__search-bar__inner">
  		<input
        class="b-domain-list-section__search-bar__input"
        type="text"
        :value="modelValue"
        @input="e => handleOnSearch(e.target.value)"
        :placeholder="$t('domainList.search.placeholderDesktop')"
      >
  		<div class="b-domain-list-section__search-bar__actions">
  		  <button href="#" target="_self" rel="noopener noreferrer" class="b-button b-button--primary b-button--orange b-button--medium  b-button--if-label" id="js-helpdesk-search-submit" type="submit" onclick="">
  	    	<div class="b-button__icon b-button__icon--left">
  	    	  <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE4IDE4Ij48cGF0aCBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjI1IDNhNS4yNSA1LjI1IDAgMSAwIDAgMTAuNSA1LjI1IDUuMjUgMCAwIDAgMC0xMC41Wk0xLjUgOC4yNWE2Ljc1IDYuNzUgMCAxIDEgMTMuNSAwIDYuNzUgNi43NSAwIDAgMS0xMy41IDBaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS45OTMgMTEuOTk1YS43NS43NSAwIDAgMSAxLjA2IDBsMy4yMjYgMy4yMjVhLjc1Ljc1IDAgMSAxLTEuMDYgMS4wNmwtMy4yMjYtMy4yMjRhLjc1Ljc1IDAgMCAxIDAtMS4wNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==" alt="icon">
  	      </div>
  		  	<span class="b-button__label">{{ $t('search_btn')}}</span>
  	    </button>
  		</div>
  	</div>
  </div>
</template>
