<script setup>
import { ref, computed } from 'vue'
import { getWhoIs } from '@/services/whois.service.js'

const props = defineProps({
  opts: String | undefined
})

const input = ref('')
const inputFound = ref('')
const result = ref(null)
const isHandlingSearch = ref(false)

const handleSearch = async () => {
  if(isHandlingSearch.value) {
    return
  }
  isHandlingSearch.value = true

  const res = await getWhoIs(input.value)

  result.value = res

  if(res.success) {
    inputFound.value = input.value
  }

  isHandlingSearch.value = false
}


const svgPatternDesktopLeft = window.webGlobe.global.getSvgDataForImageSrc(`<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" width="245" height="515" fill="none">
  <path fill="url(#a)" d="M231.743 130.825c-.44-28.217-29.174-47.118-55.258-36.35l-448.346 185.079a106.999 106.999 0 0 1-93.633-5.841L-837.175 6.079c-26.907-15.267-60.217 4.48-59.735 35.413l8.416 539.95a40.002 40.002 0 0 0 25.808 36.776L190.793 1017.87c26.422 10.02 54.624-9.77 54.183-38.028l-13.233-849.017Z" opacity=".2"/>
  <defs>
    <radialGradient id="a" cx="0" cy="0" r="1" gradientTransform="rotate(131.44 -49.814 49.592) scale(790.354 904.911)" gradientUnits="userSpaceOnUse">
      <stop stop-color="#fff"/>
      <stop offset="1" stop-color="#fff" stop-opacity="0"/>
    </radialGradient>
  </defs>
</svg>`);

const svgPatternDesktopRight = window.webGlobe.global.getSvgDataForImageSrc(`<svg xmlns="http://www.w3.org/2000/svg" width="784" height="580" fill="none"><path fill="url(#a)" d="M577.721 118.846c-27.197-7.53-53.43 14.712-50.449 42.775l51.234 482.331a107 107 0 0 1-31.99 88.192L156.809 1109.29c-22.231 21.51-12.67 59.04 17.145 67.29l520.438 144.09a40.015 40.015 0 0 0 42.558-14.4l680.32-898.167c17.06-22.526 6.02-55.162-21.21-62.701L577.721 118.846Z" opacity=".2"/><path fill="url(#b)" d="M879.386 95.197c-21.133-18.701-54.466-10.228-64.103 16.295L649.639 567.377a107.002 107.002 0 0 1-67.45 65.205L66.499 800.435c-29.417 9.575-37.295 47.49-14.127 67.991L456.779 1226.3c12.274 10.86 29.933 13.13 44.559 5.74L1506.82 723.564c25.22-12.753 29.62-46.923 8.46-65.651L879.386 95.197Z" opacity=".2"/><defs><radialGradient id="a" cx="0" cy="0" r="1" gradientTransform="rotate(57.807 -101.321 633.71) scale(790.354 904.911)" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></radialGradient><radialGradient id="b" cx="0" cy="0" r="1" gradientTransform="rotate(83.839 179.188 527.285) scale(790.354 904.911)" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></radialGradient></defs></svg>`);

const svgPatternMobileLeft = window.webGlobe.global.getSvgDataForImageSrc(`<svg xmlns="http://www.w3.org/2000/svg" width="82" height="397" fill="none"><path fill="url(#a)" d="M74.61 90.859c-.44-28.217-29.173-47.118-55.258-36.35L-209.793 149.1a106.997 106.997 0 0 1-93.632-5.842l-241.75-137.17c-26.906-15.266-60.217 4.482-59.735 35.414l4.466 286.494a39.997 39.997 0 0 0 25.807 36.776L27.666 593.262c26.421 10.023 54.623-9.767 54.183-38.022L74.61 90.859Z" opacity=".2"/><defs><radialGradient id="a" cx="0" cy="0" r="1" gradientTransform="matrix(-314.96216 356.75206 -408.46061 -360.61358 -92.753 60.806)" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></radialGradient></defs></svg>`);

const svgPatternMobileRight = window.webGlobe.global.getSvgDataForImageSrc(`<svg xmlns="http://www.w3.org/2000/svg" width="375" height="291" fill="none"><path fill="url(#a)" d="M299.405 91.048c-27.197-7.53-53.43 14.712-50.449 42.775l26.185 246.514a106.999 106.999 0 0 1-31.99 88.192L43.415 661.827c-22.23 21.513-12.67 59.039 17.145 67.293l276.142 76.45a40 40 0 0 0 42.558-14.398l388.958-513.505c17.063-22.526 6.022-55.162-21.213-62.702l-447.6-123.917Z" opacity=".2"/><path fill="url(#b)" d="M476.018 86.069c-21.133-18.702-54.466-10.229-64.103 16.295l-84.659 232.998a107 107 0 0 1-67.45 65.205L-4.499 486.596c-29.417 9.575-37.295 47.49-14.128 67.991L195.95 744.472a40.002 40.002 0 0 0 44.56 5.74l574.86-290.708c25.218-12.753 29.62-46.924 8.457-65.651L476.018 86.069Z" opacity=".2"/><defs><radialGradient id="a" cx="0" cy="0" r="1" gradientTransform="rotate(57.807 -108.503 323.899) scale(475.892 544.869)" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></radialGradient><radialGradient id="b" cx="0" cy="0" r="1" gradientTransform="matrix(51.0715 473.14363 -541.72228 58.47394 340.974 189.395)" gradientUnits="userSpaceOnUse"><stop stop-color="#fff"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></radialGradient></defs></svg>`);

const notFoundSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="114" height="114" viewBox="0 0 114 114" fill="none"><circle cx="57" cy="57" r="44" fill="#F03A49"/><path d="M56 77C67.0457 77 76 68.0457 76 57C76 45.9543 67.0457 37 56 37C44.9543 37 36 45.9543 36 57C36 68.0457 44.9543 77 56 77Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M36 57H76M56 37C50.8645 42.3923 48 49.5535 48 57C48 64.4465 50.8645 71.6077 56 77C61.1355 71.6077 64 64.4465 64 57C64 49.5535 61.1355 42.3923 56 37Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><circle cx="67" cy="68" r="13" fill="#F03A49"/><path d="M67 78C72.5228 78 77 73.5228 77 68C77 62.4772 72.5228 58 67 58C61.4772 58 57 62.4772 57 68C57 73.5228 61.4772 78 67 78Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M70 65L64 71M64 65L70 71" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><circle opacity="0.5" cx="57" cy="57" r="55" stroke="#F03A49" stroke-width="2.07547" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="6.23 6.23"/></svg>'

 const NON_AVAILABLE_KEYPHRASES = [
  'Domain not found',
  'No entries found',
  'No match for',
  'Status: AVAILABLE',
  'Status: Available',
  'AVAILABLE',
  'NOT FOUND',
  'is available for registration',
  'Error 404',
  'No entries found for the selected source',
  'No match',
  'Status: free',
  'No Data Found',
  'Not found',
  'The queried object does not exist',
  'No Object Found',
  'nothing found',
  'No information available about domain name',
  'No registration records found',
  'Domain is available for registration',
  'This domain has not been registered',
  'Not registered',
  'Object does not exist',
  'This domain name is available',
  'The domain is unregistered',
  'Domain not available',
  'No such domain',
  'The domain you requested is available',
  'Domain not in registry',
  'Whois lookup failed for the domain',
  'No domain exists',
  'Domain is free for registration',
  'Object not found in the database',
  'The queried domain does not exist',
  'Error: domain not found in database',
  'No data available',
  'The domain does not exist',
  'Name not available in WHOIS',
  'Unknown domain name',
  'This domain has not been claimed',
  'Domain status: Unregistered',
  'not permitted',
  'no entries found',
  'No data was found to match the request criteria',

]

const isSuccess = computed(() => {
  
  if(!result.value?.success) {
    return false
  }
  
  if(!result.value?.result) {
    return false
  }

  if(result.value?.domain_status === 'EMPTY_TOPLEVEL') {
    return false
  }
  
  if(NON_AVAILABLE_KEYPHRASES.some(phrase => result.value.result.includes(phrase))) {
    return false
  }

  return true
})

</script>

<template>
  <div 
    class="b-top-section-secondary"
  >
    <div class="container">
      <div class="b-top-section-secondary__content">
        <h1 class="b-top-section-secondary__title">{{ props?.opts?.title || $t('whois.search-banner.title') }}</h1>
        <p class="b-top-section-secondary__subtitle">{{ props?.opts?.text || $t('whois.search-banner.description') }}</p>
        <div class="b-das">
          <div class="b-das__search-wrap b-das__search-wrap--allow-hover">
            <input v-model="input" @keyup.enter="handleSearch" class="b-das__domain-search" type="text" :placeholder="props?.opts?.input_label || $t('whois.search-banner.inputLabel')">
            <div class="b-das__toggle-area"><!---->
              <button 
                @click="handleSearch"
                :class="{ 'b-button--loading b-button--disabled': isHandlingSearch }"
                class="b-button b-button--primary b-button--orange b-button--big b-button--if-label"
              >
                <div class="b-button__icon b-button__icon--left">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                    <path fill="#fff" fill-rule="evenodd" d="M9.166 3.333a5.833 5.833 0 1 0 0 11.667 5.833 5.833 0 0 0 0-11.667Zm-7.5 5.833a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0Z" clip-rule="evenodd"></path>
                    <path fill="#fff" fill-rule="evenodd" d="M13.328 13.328a.833.833 0 0 1 1.179 0l3.583 3.583a.833.833 0 0 1-1.179 1.178l-3.583-3.583a.833.833 0 0 1 0-1.178Z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <span class="b-button__label d-none d-md-block">{{ props?.opts?.submit_label || $t('search_btn') }}</span>
              </button><!----><!---->
            </div>
          </div>
          <div class="b-das__result-wrap"><!----></div>
        </div>
      </div>
      <img class="b-top-section-secondary__mobile-pattern-left" :src="svgPatternMobileLeft" alt="pattern">
      <img class="b-top-section-secondary__mobile-pattern-right" :src="svgPatternMobileRight" alt="pattern">
      <img class="b-top-section-secondary__pattern-left" :src="svgPatternDesktopLeft" alt="pattern">
      <img class="b-top-section-secondary__pattern-right" :src="svgPatternDesktopRight" alt="pattern">
    </div>
  </div>
  <div v-if="result" class="b-whois-result">
    <div class="b-whois-result__header" v-if="isSuccess">
      <h2 
        class="b-whois-result__header-title"
      >
        {{ $t('whois.title') }} <a v-if="result?.result" :href="`https://${inputFound}`">{{ inputFound }}</a>
      </h2>
    </div>
    <div 
      v-if="isSuccess"  
      class="b-whois-result__content" 
      v-html="result?.result?.replace(/(?:\r\n|\r|\n)/g, '<br>')"
    ></div>
    <div class="b-whois-result__not-found" v-if="!isSuccess">
      <div class="b-whois-result__not-found__icon" v-html="notFoundSvg"></div>
      <div class="b-whois-result__not-found__content">
        <h2 class="b-whois-result__not-found__content-title">{{ $t('whois.title-not-found') }}</h2>
        <p class="b-whois-result__not-found__content-text">{{ $t('whois.text-not-found') }}</p>
      </div>
    </div>
  </div>
</template>