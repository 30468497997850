<script setup>
import {ref, onMounted, computed} from 'vue'
import IconEyeHide from '../auth/svg/iconEyeHide.vue'
import IconEyeShow from '../auth/svg/iconEyeShow.vue'
import IconWrong from '../auth/svg/iconWrong.vue'
import IconFine from '../auth/svg/iconFine.vue'
import IconNeutral from '../auth/svg/IconNeutral.vue'
import NxtInput from './NxtInput.vue'

const props =  defineProps(['modelValue', 'v', 'showIndicators', 'variant'])

const emit = defineEmits()

const wasChanged = ref(false)
const type = ref('password')
const isFocused = ref(false)

const updateModelValue = (newValue) => {
  emit('update:modelValue', newValue)
  wasChanged.value = true
}

const togglePassword = () => {
  if (type.value === 'password') {
    type.value = 'text'
  } else {
    type.value = 'password'
  }
}

const getErrorClass = (uid) => {
  if (!wasChanged.value) {
    return ''
  }
  return props.v?.$silentErrors?.find(e => e.$uid === uid) ? 'is-wrong' : 'is-fine'
}

onMounted(() => {
  if (props.modelValue) {
    wasChanged.value = true
  }
})

const isCheckout = computed(() => props.variant === 'checkout');
</script>

<template>
  <NxtInput :modelValue="modelValue" @update:modelValue="updateModelValue" :type="type" :v="v" v-bind="$attrs" @focus="isFocused = true" @blur="isFocused = false">
    <template #iconRight>
      <button type="button" class=" show-hide-pass b-form-input-wrapper__icon b-input-wrapper__icon--right" @click="togglePassword">
        <IconEyeHide v-if="type === 'password'" />
        <IconEyeShow v-else />
      </button>
    </template>
  </NxtInput>

  <div v-if="showIndicators && (isFocused || props.v?.$errors.length)" class="panel-right__password-status">
    <div class="row" :class="isCheckout ? 'row-gap-20' : 'row-gap-20 row-gap-md-10'">
      <div :class="[isCheckout ? 'col-6 col-md-3' : 'col-sm-6']">
        <div class="panel-right__password-status-item" :class="getErrorClass('password-hasLowerCase')">
          <IconWrong v-if="getErrorClass('password-hasLowerCase') === 'is-wrong'" />
          <IconFine v-else-if="getErrorClass('password-hasLowerCase') === 'is-fine'" />
          <IconNeutral v-else />
          <span>{{ $t('auth.register.passwordHint.lowerCase') }}</span>
        </div>
      </div>
      <div :class="[isCheckout ? 'col-6 col-md-3' : 'col-sm-6']">
        <div class="panel-right__password-status-item" :class="getErrorClass('password-hasNumber')">
          <IconWrong v-if="getErrorClass('password-hasNumber') === 'is-wrong'" />
          <IconFine v-else-if="getErrorClass('password-hasNumber') === 'is-fine'" />
          <IconNeutral v-else />
          <span>{{ $t('auth.register.passwordHint.number') }}</span>
        </div>
      </div>
      <div :class="[isCheckout ? 'col-6 col-md-3' : 'col-sm-6']">
        <div class="panel-right__password-status-item" :class="getErrorClass('password-hasUpperCase')">
          <IconWrong v-if="getErrorClass('password-hasUpperCase') === 'is-wrong'" />
          <IconFine v-else-if="getErrorClass('password-hasUpperCase') === 'is-fine'" />
          <IconNeutral v-else />
          <span>{{ $t('auth.register.passwordHint.upperCase') }}</span>
        </div>
      </div>
      <div :class="[isCheckout ? 'col-6 col-md-3' : 'col-sm-6']">
        <div class="panel-right__password-status-item" :class="getErrorClass('password-minLength')">
          <IconWrong v-if="getErrorClass('password-minLength') === 'is-wrong'" />
          <IconFine v-else-if="getErrorClass('password-minLength') === 'is-fine'" />
          <IconNeutral v-else />
          <span>{{ $t('auth.register.passwordHint.minLength') }}</span>
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.is-wrong {
  color: #F90014;
}
.is-fine {
  color: #44CC4F;
}
</style>
