// Import bootstrap
// import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

// Register js to wg object on window
import './assets/js/register';

// Import autorun and run it
import { autorun } from './assets/js/autorun';
autorun?.domLoaded();

import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import {registerWgGlobalComponent, renderApp} from './register-wg-components';

import { i18n } from './i18n-instance';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

registerWgGlobalComponent();

document.addEventListener("DOMContentLoaded", function(event) {
  window.webGlobe?.wgEvent('setupComponent', (event)=> {
    setupComponents(event.wgComp, event.wgOpts);
  })
});


const setupComponents = (component, opts = null) => {
  let elements = component ? document.querySelectorAll(`[wg-comp="${component}"]`) : document.querySelectorAll('[wg-comp]');

  if (opts?.id) {
    elements = document.querySelectorAll(`#${opts?.id}`);
  }

  elements.forEach(el => {
    const wgOpts = el.getAttribute('wg-opts');
    if (wgOpts) {
      if (opts?.fromBridge) {
        opts = {
          ...JSON.parse(wgOpts.replace(/&quot;/g, '\"')),
          ...opts,
        }
      } else {
        opts = {
          ...opts,
          ...JSON.parse(wgOpts.replace(/&quot;/g, '\"')),
        }
      }
    }

    const app = renderApp(el, opts);
    app.use(i18n).use(pinia).mount(el);
  });
}

setupComponents();
