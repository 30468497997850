import { defineStore } from "pinia";
import { nextTick } from 'vue'
import { useGlobalStore } from "./global.store";
import { prepareTldList } from "@/utils";

export const usePricingTableStore = defineStore("pricingTable", {
  state: () => ({
    config: {
      das_config_id: 1,
      domain_list: '',
      search_bar_position: 'left',
      show_searchbar: true
    },
    tldList: [],
    activeTldList: [],
    resultList: [],
    searchTldInput: "",
    selectedTld: undefined
  }),
  getters: {
    getTldObject: (state) => {
      return (tlName, componentId) => {
        if (!componentId) {
          const globalStore = useGlobalStore();
          return globalStore.tldList[tlName];
        }
        return state.getDas(componentId).tldList.find((tld) => tld.tl_name === tlName)
      }
    },
    getResults: (state) => {
      return () => {
        let resultList = state.resultList;
        if (state.selectedTld !== undefined) {
          resultList = state.resultList.filter((tld) => tld.tl_name === state.selectedTld);
          return resultList;
        }

        if (state.searchTldInput !== '') {
          resultList = state.tldList.filter((tld) => tld.tl_name.startsWith(state.searchTldInput.toLocaleLowerCase()));
          if (resultList.length === 0) {
            const firstLetter = state.searchTldInput.charAt(0).toLocaleLowerCase();
            resultList = state.tldList.filter((tld) => tld.tl_name.startsWith(firstLetter));
          }
        }
        return resultList;
      }
    }
  },
  actions: {
    setConfig(config, opts) {
      this.config = {
        ...config,
        ...opts ? opts : {}
      }
    },
    async setTldList() {
      const globalStore = useGlobalStore();
      this.tldList = prepareTldList(globalStore.tldList, this.config.domain_list);
      this.updateResultList();
    },

    updateResultList() {
      const currentLength = this.resultList.length;
      // this.resultList = this.tldList.slice(0, currentLength + 12);
      this.resultList = this.tldList;
    },

  },
  /* persist: {
    debug: true,
    paths: [ "das"],
  }, */
});
