import config from '@/config';
import { wpWg } from '@/axios.js'

export async function submitFreeWebeditorAccount(email) {
  try {
    const { data: requestResponseData } = await wpWg.post('/general', {
      endpoint: `/order/webbuilder/create-test-acc`,
      method: 'POST',
      content: {
        email,
        lang: config.invoiceLanguage,
        form_name: config.formName,
      }
    })

    const { data: successResponseData } = await wpWg.post('/general', {
      endpoint: '/order/webbuilder/login-to-test-acc',
      method: 'POST',
      content: {
        billfirm: 'IGN',
        hash: requestResponseData.hash,
        userID: requestResponseData.userID
      }
    })

    return successResponseData

  } catch(error) {
    return error
  }
}
