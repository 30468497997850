<script setup>
import {onMounted, ref, nextTick} from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { t } from '@/i18n-instance';
import { useAuthStore } from '@/stores/auth.store'
const props = defineProps(['modelValue', 'name', 'isRequired', 'isDisabled', 'v', 'hideErrors', 'type', 'label', 'autofocus', 'foxentry', 'iconRight', 'iconLeft', 'background', 'errorMsg', 'isValueCapitalized'])

const emit = defineEmits(['update:modelValue', 'onBlur', 'onFocus', 'onDebouncedInput'])

const authStore = useAuthStore()

const myInput = ref(null)
const wasChanged = ref(false)
const customError = ref('')

const debouncedInputEmit = useDebounceFn(() => {
  emit('onDebouncedInput');
}, 200);

const capitalize = (string) => {
  if(!string) {
    return ''
  }
  const [firstLetter, ...restLetters] = string.split('')
  return `${firstLetter.toUpperCase()}${restLetters.join('')}`
}

const updateValue = (event) => {
  const value = props.isValueCapitalized ? capitalize(event.target.value) : event.target.value
  emit('update:modelValue', value)
  wasChanged.value = true
  debouncedInputEmit()
}

const isFocused = ref(false)

const onFocus = () => {
  emit('onFocus')
  isFocused.value = true
}

const onBlur = async () => {
  let isValid = false
  if (wasChanged.value && props.v){
    isValid = await props.v.$validate()
  }
  if (wasChanged.value && props.foxentry && isValid) {
    if (props.foxentry === 'email') {
      foxentryEmailValidation()
    }

    if (['name', 'surname'].includes(props.foxentry)) {
      foxentryNameValidation(props.foxentry)
    }
  }
  emit('onBlur')
  isFocused.value = false
}

const foxentryEmailValidation = async (value) => {
  if (authStore.form.register.foxentryValidator.email.value === props.modelValue) return
  const result = await authStore.foxentryEmailValidation(props.modelValue)
  if (result.isValid) {
    customError.value = ''
    return
  }

  const error = result?.errors[0]
  if (error && error.subtype === 'DISPOSABLE') {
    customError.value = t('validations.emailDisposable')
    return
  }
  if (error && (error.subtype === 'DOMAIN' || error.subtype === 'DOMAIN_DNS') ) {
    customError.value = t('validations.emailDomain')
    return
  }
  customError.value = t('validations.emailInvalid')
}

const foxentryNameValidation = async (type) => {
  if (authStore.form.register.foxentryValidator[type].value === props.modelValue) return
  const search = {
    [type]: props.modelValue
  }
  const result = await authStore.foxentryNameValidation(search)
  if (result.isValid) {
    customError.value = ''
    return
  }
  customError.value = t('validations.nameInvalid')
}

onMounted(() => {
  nextTick(() => {
    if (props.autofocus) {
      myInput.value.focus();
    }
  });
  if (props.modelValue) {
    wasChanged.value = true
  }
})

defineExpose({ myInput })

</script>

<template>
  <div class="b-form__group"
    :class="{
      'b-form__group--filled': modelValue,
      'b-form__group--focused': isFocused,
      'b-form__group--disabled': isDisabled,
      'b-form__group--icon-left': iconLeft,
      'b-form__group--icon-right': iconRight,
      'b-form__group--error': v?.$errors.length || customError || errorMsg,
      'b-form__group--background-light-blue': background && background === 'light-blue'
    }"
  >
    <slot name="autocomplete"></slot>
    <label v-if="label" class="b-form__label-input">{{ label }} <span v-if="isRequired">&nbsp;*</span></label>
    <div class="b-form-input">
      <slot name="iconLeft"></slot>
      <input
        ref="myInput"
        :type="type ?? 'text'"
        :value="modelValue"
        @click.stop
        @input="updateValue"
        @blur="onBlur"
        @focus="onFocus"
        v-bind="$attrs"
        :disabled="isDisabled"
        maxlength="100"
      >
      <slot name="iconRight"></slot>
    </div>
    <div v-if="!hideErrors && v?.$errors.length" class="b-form__helper-text">{{ v.$errors[0].$message }}</div>
    <div v-else-if="!hideErrors && (customError || errorMsg)" class="b-form__helper-text">{{ customError || errorMsg }}</div>
    <!-- Move to inside input -->
  </div>
</template>

<style lang="scss" scoped></style>
