export const randomBanners = {
  init() {
    const megaMenus = document.querySelectorAll('.b-mega-menu')

    if(!megaMenus.length) {
      return
    }

    megaMenus.forEach(megaMenu => {
      const banners = megaMenu.querySelectorAll('.b-mega-menu__banner')
      
      if(banners.length <= 1) {
        return
      }

      const bannersList = [...banners]
      bannersList.slice(1).forEach(banner => {
        banner.classList.add('d-lg-none')
      })

      let currentActiveBannerIndex = 0
      let isBannerGoingToAppear

      const observer = new MutationObserver(() => {
        const currentMegaMenuClassList = [...megaMenu.classList]
        if(
          !currentMegaMenuClassList.includes('collapsing') &&
          !currentMegaMenuClassList.includes('show')
        ) {
          isBannerGoingToAppear = true
        }
        
        if(currentMegaMenuClassList.includes('collapsing') && isBannerGoingToAppear) {
          currentActiveBannerIndex = currentActiveBannerIndex < banners.length - 1 
            ? currentActiveBannerIndex + 1 
            : 0
          
          banners.forEach((banner, index) => {
            if(index === currentActiveBannerIndex) {
              banner.classList.remove('d-lg-none')
            } else {
              banner.classList.add('d-lg-none')
            }
          })

          isBannerGoingToAppear = false
        }
      })

      observer.observe(megaMenu, { attributes: true, attributeFilter: ['class']})
    })
  }
}