import { parseOpts } from '@/utils/index.js';
import config from '@/config';
class DataLayer {
  constructor() {
    this.observer = null;
    this.initObserver();
  }

  init = () => {
    const dataLayers = document.querySelectorAll('[data-layer]');
    if (!dataLayers) {
      return;
    }
    dataLayers.forEach(dataLayer => {
      const type = dataLayer.getAttribute('data-layer-type') || 'click';
      if (type === 'click') {
        dataLayer.addEventListener('click', this.clickHandler);
      } else if (type === 'visible') {
        this.observer.observe(dataLayer);
      } else if (type === 'immediate') {
        this.immediateHandler(dataLayer);
      }
    });
  }

  initObserver = () => {
    this.observer = new IntersectionObserver(this.visibilityHandler, {
      threshold: 0.1,
    });
  }

  visibilityHandler = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const target = entry.target;
        if (!target || !target.hasAttribute('data-layer')) {
          return;
        }
        try {
          const dataLayer = target.getAttribute('data-layer');
          const provider = target.getAttribute('data-layer-provider');
          const data = parseOpts(dataLayer);
          this.sendData(data, provider);
          observer.unobserve(target);
        } catch (error) {
          console.error('Error parsing data-layer attribute', error);
        }
      }
    });
  }

  clickHandler = (e) => {
    const target = e.currentTarget;
    if (!target || !target.hasAttribute('data-layer')) {
      return;
    }

    try {
      const dataLayer = target.getAttribute('data-layer');
      const provider = target.getAttribute('data-layer-provider');
      const data = parseOpts(dataLayer);
      this.sendData(data, provider);
    } catch (error) {
      console.error('Error parsing data-layer attribute', error);
    }
  }

  immediateHandler = (e) => {
    const target = e;
    if (!target || !target.hasAttribute('data-layer')) {
      return;
    }
    const dataLayerData = target.getAttribute('data-layer');
    const provider = target.getAttribute('data-layer-provider');
    const data = parseOpts(dataLayerData);
    this.sendData(data, provider);
  }

  sendData = (data, provider = 'gtm') => {
    if (provider === 'matomo') {
      return this.sendMatomoData(data);
    }
    window.dataLayer = window.dataLayer || [];
    data._clear = true;
    if (!data.currency) {
      data.currency = config.currency_code
    }
    if (!data.coupon) {
      data.coupon = ''
    }
    window.dataLayer.push(data);
    // console.log('data :>> ', data);
  }

  sendMatomoData = (data) => {
    window._paq = window._paq || [];
    window._paq.push(data);
  }
}



export const dataLayer = new DataLayer();
