<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import Button from '@/stories/Button.vue'
import { useCheckoutStore } from '@/stores/checkout.store'

const $checkout = useCheckoutStore()

const props = defineProps({
  domain: {
    type: String,
    default: ''
  },
})

const emit = defineEmits(['onEdit'])

const profileComputed = computed(() => {
  const registrantProfile = props.domain && $checkout.domainRegistrantsIDs[props.domain]
    ? [...$checkout.listOfRegistrants, ...$checkout.listOfTemporaryRegistrants]
        .find(registrant => {
          if(registrant.IDregistrant) {
            return registrant.IDregistrant === $checkout.domainRegistrantsIDs[props.domain]
          }
          return registrant.temporaryIDregistrant === $checkout.domainRegistrantsIDs[props.domain]
        })
    : null

  const currentBillingProfile = $checkout.currentBillingProfileID === null
    ? $checkout.billingProfiles.main_profile
    : $checkout.billingProfiles.bill_profiles.find(profile => {
      return $checkout.currentBillingProfileID === profile.IDbillprofile
    })

  return {
    customer_name: registrantProfile?.owner?.name
      || currentBillingProfile?.contact_name
      || currentBillingProfile?.customer_name
      || '',
    customer_email: registrantProfile?.contact?.email
      || currentBillingProfile?.contact_email
      || currentBillingProfile?.customer_email
      || '',
    customer_phone: registrantProfile?.contact?.phone
      || currentBillingProfile?.contact_phone
      || currentBillingProfile?.customer_phone
      || '',
    customer_street: registrantProfile?.owner?.street
      || currentBillingProfile?.invoice_street
      || currentBillingProfile?.customer_street
      || '',
    customer_town: registrantProfile?.owner?.town
      || currentBillingProfile?.invoice_town
      || currentBillingProfile?.customer_town
      || '',
    customer_postcode: registrantProfile?.owner?.postcode
      || currentBillingProfile?.invoice_postcode
      || currentBillingProfile?.customer_postcode
      || '',
    customer_state: registrantProfile?.owner?.state
      || currentBillingProfile?.invoice_state
      || currentBillingProfile?.customer_state
      || '',
  }
})
</script>

<template>
  <div class="b-checkout-content__summary-order">
    <div class="b-checkout-content__summary-order-title">
      <div>{{ domain ? $t('checkout.step4.domainOwner') : $t('checkout.step4.billingInfo') }}</div>
      <Button @click="emit('onEdit')" class="b-checkout-content__summary-order-edit" variant="link" iconLeft='<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.55 2.598a1.557 1.557 0 0 0-1.747.44.837.837 0 0 1-.047.051L3.663 14.183l-.808 2.963 2.962-.808L16.911 5.244c.019-.019.039-.037.06-.054a1.528 1.528 0 0 0 .554-1.099 1.512 1.512 0 0 0-.444-1.146 1.543 1.543 0 0 0-.531-.347Zm-.698-1.77a3.223 3.223 0 0 1 2.408.938 3.19 3.19 0 0 1 .93 2.407 3.178 3.178 0 0 1-1.127 2.276L6.839 17.673a.833.833 0 0 1-.37.215l-4.583 1.25a.833.833 0 0 1-1.023-1.024l1.25-4.583a.834.834 0 0 1 .215-.37L13.555 1.934A3.208 3.208 0 0 1 15.852.828Z" fill="#2C57DE"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.91 3.578a.833.833 0 0 1 1.18 0l3.333 3.333a.833.833 0 0 1-1.179 1.178l-3.333-3.333a.833.833 0 0 1 0-1.178Z" fill="#2C57DE"/></svg>' />
    </div>
    <div v-if="props.domain" class="b-checkout-content__summary-order-title">
      <div>{{ $t('checkout.step4.domain') }} - <span class="text-blue">{{ domain }}</span></div>
    </div>
    <div class="b-checkout-content__summary-order-content">
      <p>
        {{ profileComputed.customer_name }} <br>
        {{ profileComputed.customer_email }} <br>
        {{ profileComputed.customer_phone }} <br>
        {{ profileComputed.customer_street }} <br>
        {{ profileComputed.customer_postcode }} &nbsp; {{ profileComputed.customer_town }}, {{ profileComputed.customer_state }}
      </p>
    </div>
  </div>
</template>
