<script setup>
import { watch, inject, ref, nextTick, onMounted, computed, defineEmits } from 'vue'
import { refDebounced } from '@vueuse/core'
import { useDasStore } from '@/stores/das.store.js'
import { removeAccents } from '@/utils'

const emit = defineEmits(['onFocus', 'onFocusOut', 'onFocusIn', 'onDebouncedInput'])

const config = inject('config')
const componentId = inject('componentId')

const inputRef = ref(null)

const dasStore = useDasStore()

const searchDomainInput = ref(dasStore.getDas(componentId).searchDomainInput)
const debouncedSearchInput = refDebounced(searchDomainInput, config.value.delay)

const autofocus = computed(() => dasStore.getDas(componentId).config?.autofocus)

const isPreferredDomainConfigurated = computed(() => Boolean(dasStore.getDas(componentId).config?.preferred_domain))

watch(searchDomainInput, (newValue) => {
  const sanitizedValue = removeAccents(newValue.toLowerCase())
    .replace(/^\.|^\-/, '')
    .replace(/[^a-zA-Z0-9-.]/g, '')

  if(isPreferredDomainConfigurated.value && sanitizedValue !== newValue) {
    searchDomainInput.value = sanitizedValue.replace(/\.$/, '')
    return
  }

  const isWwwIncluded = sanitizedValue.startsWith('www.') || sanitizedValue.startsWith('WWW.')
  const sanitizedValueWithTwoTldsMax = sanitizedValue.split('.', isWwwIncluded ? 4 : 3).join('.')

  if (sanitizedValueWithTwoTldsMax !== newValue) {
    searchDomainInput.value = sanitizedValueWithTwoTldsMax
  }
})

watch(debouncedSearchInput, async () => {
  await dasStore.searchTld(componentId, debouncedSearchInput.value)
  dasStore.switchTransfer(componentId)

  if(debouncedSearchInput.value !== '') {
    emit('onDebouncedInput')
  }
})

watch(() => dasStore.getDas(componentId).searchDomainInput, (newValue) => {
  if (newValue !== searchDomainInput.value) {
    searchDomainInput.value = newValue || ''
  }
  dasStore.getResults(componentId)
})

const onFocus = async () => {
  await dasStore.searchTld(componentId)
  emit('onFocus')
}
const onBlur = async () => {
  if('ontouchstart' in window) {
    emit('onMobileBlur')
  }
}

onMounted(async () => {
  await nextTick()
  if (autofocus.value) {
    inputRef.value?.focus()
  }
})
</script>

<template>
  <input
    v-model="searchDomainInput"
    class="b-das__domain-search"
    type="text"
    :placeholder="dasStore.getDas(componentId).config.placeholder || dasStore.getDas(componentId).config?.content?.placeholder || $t('input_search')"
    ref="inputRef"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>
