<script setup>
import { computed, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { getIsServerNameValid } from '@/services';
import { required, alphaNum, minLength, maxLength } from '@/utils/i18n-validators'
import NxtInput from '@/components/shared/NxtInput.vue'
import Button from '@/stories/Button.vue';
import { t } from '@/i18n-instance'
import { watch } from 'vue'
import { useCheckoutStore } from '@/stores/checkout.store'

const props = defineProps(['canGoBack'])

const emit = defineEmits(['onGoBack'])

const $checkout = useCheckoutStore()

const serverName = ref('')
const isServerNameValid = ref(null)
const isServerNameValidating = ref(false)
const isRewriting = ref(false)

const visibilityTimeout = ref(null)
const isSubmitButtonVisible = ref(true)

const rules = {
  serverName: {
    required: required,
    alphaNum: alphaNum('validations.serverNameOnlyAlphanumeric'),
    minLength: minLength(4),
    maxLength: maxLength(20),
  }
}

const $v = useVuelidate(rules, { serverName })

const handleRewrite = () => {
  clearTimeout(visibilityTimeout.value)
  isServerNameValid.value = null
  isRewriting.value = true
  isSubmitButtonVisible.value = true
}

watch(serverName, _serverName => {
  if(isServerNameValidating.value) {
    return
  }
  handleRewrite()
})

const handleValidation = async () => {
  if(isServerNameValidating.value || isSuccess.value) {
    return
  }

  isRewriting.value = false

  const isVuelidate = await $v.value.$validate()

  if(!isVuelidate) {
    return
  }

  const isServerNameExistingInCart = Boolean($checkout.getCheckoutItems.filter(
    (item) => item.update_name.includes(serverName.value)
  ).length)

  if(isServerNameExistingInCart) {
    isServerNameValid.value = false
    return
  }

  isServerNameValidating.value = true

  const { data: { result } } = await getIsServerNameValid(serverName.value)


  if(result === 'AVAILABLE') {
    isServerNameValid.value = true
    isServerNameValidating.value = false

    visibilityTimeout.value = setTimeout(() => {
      isSubmitButtonVisible.value = false
      return clearTimeout(visibilityTimeout.value)
    }, 3000)

    return
  }

  isServerNameValid.value = false
  isServerNameValidating.value = false
}

const handleClearName = () => {
  serverName.value = ''
  isServerNameValid.value = null
  clearTimeout(visibilityTimeout.value)
  isSubmitButtonVisible.value = true
}

const isClearNameButtonVisible = computed(() => {
  return isServerNameValid.value !== null && isServerNameValid.value !== false && serverName.value
})

const handleOnGoBack = () => {
  emit('onGoBack')
  handleClearName()
}

const handleDebouncedInput = () => {
  $v.value.serverName.$touch()
}

const isSuccess = computed(() => {
  return serverName.value && isServerNameValid.value !== null && isServerNameValid.value !== false && !isServerNameValidating.value
})

const iconRemoveBtn = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#8E98A7"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#8E98A7"/>
</svg>
`;

const inputLabel = (() => {
  if(window.innerWidth >= 768) {
    return t('serverConfiguration.nameValidationInputLabel')
    } else {
    return t('serverConfiguration.nameValidationInputLabelMobile')
  }
})()

const defaultButtonLabel = (() => {
  if(window.innerWidth >= 768) {
    return t('serverConfiguration.nameValidationButtonLabel.default')
    } else {
    return t('serverConfiguration.nameValidationButtonLabel.defaultMobile')
  }
})()
</script>

<template>
<div class="b-server-configurator__server-name-validator">
  <p class="b-server-configurator__server-name-validator__description">
    {{ $t('serverConfiguration.nameValidationDescription') }}
  </p>
  <div class="b-server-configurator__server-name-validator__input-wrapper">
    <NxtInput 
      v-model="serverName" 
      :label="inputLabel" 
      name="login" 
      :v="$v.serverName" 
      :autofocus="true" 
      :error-msg="isServerNameValid !== null && !isServerNameValid ? $t('serverConfiguration.occupied') : null" 
      @on-debounced-input="handleDebouncedInput"
    />
    <div class="b-server-configurator__server-name-validator__input-wrapper-buttons">
      <Button
        v-if="isClearNameButtonVisible"
        @click="handleClearName"
        size="medium"
        mobile-size="small"
        color="gray"
        :iconRight="iconRemoveBtn"
        variant="link"
        class="remove-name-button"
      />
      <Button
        v-if="isSubmitButtonVisible"
        :loading="isServerNameValidating"
        :label="isServerNameValid !== null && isServerNameValid 
          ? $t('serverConfiguration.nameValidationButtonLabel.success') 
          : defaultButtonLabel
        " 
        :color="isSuccess ? 'green3': 'blue'"
        @click="handleValidation"
        :class="{ 'click-not-allowed': isSuccess }"
      />
    </div>
  </div>
  <div class="b-server-configurator__server-name-validator__buttons-wrapper">
    <Button @click="emit('onAddToCart', serverName)" color="orange" :label="$t('addToBasket')" :disabled="!isSuccess" />
    <Button v-if="props.canGoBack" @click="handleOnGoBack" variant="outline" :label="$t('back')" />
  </div>
</div>
</template>