<script setup>
import { computed } from 'vue';
import CartRemoveButton from "@/components/cart/CartRemoveButton.vue";
import { useCheckoutStore } from "@/stores/checkout.store";
import { useGlobalStore } from "@/stores/global.store";
import { formatPrice } from '@/utils'
import { getPeriodLabel } from '@/utils/checkout'

const $checkout = useCheckoutStore();
const $global = useGlobalStore();

const props = defineProps({
  item: {
    type: Object,
  },
});

const isDiscount = computed(() => props.item.discount_percentage > 0);
const discount = computed(() => isDiscount ? `${props.item.discount_percentage * (-1)}%` : 0);
const generalConfig = computed(() => $global.getConfigBySlug('general'));
</script>

<template>
  <div class="b-cart-panel-item">
    <div class="b-cart-panel-item__text" v-html="item.name"></div>
    <div v-if="isDiscount" class="b-cart-panel-item__badge">{{ discount }}</div>
    <div class="b-cart-panel-item__price">
      <div v-if="isDiscount" class="b-cart-panel-item__price-base">{{ item.price_base}}</div>
      <div class="b-cart-panel-item__price-final">
        <span class="b-cart-panel-item__price-final__amount">
          {{ formatPrice(item.price_final, generalConfig.prices_round) }}
        </span>
        <span class="b-cart-panel-item__price-final__currency">
          {{ generalConfig.currency_symbol }}
        </span>
        <span class="b-cart-panel-item__price-final__period">
          {{ item.period === 12 ? $t('price_year_without_asterix') : `/${getPeriodLabel(item.period)}` }}
        </span>
      </div>
      <CartRemoveButton @click="$checkout.removeFromBasket({ index: item.id, location_source: 'cart' })" :class="{disabled: $checkout.isApiCall}" :disabled="$checkout.isApiCall"/>
    </div>
  </div>
</template>

<style scoped></style>
