<script setup>
import Domain from './Domain.vue'
import OnlyFree from './OnlyFree.vue'
import Btns from './Btns.vue'
import { useDasStore } from '@/stores/das.store'

const emit = defineEmits(['onSearchTld'])

import { inject } from 'vue'
const config = inject('config')
const componentId = inject('componentId')

const dasStore = useDasStore();
</script>

<template>
  <div class="b-das__toggle-area">
    <Domain v-if="dasStore.getDas(componentId).preferred_domain" :tld="dasStore.getDas(componentId).preferred_domain" />
    <OnlyFree v-if="dasStore.getDas(componentId).onlyFreeToggle" class="b-form__group--switch-right d-none d-md-block" />
    <Btns @onSearchTld="emit('onSearchTld')" />
  </div>
</template>
