<script setup>
// core imports
import { useAuthStore } from "@/stores/auth.store";
import iconExternal from './svg/iconExternal.vue'

const props = defineProps({
  isRightPanel: Boolean | undefined,
});

const authStore = useAuthStore();
</script>

<template>
  <div>
    <div class="panel-right__title">
      <h4 class="panel-right__title-title">{{ authStore.config.account_title }}</h4>
    </div>
    <div class="panel-right__account">
      <a
        v-for="(service, index) in authStore.config.login_services"
        :href="service.link.url"
        :key="index"
        target="_blank"
        class="panel-right__account-item"
      >
        <img class="panel-right__account-item-icon" :src="service.img.url" :alt="service.img.alt">
        <div class="panel-right__account-item-name">{{ service.name }}</div>
        <iconExternal class="ms-auto" />
      </a>
    </div>
    <button @click="authStore.logout" class="btn btn-primary btn-md w-100 mt-20">{{ $t('auth.myAccount.logoutBtn') }}</button>
  </div>
</template>

<style scoped></style>
