
import { setElementsScrollPositionFromTop, getCssVariable } from '../../../utils/dom'

export const collapseScroller = {
  init() {
    const collapseButtons = document.querySelectorAll('[data-scroll-button], [data-scroll-target]')

    collapseButtons.forEach(button => {
      button.addEventListener('click', _event => {

        const topOffset = Number(getCssVariable({ element: document.documentElement, variable: '--header-height-for-top-offset'}))

        if(button.classList.contains('collapsed')) {
          return
        }

        if(button.getAttribute('data-scroll-button') !== null) {
          setElementsScrollPositionFromTop({ element: button, topOffset: topOffset + 10 })
        }

        if(button.getAttribute('data-scroll-target') !== null) {
          const target = document.querySelector(`.b-collapse-wrapper${button.getAttribute('href')}`)
          setElementsScrollPositionFromTop({ element: target, topOffset: topOffset + 20 })
        }
      })
    })
  }
}