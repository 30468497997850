import { useDataLayer } from "@/dataLayer";
class Modals {
  constructor() {
  }

  init = () => {
    const { modalWindowOpen, modalWindowClose } = useDataLayer();
    document.body.addEventListener('show.bs.modal', event => {
      const modalId = event.target.id;
      modalWindowOpen(modalId);
    });

    document.body.addEventListener('hidden.bs.modal', event => {
      const modalId = event.target.id;
      modalWindowClose(modalId);
    });
  }

  openModal(modalId) {
    const modal = document.getElementById(modalId);
    if (!modal) {
      return;
    }
    const bootstrapModal = new bootstrap.Modal(modal);
    bootstrapModal.show();
  }

  closeModal(modalId) {
    const modal = document.getElementById(modalId);
    if (!modal) {
      return;
    }
    const bootstrapModal = new bootstrap.Modal(modal);
    bootstrapModal.close();
  }

  closeAllModals() {
    const modals = document.querySelectorAll('.modal.show');
    modals.forEach((modal) => {
      const bootstrapModal = bootstrap.Modal.getInstance(modal);
      if (bootstrapModal) {
        bootstrapModal.hide();
      }
    });
  }
}

export const modals = new Modals();
