<script setup>
import { computed } from 'vue'
import { useCheckoutStore } from '@/stores/checkout.store';
import SummaryOrderProfile from '@/components/checkout/components/SummaryOrderProfile.vue'
import SummaryOrderPayment from '@/components/checkout/components/SummaryOrderPayment.vue'

const $checkout = useCheckoutStore()
$checkout.getListOfRegistrants()

const payment = computed(() => $checkout.paymentTypes[$checkout.selectedPaymentType])

const checkoutDomainItems = computed(() => $checkout.getCheckoutDomains)
</script>

<template>
  <div class="b-checkout-content b-checkout-content__summary-order-wrapper">
    <div class="b-checkout-content__header">
      <div class="b-checkout-content__header-title">
        <h2 class="b-checkout-content__header-title-h2 h6">{{ $t('checkout.step4.orderSummary') }}</h2>
      </div>
    </div>
    <div class="b-checkout-content__content">
      <div class="row g-10 g-lg-20">
        <div class="col-lg-6">
          <SummaryOrderProfile
            @on-edit="$checkout.step4EditMode = 'billProfile'"
          />
        </div>
        <div class="col-lg-6">
          <SummaryOrderPayment :payment="payment" />
        </div>
      </div>
    </div>
    <template v-if="checkoutDomainItems.length">
      <div class="b-checkout-content__header">
        <div class="b-checkout-content__header-title">
          <h2 class="b-checkout-content__header-title-h2 h6">{{ $t('checkout.step4.serviceSettings') }}</h2>
        </div>
      </div>
      <div class="b-checkout-content__content">
        <div class="row g-10 g-lg-20">
          <div v-for="checkoutDomainItem of checkoutDomainItems" :key="checkoutDomainItem.name" class="col-lg-6">
            <SummaryOrderProfile
              @on-edit="() => {
                $checkout.step4EditMode = 'domainOwner'
                $checkout.currentDomainRegistrantEditing = checkoutDomainItem.name
              }"
              :domain="checkoutDomainItem.name"
            />
          </div>
        </div>
      </div>
    </template>
    <div class="b-checkout-content__footer">
      <div class="b-checkout-content__footer-consent" v-html="$t('checkout.step4.vop')">
        
      </div>
    </div>
  </div>
</template>
