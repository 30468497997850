<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    content: {
      type: String,
      default: ''
    },
  });

  const section = computed(() => {
    return {
      styles: {
      }
    }
  });
</script>

<template>
  <div class="wp-block-wysiwyg">
    <div class="b-wysiwyg" :style="section.styles">
      <slot>
        <h1>Lorem Ipsum</h1>
        <h2>Lorem Ipsum</h2>
        <h3>Lorem Ipsum</h3>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Adipisci cumque perspiciatis ducimus laudantium corrupti, odit debitis, labore ipsum veritatis magni necessitatibus commodi fugit natus optio maiores pariatur, quod quos accusamus.</p>
        <ul>
          <li><b>Certifikovaný registrátor CZ.NIC</b> s nejvyšším možným počtem pěti hvězdiček.</li>
          <li><b>Certifikovaný registrátor EURid a SK.NIC</b> - záruka rychlosti a kvality.</li>
          <li>Výběr z více než <b>380 oblíbených koncovek.</b></li>
          <li>Doména je vaše ihned po uhrazení platby</li>
          <li>Na expiraci domény vás upozorníme jak e-mailem, tak v SMS.</li>
          <li>Možnost automatického prodloužení domény.</li>
          <li>Doménová pojistka - uzamčení domény proti změnám a zabezpečení proti její expiraci.</li>
          <li>Přesměrování webu na vaši .CZ doménu zdarma.</li>
        </ul>
        <img src="https://picsum.photos/2000/2000" alt="">
      </slot>
    </div>
  </div>
</template>

