<script setup>
import { inject } from 'vue';
import Transfer from './Transfer.vue';
import TransferNotAvailable from './TransferNotAvailable.vue';
import TransferLogin from './TransferLogin.vue';
import TransferLoading from './TransferLoading.vue';
import TransferCdasOptions from './TransferCdasOptions.vue';

import { useDasStore } from '@/stores/das.store.js';
const dasStore = useDasStore();

const componentId = inject('componentId')
</script>

<template>
  <div class="b-das__transfer-wrap">
    <div v-if="typeof dasStore.getDas(componentId).transfer === 'object'">
      <TransferCdasOptions
        v-if="
        dasStore.isCdas(componentId)
          && !dasStore.isAlreadyHostedInternally(componentId)
          && !dasStore.isDomainTransferRequired
        "
      />
      <transfer v-else-if="dasStore.isTransfer(componentId)" />
      <transfer-login v-else-if="dasStore.isAlreadyHostedInternally(componentId)" />
      <transfer-not-available v-else />
    </div>
    <transfer-loading v-else />
  </div>
</template>
