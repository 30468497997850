<script setup>
import { computed } from 'vue'
import { useGlobalStore } from '@/stores/global.store'
import { getTldPrice, getFullTldPrice } from '@/utils/das'
import { formatPrice } from '@/utils'
import { getCustomRoundedNumber } from '@/utils/checkout'

const props = defineProps({
  tldItem: {
    type: Object,
    required: true
  },
  currentActiveTld: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['onSetActive', 'onSetUnactive'])

const $global = useGlobalStore();

const generalConfig = computed(() => $global.getConfigBySlug('general'));

const price = getTldPrice(props.tldItem)

const fullPrice = getFullTldPrice(props.tldItem)

const discount = computed(() => {
  return props.tldItem.customer_discount > 0 ? getCustomRoundedNumber(props.tldItem.customer_discount) : 0
})

const isActive = computed(() => {
  if(!props.currentActiveTld) {
    return false
  }
  return props.tldItem.tl_name === props.currentActiveTld.tl_name
})

const handleSetActive = () => {
  if(!isActive.value) {
    return emit('onSetActive', props.tldItem)
  }
}

const handleSetUnactive = () => {
  if(isActive.value) {
    return emit('onSetUnactive')
  }
}
</script>

<template>
  <ul
    @click="handleSetActive"
    class="b-domain-list-section__list-row"
    :class="{ 'b-domain-list-section__list-row--active': isActive }"
  >
    <li class="b-domain-list-section__list-row__item b-domain-list-section__list-row__item--name">
      .{{ tldItem.tl_name }}
    </li>
    <li class="b-domain-list-section__list-row__item b-domain-list-section__list-row__item--country">
      {{ tldItem.country }}
    </li>
    <li class="b-domain-list-section__list-row__item b-domain-list-section__list-row__item--price">
      <div
        v-if="discount"
        class="b-domain-list-section__list-row__item-badge"
      >
        -{{ discount }}%
      </div>
      <div class="b-domain-list-section__list-row__item-price">
        <span class="b-domain-list-section__list-row__item-price__new">{{ formatPrice(price, generalConfig.prices_round) }} <span class="b-domain-list-section__list-row__item-price__new__currency">{{ generalConfig.currency_symbol }}</span></span>
        <span v-if="discount" class="b-domain-list-section__list-row__item-price__old">{{ formatPrice(fullPrice, generalConfig.prices_round) }} {{ generalConfig.currency_symbol }}</span>
      </div>
      <div @click.stop="isActive ? handleSetUnactive() : handleSetActive()" class="b-domain-list-section__list-row__item-action">
        <svg class="b-domain-list-section__list-row__item-action__icon-inactive" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90814 4.41076C7.23358 4.08533 7.76121 4.08533 8.08665 4.41076L13.0867 9.41076C13.4121 9.7362 13.4121 10.2638 13.0867 10.5893L8.08665 15.5893C7.76121 15.9147 7.23358 15.9147 6.90814 15.5893C6.5827 15.2638 6.5827 14.7362 6.90814 14.4108L11.3189 10L6.90814 5.58928C6.5827 5.26384 6.5827 4.7362 6.90814 4.41076Z" fill="#315EED"/>
        </svg>
        <svg class="b-domain-list-section__list-row__item-action__icon-active" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5867 4.41076C15.9121 4.7362 15.9121 5.26384 15.5867 5.58928L5.58665 15.5893C5.26121 15.9147 4.73358 15.9147 4.40814 15.5893C4.0827 15.2638 4.0827 14.7362 4.40814 14.4108L14.4081 4.41076C14.7336 4.08533 15.2612 4.08533 15.5867 4.41076Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.40814 4.41076C4.73358 4.08533 5.26121 4.08533 5.58665 4.41076L15.5867 14.4108C15.9121 14.7362 15.9121 15.2638 15.5867 15.5893C15.2612 15.9147 14.7336 15.9147 14.4081 15.5893L4.40814 5.58928C4.0827 5.26384 4.0827 4.7362 4.40814 4.41076Z" fill="white"/>
        </svg>
      </div>
    </li>
  </ul>
</template>
