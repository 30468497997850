export const videoHandler = {
  init() {
    const videoWrappers = [...document.querySelectorAll(".b-video")]
    if(!videoWrappers.length) return

    videoWrappers.forEach(videoWrapper => {
      const video = videoWrapper.querySelector(".b-video__video iframe");
      const thumbnail = videoWrapper.querySelector(".b-video__thumbnail");
      const thumbnailOverlay = videoWrapper.querySelector(".b-video__thumbnail-overlay");
      const playButton = videoWrapper.querySelector(".b-video__play-button");
      
      playButton.addEventListener("click", () => {
        thumbnail.style.display = "none";
        thumbnailOverlay.style.display = "none";
        playButton.style.display = "none";
        video.src = video.getAttribute('data-src')
        video.style.display = "block"
      });
    })
  }
}