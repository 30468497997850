<script setup>
import debounce from 'lodash.debounce'
import { ref, computed, watch, toRefs } from 'vue'
import NxtInput from '@/components/shared/NxtInput.vue'
import NxtChoices from '@/components/shared/NxtChoices.vue'
import config from '@/config';
import { foxentryPhoneValidation } from '@/services'

const props = defineProps(['modelValue', 'isDisabled', 'background', 'isRequired', 'v'])
const { modelValue, isRequired, isDisabled } = toRefs(props)

const emit = defineEmits('update', 'onValidateHandler')

const prefix = ref(props.modelValue ? props.modelValue.split(' ')[0] : config.phonePrefix)
const number = ref(props.modelValue ? props.modelValue.split(' ')[1] : '')

const fullNumberWithPrefix = computed(() => {
  return `${prefix.value ?? ''} ${number.value ?? ''}`
})

const updateNumber = debounce((event) => {
  number.value = event?.target?.value
  emit('update', fullNumberWithPrefix.value)
}, 1000)

const updatePrefix = (value) => {
  prefix.value = value
  emit('update', fullNumberWithPrefix.value)
  if(number.value) {
    onValidateHandler()
  }
}

watch(modelValue, modelValue => {
  const [prefixPart, ...numberPart] = modelValue.split(' ')
  if(prefix) {
    prefix.value = prefixPart
  }
  if(number) {
    number.value = numberPart.join(' ')
  }
})

const isFocused = ref(false)

const isValidating = ref(false)
const isValid = ref(null)

const isFilled = computed(() => {
  return prefix.value && number.value
})

const onFocus = () => {
  isFocused.value = true
}

const onBlur = async () => {
  isFocused.value = false
  onValidateHandler()
}

const onValidateHandler = async () => {
  isValidating.value = true
  emit('onValidating', isValidating.value)
  try {
    const { data } = await foxentryPhoneValidation(
      {
        prefix: prefix.value,
        number: number.value
      },
      {
        validationType: 'extended',
        formatNumber: false
      }
    )

    isValid.value = data?.response?.result?.isValid;
    
  } catch (error) {
    console.error(error)
    return false
  } finally {
    emit('onValidateHandler', isValid.value === null ? false : isValid.value)
    isValidating.value = false
    emit('onValidating', isValidating.value)
  }
}
</script>

<template>
  <div
    class="b-form__group"
    :class="{
      'b-form__group--filled': isFilled,
      'b-form__group--focused': isFocused,
      'b-form__group--error': isValid !== null && !isValid && !isValidating || customError || v?.$errors.length,
      'b-form__group--disabled': isDisabled || isValidating,
      'b-form__group--background-light-blue': props.background && props.background === 'light-blue',
    }"
  >
    <div class="b-form-select b-form-select--phone">
      <NxtChoices
        choiceType="phone" 
        :modelValue="prefix || '+420'" 
        :defaultSelected="prefix" 
        @onChange="updatePrefix"
      /> 
      <NxtInput
        v-model="number" 
        @input="updateNumber" 
        @onFocus="onFocus"
        @onBlur="onBlur"
        :v="v"
        v-bind="$attrs" 
        :hideErrors="true" 
        :is-disabled="isDisabled || v?.$pending && v?.$dirty"
        :background="props.background" 
        :is-required="isRequired"
      />
      <template v-if="isValidating"><div class="input-loader"></div></template>
    </div>
    <div v-if="v?.$errors.length" class="b-form__helper-text">{{ v.$errors[0].$message }}</div>
    <div v-if="isValid !== null && !isValid" class="b-form__helper-text">{{ $t('validations.phoneInvalid') }}</div>
  </div>
</template>
