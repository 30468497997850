<script setup>
import { defineEmits } from 'vue'
import Button from '@/stories/Button.vue';
import BlockButtonWrapper from "@/stories/block-button-wrapper/BlockButtonWrapper.vue";
import { closeAllModals } from '@/utils'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: '',
  },
  description: {
    type: String,
    default: ''
  },
  primaryButton: {
    type: String,
    required: '',
  },
  primaryButtonColor: {
    type: String,
    default: 'blue',
    validator: color => ['blue', 'orange'].includes(color)
  },
  secondaryButton: {
    type: String,
    required: '',
  },
  secondaryButtonColor: {
    type: String,
    default: 'blue',
    validator: color => ['blue', 'orange'].includes(color)
  },
  severity: {
    type: String,
    default: 'none',
    validator: severity => ['none', 'warning'].includes(severity)
  },
  textAlign: {
    type: String,
    default: 'none',
    validator: textAlign => ['none', 'left'].includes(textAlign)
  },
})

const emit = defineEmits(['onPrimaryAction', 'onSecondaryAction'])

const handlePrimaryAction = () => {
  emit('onPrimaryAction')
  closeAllModals()
}

const handleSecondaryAction = () => {
  emit('onSecondaryAction')
  closeAllModals()
}
</script>

<template>
  <div class="modal fade" :id="props.id" tabindex="-1" :aria-labelledby="props.id" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-md">
      <div class="modal-content">
        <div :class="`modal-body b-action-modal-body b-action-modal-body--${props.severity} b-action-modal-body--text-${props.textAlign}`">
          <Button 
            variant="link" 
            color="gray" 
            size="medium" 
            iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>'
            class="modal-close" 
            @click="closeAllModals"
          />
          <h4 v-if="props.title" class="b-action-modal-body__title">{{ props.title }}</h4>
          <div v-if="props.description" class="b-action-modal-body__desc" v-html="props.description"></div>
          <BlockButtonWrapper desktopPosition="center" mobilePosition="center">
            <Button 
              v-if="props.primaryButton" 
              variant="primary" 
              size="big" 
              :color="props.primaryButtonColor" 
              :label="props.primaryButton" 
              @click.prevent.stop="handlePrimaryAction" 
            />
            <Button 
              v-if="props.secondaryButton" 
              variant="outline" 
              size="big" 
              :color="props.secondaryButtonColor" 
              :label="props.secondaryButton" 
              @click.prevent.stop="handleSecondaryAction" 
            />
          </BlockButtonWrapper>
        </div>
      </div>
    </div>
  </div>
</template>