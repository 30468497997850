import {AxiosError} from 'axios'

export class FetchData {
  successCallback = undefined;
  errorCallback = undefined;
  callback = undefined;
  param = undefined;


  onSuccess(callback) {
    this.successCallback = callback
    return this
  }

  onError(callback) {
    this.errorCallback = callback
    return this
  }

  onCompletion() {
    this.callback = callback
    return this
  }

  withParam(param) {
    this.param = param
    return this
  }

  async call(callFn, ...args) {
    try {
      const response = await callFn(...args)
      if (this.successCallback) {
        this.successCallback(response)
      }
      return response
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
        }

        if (this.errorCallback) {
          this.errorCallback(error)
        }
      }
      throw error
    } finally {

      if (this.callback) {
        this.callback()
      }
    }
  }
}

export const fetchData = new FetchData()
