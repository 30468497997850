<script setup>
import { useDasStore } from '@/stores/das.store';
import {inject} from 'vue';
const componentId = inject('componentId')
const dasStore = useDasStore();
</script>

<template>
  <div v-if="!dasStore.getDas(componentId).transfer" class="b-das__only-free b-form__group">
    <div class="b-form-switch">
      <input type="checkbox" :id="`onlyFree_${componentId}`" v-model="dasStore.getDas(componentId).onlyFree">
      <label class="b-form-switch__label" :for="`onlyFree_${componentId}`">
        <span class="text-gray2">{{ $t('only_free') }}</span>
      </label>
    </div>
  </div>
</template>
