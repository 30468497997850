import latinize from "./latinize";
import config from "../config";
import { useCheckoutStore } from "@/stores/checkout.store";

export const ignoreWww = (domain) => {
  return domain.replace(/^www\./, "");
};

export const ignoreTld = (domain) => {
  const parts = domain.split('.');
  if (parts.length < 2) return domain;
  if (parts.length > 2 && parts[parts.length - 2].length <= 3) {
    return parts.slice(0, -2).join('.');
  }
  return parts.slice(0, -1).join('.');
};

export const getClearDomain = (domain) => {
  domain = ignoreWww(domain);
  domain = ignoreTld(domain);
  return domain;
}

export const isValidDomain = (domain) => {
  const regex = /^(www\.)?([a-z0-9-]+(\.[a-z0-9-]+)*)$/i;
  return regex.test(domain);
};

export const getTldFromDomainInput = (domain) => {
  domain = ignoreWww(domain.toLocaleLowerCase());
  const parts = domain.split('.');
  if (parts.length < 2) return '';
  if (parts.length > 2 && parts[parts.length - 2].length <= 3) {
    return parts.slice(-2).join('.');
  }
  return parts[parts.length - 1];
};


export const formatCurrency = (value, precision = 2) => {
  const options = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: precision,
  }

  // ošetrenie pri čísle -0
  if (value === 0) {
    value = 0
  }

  return new Intl.NumberFormat("sk-SK", options).format(value)
}

export const formatPrice = (value, precision = 2) => {
  value = Number(value)
  return value.toFixed(precision).replace('.', ',')
}

export const prepareTldList = (tlds, preferred_domain) => {
  // convert preferred domain list (string) to array
  preferred_domain = preferred_domain.split(',').map(item => item.trim());

  // convert object to array
  const tldArray = Object.keys(tlds).map(key => ({ tld: key, ...tlds[key] }));

  return customTldSort(tldArray, preferred_domain);
}

export function customTldSort(array, preferredOrder) {
  return array.sort((a, b) => {
      const aIndex = preferredOrder.indexOf(a.tld);
      const bIndex = preferredOrder.indexOf(b.tld);
      if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex; // Ak sa obe hodnoty nachádzajú v preferovanom zozname, zoradíme ich podľa ich poradia.
      } else if (aIndex !== -1) {
          return -1; // Ak sa hodnota 'a' nachádza v preferovanom zozname, ale hodnota 'b' nie, 'a' príde skôr.
      } else if (bIndex !== -1) {
          return 1; // Ak sa hodnota 'b' nachádza v preferovanom zozname, ale hodnota 'a' nie, 'b' príde skôr.
      } else {
          return a.tld.localeCompare(b.tld); // Ak sa ani 'a' ani 'b' nenachádzajú v preferovanom zozname, zoradíme ich lexikograficky.
      }
  });
}

export function parseOpts(opts) {
  try {
    if (!opts) {
      return null;
    }
    if (typeof opts === 'object') {
      return opts;
    }

    let jsonString = opts.replace(/'/g, '"');

    jsonString = jsonString
      .replace(/&quot;/g, '"')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&nbsp;/g, ' ')
      .replace(/&apos;/g, "'");

    return JSON.parse(jsonString);
  } catch (error) {
    console.log('Error parsing opts', error);
    return null;
  }
}

export function closeAllModals() {
  const modals = document.querySelectorAll('.modal.show');
  modals.forEach((modal) => {
    const bootstrapModal = bootstrap.Modal.getInstance(modal);
    if (bootstrapModal) {
      bootstrapModal.hide();
    }
  });
}

export function openModal(modalId) {
  const modal = document.getElementById(modalId);
  if (!modal) {
    return;
  }
  const bootstrapModal = new bootstrap.Modal(modal);
  bootstrapModal.show();
}

export function updateQueryParam(param, value) {
  const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set(param, value);
  const newUrl = baseUrl + '?' + urlSearchParams.toString();
  window.history.replaceState({ path: newUrl }, '', newUrl);
}

export function getQueryParam(param) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(param);
}

export function simpleClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const removeAccents = (text) => {
  return latinize(text)
}

export function generateDatalayerDasItem(domain, config, obj, additional = {}) {
  const prefixId = additional?.prefixId || "";
  const prefix = additional?.prefix || "";
  return {
    "affiliation": "Webglobe",
    "coupon": "",
    "currency": `${config.currency_code}`,
    "item_list_id": `${prefixId}check_avalability`,
    "item_list_name": `${prefix}Check availability`,
    "index": domain.index,
    "item_id": `Registration_${domain.name}`,
    "item_name": `registration ${domain.tld}`,
    "item_brand": "Webglobe",
    "item_category": "registration",
    "item_category_2": "12 months",
    "item_category_3": "",
    "item_category_4": "",
    "item_category_5": domain.available ? "available" : domain.failed ? "failed to verify availability" : "occupied",
    "item_variant": "12 months",
    "discount": domain.discount ? Math.round(domain.discount * 100) / 100 : 0,
    "price": Math.round(domain.price * 100) / 100,
    ...obj
  };
};

export function generateDatalayerItem(objOrg, additionalOrg = {}) {
  const obj = simpleClone(objOrg);
  const additional = simpleClone(additionalOrg);

  const period = additional?.period || 12
  const base_price = Math.round((Number(additional?.base_price) || 0) * 100) / 100;
  const final_price = Math.round((Number(additional?.final_price) || 0) * 100) / 100;
  const discount = Math.round(base_price - final_price)

  const defaultObj = {
    affiliation: 'Webglobe',
    coupon: '',
    currency: `${config.currency_code}`,
    item_list_id: '',
    item_list_name: '',
    index: '',
    item_id: '',
    item_name: '',
    item_brand: 'Webglobe',
    item_category: '',
    item_category_2: `${period} months`,
    item_category_3: '',
    item_category_4: '',
    item_category_5: 'available',
    item_variant: `${period} months`,
    discount,
    price: final_price,
    quantity: 1,
  };

  const filteredObj = Object.keys(defaultObj).reduce((acc, key) => {
    if (obj.hasOwnProperty(key)) {
      acc[key] = obj[key];
    } else {
      acc[key] = defaultObj[key];
    }
    return acc;
  }, {});

  return filteredObj;
}

export function generateDatalayerCheckoutItems(data) {
  const items = simpleClone(data.checkout_items)
  let result = []
  if (!items) {
    return [];
  }

  function process(items) {
    for (let item of items) {
      if (!item.in_basket) continue

      const it = generateDatalayerCheckoutItem(item)
      if (it) {
        result.push(it)
      }

      if (item.subitems) {
        process(item.subitems);
      }
    }
  }

  process(items);
  return result;

}

export function generateDatalayerCheckoutItem(itemObj) {
  const item = simpleClone(itemObj);
  if (!item.in_basket) return null
  const $checkout = useCheckoutStore();
  const key = `${item.update_name}_${item.update_type}`;
  let internalInfo = $checkout.data.internal_info?.[key];
  if (!internalInfo) {
    internalInfo = {
      sourceId: 'checkout',
    };
  }
  const registration = item.update_type === 'registration' ? ` ${item.update_name.split('.')[1]}` : '';

  const obj = {
    item_list_id: internalInfo.sourceId,
    item_list_name: internalInfo.sourceId,

    item_id: `${item.update_pack}_${item.update_name}`,
    item_name: `${item.update_pack}${registration}`,
    item_category: item.category,
    licence_quantity: item.licences_count || undefined,
  }
  if (item.licences_count && item.licences_count > 0) {
    obj.licence_quantity = Number(item.licences_count)
  }

  return generateDatalayerItem(obj, {
    period: item.period,
    final_price: item.price_final,
    base_price: item.price_base,
  })
}
