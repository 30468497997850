<script setup>
// core imports
import { provide, ref, watch} from "vue";
import {storeToRefs} from 'pinia';
import {useGlobalStore} from "@/stores/global.store"
import { usePricingTableStore } from "@/stores/pricing-table.store";
import {parseOpts} from "@/utils"
// import components
import PricingTable from "@/components/pricing-table/PricingTable.vue";


const props = defineProps({
  configId: String | Number,
  componentId: String | Number,
  opts: String | undefined
})
const conf = ref(null)

const globalStore = useGlobalStore();
const pricingTableStore = usePricingTableStore();

const {ready, tldList} = storeToRefs(globalStore)
watch(ready, (val) => {
  conf.value = globalStore.getConfig(props.configId)
  const opts = parseOpts(props.opts)
  pricingTableStore.setConfig(conf.value, opts)
  pricingTableStore.setTldList(tldList)
})

// provide config data to all child components
provide('config', conf)
provide('componentId', props.componentId)
</script>

<template>
  <PricingTable />
</template>

<style scoped></style>
