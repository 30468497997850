<script setup>
const props = defineProps(['title', 'type', 'isReversed', 'isActive'])
const emit = defineEmits(['onDirectionUp', 'onDirectionDown'])

function toggleDirection() {
  emit(props.isReversed ? 'onDirectionDown' : 'onDirectionUp')
}
</script>

<template>
  <li
   :class="`b-domain-list-section__list-header__item b-domain-list-section__list-header__item--${type}`"
    @click="toggleDirection"
  >
    <p class="b-domain-list-section__list-header__item__title" v-html="title"></p>
    <div class="b-domain-list-section__list-header__item__sorter">
      <svg
        class="b-domain-list-section__list-header__item__sorter__up"
        :class="{ 'b-domain-list-section__list-header__item__sorter__up--active': isActive && isReversed }"
        xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none"
      >
        <path opacity="0.5" d="M4.31193 0.419635C4.40869 0.476266 4.49117 0.558145 4.55205 0.658008L7.21863 5.03289C7.28562 5.14269 7.32409 5.27033 7.32994 5.40219C7.3358 5.53404 7.30882 5.66515 7.25188 5.78155C7.19494 5.89794 7.11018 5.99524 7.00664 6.06308C6.90311 6.13091 6.78467 6.16673 6.66398 6.16671H1.33081C1.21012 6.16673 1.09168 6.13091 0.988147 6.06308C0.884608 5.99524 0.799849 5.89794 0.742911 5.78155C0.685973 5.66515 0.658992 5.53404 0.664848 5.40219C0.670703 5.27033 0.709175 5.14269 0.776159 5.03289L3.44275 0.658008C3.50363 0.558145 3.5861 0.476266 3.68286 0.419635C3.77962 0.363004 3.88766 0.333374 3.9974 0.333374C4.10713 0.333374 4.21518 0.363004 4.31193 0.419635Z" fill="white"/>
      </svg>
      <svg
        class="b-domain-list-section__list-header__item__sorter__down"
        :class="{ 'b-domain-list-section__list-header__item__sorter__down--active': isActive && !isReversed }"
        xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none"
      >
        <path opacity="0.5" d="M4.31193 0.419635C4.40869 0.476266 4.49117 0.558145 4.55205 0.658008L7.21863 5.03289C7.28562 5.14269 7.32409 5.27033 7.32994 5.40219C7.3358 5.53404 7.30882 5.66515 7.25188 5.78155C7.19494 5.89794 7.11018 5.99524 7.00664 6.06308C6.90311 6.13091 6.78467 6.16673 6.66398 6.16671H1.33081C1.21012 6.16673 1.09168 6.13091 0.988147 6.06308C0.884608 5.99524 0.799849 5.89794 0.742911 5.78155C0.685973 5.66515 0.658992 5.53404 0.664848 5.40219C0.670703 5.27033 0.709175 5.14269 0.776159 5.03289L3.44275 0.658008C3.50363 0.558145 3.5861 0.476266 3.68286 0.419635C3.77962 0.363004 3.88766 0.333374 3.9974 0.333374C4.10713 0.333374 4.21518 0.363004 4.31193 0.419635Z" fill="white"/>
      </svg>
    </div>
  </li>
</template>
