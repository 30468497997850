class CookiesSettings {
  constructor() {
  }

  init = () => {
    this.manageCookies()
    window.webGlobe?.wgEvent('manageCookies', this.manageCookies)

    const cookieSettingsTriggerElements = [...document.querySelectorAll('a[href="#cookie-settings"]')]
    cookieSettingsTriggerElements.forEach(el => {
      el.addEventListener('click', event => {
        event.preventDefault()
        window.webGlobe.wgCall('openCookieSettings')
      })
    })
  }

  manageCookies = () => {
    const storagePermittedCookiesKey = 'permittedCookies'
    const storagePermittedCookies = localStorage.getItem(storagePermittedCookiesKey)
    if (!storagePermittedCookies) {
      return this.defaultGoogleConsent()
    }

    try {
      const cookies = JSON.parse(storagePermittedCookies)
      for (const key in cookies) {
        if (key === 'default') continue
        let value = cookies[key].permitted
        if (key === 'analytics_storage') {
          value = true
        }
        this.consentGranted(key, value)
      }
    } catch (e) {
      console.error(e);
      this.defaultGoogleConsent()
    }
  }

  defaultGoogleConsent = () => {
    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'granted'
    });
  }

  consentGranted(key, value) {
    gtag('consent', 'update', {
      [key]: value ? 'granted' : 'denied'
    });
  }
}

export const cookies = new CookiesSettings();
