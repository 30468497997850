<script setup>
import { defineProps } from 'vue';
import { useCheckoutStore } from '@/stores/checkout.store';

const $checkout = useCheckoutStore()

const changeStep = (step) => {
  if(step <= $checkout.furthestStep) {
    $checkout.setStep(step)
  }
};
</script>

<template>
  <div class="l-page-steps">
    <div class="b-checkout-steps">
      <div class="container">
        <div class="b-checkout-steps__inner">
          <template v-for="step in 4" :key="step">
            <button
              @click="changeStep(step)"
              class="b-checkout-steps__item"
              :class="{
                'b-checkout-steps__item--active': $checkout.step === step,
                'b-checkout-steps__item--done': $checkout.step > step,
                'b-checkout-steps__item--disabled': $checkout.furthestStep < step
              }"
            >
              <div class="b-checkout-steps__item-number">{{ step }}</div>
              <span class="b-checkout-steps__item-title d-md-none">{{ $t(`checkout.stepsMobile.step${step}`) }}</span>
              <span class="b-checkout-steps__item-title d-none d-md-block">{{ $t(`checkout.steps.step${step}`) }}</span>
            </button>
            <div v-if="step !== 4" class="b-checkout-steps__divider"></div>
          </template>
        </div>
      </div>
      <div class="loader">
        <div v-if="$checkout.showLoader" class="loading-bar"></div>
      </div>
    </div>
  </div>
</template>
