<script setup>
import { computed } from 'vue'

const props = defineProps({
  label: {
    type: String,
    default: 'Label'
  },
  checkboxRight: {
    type: Boolean,
    default: false
  },
  checked: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMsg: {
    type: String,
    default: ''
  },
  inputId: {
    type: String,
    default: 'checkbox'
  },
  v: {
    type: Object,
    default: () => ({})
  },
  isRequired: {
    type: Boolean,
    default: false
  },
  showError: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['onChange'])

const onChange = (e) => {
  emit('onChange', e.target.checked)
}

const computedLabel = computed(() => {
  if(props.isRequired) {
    return `${props.label}&nbsp;*`
  }
  return props.label
})
</script>

<template>
  <div
    class="b-form__group"
    :class="{
      'b-form__group--error': v?.$errors?.length,
      'b-form__group--checkbox-right': checkboxRight
    }
  ">
    <div class="b-form-checkbox">
      <input @change="onChange" type="checkbox" :checked="checked" :id="inputId" />
      <label class="b-form-checkbox__label" :for="inputId"><span v-if="label" v-html="computedLabel"></span></label>
    </div>
    <div v-if="v?.$errors?.length && props.showError" class="b-form__helper-text">{{ v.$errors[0].$message }}</div>
    <div v-if="errorMsg" class="b-form__helper-text">{{ errorMsg }}</div>
  </div>
</template>
