import { setCssVariable } from "@/utils/dom"

export const headerHeight = {
  init() {
    const mainMenu = document?.querySelector('.b-main-menu');
    const mainMenuHeight = mainMenu?.getBoundingClientRect()?.height

    if(mainMenuHeight) {
      setCssVariable({ 
        element: document.documentElement, 
        variable: '--header-height-for-top-offset',
        value: mainMenuHeight
      })
    }
  }
}