<script setup>
import { ref } from 'vue'
import { useElementVisibility } from '@vueuse/core'
import { useCheckoutStore } from '@/stores/checkout.store'
import SummaryItem from './SummaryItem.vue';
import { watch } from 'vue'

const props = defineProps({
  isSummaryMobileSticky: {
    type: Boolean,
    default: true
  }
})

const $checkout = useCheckoutStore()

const scrollableProductList = ref(null)
const isScrollableProductListVisible = useElementVisibility(scrollableProductList)

const isTouched = ref(false)
const isScrolled = ref(false)

const handleScroll = () => {
  if(!props.isSummaryMobileSticky) {
    return
  }

  if(!isTouched.value) {
    return
  }

  if(!isScrolled.value) {
    isScrolled.value = true
  }
}

watch(isScrollableProductListVisible, isVisible => {
  if(!isVisible) {
    isScrolled.value = false
  }
})
</script>

<template>
  <div 
    @scroll="handleScroll"
    @touchstart="isTouched = true"
    @touchend="isTouched = false"
    ref="scrollableProductList" 
    class="b-checkout-summary__product-list"
    :class="{'b-checkout-summary__product-list--extended': props.isSummaryMobileSticky && isScrolled }"
  >
    <div 
      v-for="(product, idx) in $checkout.getCheckoutItems" :key="`product_${idx}`"
      class="b-checkout-summary__product-wrapper"
      :class="{ 'b-checkout-summary__product-wrapper--last': idx === $checkout.getCheckoutItems.length - 1 }"
    >
      <SummaryItem :product="product" />
    </div>
  </div>
</template>