<script setup>
import MyButton from '../Button.vue'
import { computed } from 'vue';
const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  overtitle: {
    type: String,
    default: null,
  },
  overtitleTag: {
    type: String,
    default: 'span',
  },
  title: {
    type: String,
    default: null,
  },
  titleTag: {
    type: String,
    default: 'h2',
  },
  titleSize: {
    type: String,
    default: 'none',
  },
  titleContainerSize: {
    type: String,
    default: 'none',
  },
  text: {
    type: String,
    default: '',
  },
  textSize: {
    type: String,
    default: 'normal'
  },
  textContainerSize: {
    type: String,
    default: 'none',
  },
  alignContent: {
    type: String,
    default: 'none',
  },
  alignContentMobile: {
    type: String,
    default: 'none',
  },
  justifyContent: {
    type: String,
    default: 'none',
  },
  justifyContentMobile: {
    type: String,
    default: 'none',
  },
  buttonsOnlyStorybook: {
    type: String,
    default: 'none',
  },
  buttonsVisibility: {
    type: String,
    default: 'both', // mobile, desktop, both
  },
  isDivided: {
    type: String,
    default: 'none',
  },
});

const block = computed(() => {
  return {
    class: [
      'wp-block-b-title-banner',
    ],
    // styles: {
    //   paddingTop: `var(--wp--preset--spacing--${props.paddingTop})`,
    //   paddingBottom: `var(--wp--preset--spacing--${props.paddingBottom})`
    // }
  }
});

const bannerObj = computed(() => {
  return {
    class: [
      'b-title-banner',
      props?.isDivided !== 'none' && 'b-title-banner--is-divided',
    ],
  }
});

const overtitleObj = computed(() => {
  return {
    class: [
      'b-title-banner__overtitle',
      props.titleContainerSize !== 'none' && `b-title-banner__title--width-${props.titleContainerSize}`,
      props.alignContent !== 'none' &&  `b-title-banner__title--align-${props.alignContent}`,
      props.alignContentMobile !== 'none' &&  `b-title-banner__title--align-mobile-${props.alignContentMobile}`,
      props.justifyContent !== 'none' &&  `b-title-banner__title--justify-${props.justifyContent}`,
      props.justifyContentMobile !== 'none' &&  `b-title-banner__title--justify-mobile-${props.justifyContentMobile}`,
    ],
  }
});

const titleObj = computed(() => {
  return {
    class: [
      'b-title-banner__title',
      props.titleSize !== 'none' && props.titleSize,
      props.titleContainerSize !== 'none' && `b-title-banner__title--width-${props.titleContainerSize}`,
      props.alignContent !== 'none' &&  `b-title-banner__title--align-${props.alignContent}`,
      props.alignContentMobile !== 'none' &&  `b-title-banner__title--align-mobile-${props.alignContentMobile}`,
      props.justifyContent !== 'none' &&  `b-title-banner__title--justify-${props.justifyContent}`,
      props.justifyContentMobile !== 'none' &&  `b-title-banner__title--justify-mobile-${props.justifyContentMobile}`,
    ],
  }
});

const textObj = computed(() => {
  return {
    class: [
      `b-title-banner__text`,
      `b-title-banner__text--size-${props.textSize}`,
      props.textContainerSize !== 'none' && `b-title-banner__text--width-${props.textContainerSize}`,
      props.alignContent !== 'none' && `b-title-banner__text--align-${props.alignContent}`,
      props.alignContentMobile !== 'none' && `b-title-banner__text--align-mobile-${props.alignContentMobile}`,
      props.justifyContent !== 'none' &&  `b-title-banner__text--justify-${props.justifyContent}`,
      props.justifyContentMobile !== 'none' &&  `b-title-banner__text--justify-mobile-${props.justifyContentMobile}`,
    ],
  }
});

const buttonsObj = computed(() => {
  return {
    class: [
      'b-title-banner__buttons',
      props.buttonsVisibility !== 'none' && `b-title-banner__buttons--show-${props.buttonsVisibility}`,
      props.alignContent !== 'none' && `b-title-banner__buttons--align-${props.alignContent}`,
      props.alignContentMobile !== 'none' && `b-title-banner__buttons--align-mobile-${props.alignContentMobile}`,
    ],
  }
});

const svgIcon = window.webGlobe.global.getSvgDataForImageSrc(props.icon);

const IconTitle = computed(() => {
  return props.icon ? `<img src="${svgIcon}" class="b-title-banner__icon" /><div>` + props.title + `</div>` : `<div>` + props.title + `</div>`;
});
</script>

<template>
  <div :class="block.class" :style="block.styles">
    <div :class="bannerObj.class">
      <template v-if="isDivided !== 'none'">
        <div class="row align-items-center g-10">
          <div
            :class="{
              'col-md': isDivided === 'half',
              'col-md-8': isDivided === 'quarter',
              'col-md-9': isDivided === 'third'
            }"
          >
             <component
              :is="overtitleTag"
              :class="overtitleObj.class"
              v-html="overtitle"
            ></component>
            <component
              :is="titleTag"
              :class="titleObj.class"
              v-html="IconTitle"
            ></component>
          </div>
          <div
            :class="{
              'col-md': isDivided === 'half',
              'col-md-4': isDivided === 'quarter',
              'col-md-3': isDivided === 'third'
            }"
            class="d-flex flex-column gap-20">
            <p v-if="text" :class="textObj.class" v-html="text"></p>

            <div v-if="buttonsOnlyStorybook !== 'none'" :class="buttonsObj.class">
              <MyButton v-if="buttonsOnlyStorybook === 'one' || buttonsOnlyStorybook === 'two'" label="Button 1" />
              <MyButton v-if="buttonsOnlyStorybook === 'two'" label="Button 2" variant="secondary" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <component
          :is="overtitleTag"
          :class="overtitleObj.class"
          v-html="overtitle"
        ></component>
        <component
          :is="titleTag"
          :class="titleObj.class"
          v-html="IconTitle"
        ></component>
        <p v-if="text" :class="textObj.class" v-html="text"></p>

        <div v-if="buttonsOnlyStorybook !== 'none'" :class="buttonsObj.class">
          <MyButton v-if="buttonsOnlyStorybook === 'one' || buttonsOnlyStorybook === 'two'" label="Button 1" />
          <MyButton v-if="buttonsOnlyStorybook === 'two'" label="Button 2" variant="secondary" />
        </div>
      </template>
    </div>
  </div>
</template>
