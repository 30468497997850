import { createApp, h } from 'vue';

import WgGlobal from './WgGlobal.vue';
import WgAuth from './WgAuth.vue';
import WgDomainFinder from './WgDomainFinder.vue';
import WgAuthAndCart from './WgAuthAndCart.vue';
import WgPricingTable from './WgPricingTable.vue'
import WgCheckout from './WgCheckout.vue'
import WgDomainList from './WgDomainList.vue'
import WgWhoisFinder from './WgWhoIsFinder.vue'

export function registerWgGlobalComponent() {
  const globalWgCompDiv = document.createElement("div");
  globalWgCompDiv.setAttribute("id", "wg-global");
  globalWgCompDiv.setAttribute("wg-comp", "wg-global");
  if (window.document.body) {
    window.document.body.appendChild(globalWgCompDiv);
  }
}

export function selectComponent(comp) {
  switch(comp) {
    case 'wg-global':
      return WgGlobal;
    case 'wg-auth':
      return WgAuth;
    case 'wg-das':
      return WgDomainFinder;
    case 'wg-auth-cart':
      return WgAuthAndCart;
    case 'wg-pricing-table':
      return WgPricingTable;
    case 'wg-checkout':
      return WgCheckout;
    case 'wg-domain-list':
      return WgDomainList;
    case 'wg-whois':
      return WgWhoisFinder;
    default:
      throw new Error(`Unknown component id: ${comp}`);
  }
}

export function renderApp(el, myOpts = null) {
  const wgComp = el.getAttribute('wg-comp');
  const wgOpts = el.getAttribute('wg-opts');

  const opts = myOpts ? myOpts : wgOpts ? wgOpts : null;
  const Component = selectComponent(wgComp);

  el.innerHTML = '';

  const app = createApp({
    render: () => h(Component, {
      configId: el.getAttribute('config-id'),
      componentId: el.getAttribute('id'),
      opts,
    })
  });
  return app;
}


