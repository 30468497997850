const eventHandlers = {};

export function wgEvent(eventName, callback) {
  if (!eventHandlers[eventName]) {
    eventHandlers[eventName] = [];
  }
  eventHandlers[eventName].push(callback);
}

export function wgCall(eventName, eventData) {
  const event = new CustomEvent("wgEvent", {
    detail: {
      name: eventName,
      data: eventData
    }
  });
  window.dispatchEvent(event);
}

function wgListener() {
  window.addEventListener("wgEvent", (event) => {
    const handlers = eventHandlers[event.detail.name];
    if (handlers) {
      handlers.forEach(handler => handler(event.detail.data));
    }
  });
}

function registerCustomEvents() {
  window.webGlobeEvent = wgEvent;
  window.webGlobeCall = wgCall;
}

function disableIOSTextFieldZoom() {
  const isIOS = () => {
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  }
  if (!isIOS()) { return }
  const element = document.querySelector('meta[name=viewport]')
  if (element !== null) {
    let content = element.getAttribute('content')
    let scalePattern = /maximum\-scale=[0-9\.]+/g
    if (scalePattern.test(content)) {
      content = content.replace(scalePattern, 'maximum-scale=1.0')
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ')
    }
    element.setAttribute('content', content)
  }
}

export const global = {
  init() {
    wgListener()
    registerCustomEvents()
    disableIOSTextFieldZoom()
  },
  events() {

  },
  getSvgDataForImageSrc(svg) {
    // Konverzia SVG do Base64 a zakódovanie potrebného prefixu pre atribút `src`
    return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svg)))}`;
  },


}

export default () => {
  //global.init();
}
