<script setup>
import BlockButtonWrapper from "@/stories/block-button-wrapper/BlockButtonWrapper.vue"
import MyButton from "@/stories/Button.vue"
import PaymentItem from "./PaymentItem.vue"
import { useCheckoutStore } from "@/stores/checkout.store";
import { t } from "@/i18n-instance"
import { useDataLayer } from "@/dataLayer.js";

defineProps({
  title: {
    type: String,
    default: t('checkout.payments.title')
  }
})

const $checkout = useCheckoutStore()

$checkout.setPaymentsData()

const { changePaymentInfo } = useDataLayer()

const handleSaveCachedPaymentType = () => {
  changePaymentInfo($checkout.selectedPaymentType, $checkout.selectedPaymentTypeCache)
  $checkout.selectedPaymentType = $checkout.selectedPaymentTypeCache
  $checkout.step4EditMode = null
}

const handleDismissCachedPaymentType = () => {
  $checkout.selectedPaymentTypeCache = $checkout.selectedPaymentType
  $checkout.step4EditMode = null
}
</script>

<template>
  <div class="b-checkout-content b-checkout-content__payments-wrapper">
    <div class="b-checkout-content__header">
      <div class="b-checkout-content__header-title">
        <h2 class="b-checkout-content__header-title-h2 h6">{{ title }}</h2>
      </div>
    </div>
    <div class="b-checkout-content__content">
      <PaymentItem v-for="payment in $checkout.getPaymentTypes" :key="payment" :payment="payment" />
    </div>
    <div v-if="$checkout.step4EditMode === 'payment'" class="b-checkout-content__footer mt-1">
      <BlockButtonWrapper desktopPosition="left" mobilePosition="center">
        <MyButton @click="handleSaveCachedPaymentType" :label="t('save_data')" variant="primary" color="blue" size="big" />
        <MyButton @click="handleDismissCachedPaymentType" :label="t('dismiss')" variant="outline" color="blue" size="big" data-bs-dismiss="modal" />
      </BlockButtonWrapper>
    </div>
  </div>
</template>
