const hostname = window.location.hostname;
const isLocalhost = hostname === 'localhost' || hostname.includes('nextio.dev');

const instance = (() => {
  if(isLocalhost) {
    return 'localhost'
  }
  if(['www.webglobe.cz', 'wy.pt'].includes(hostname)) {
    return 'cz'
  }
  if(['www.webglobe.sk', 'sk.wy.pt'].includes(hostname)) {
    return 'sk'
  }
})()

const apiWp = isLocalhost ? 'https://wy.pt/wp-json/v2/wg' : '/wp-json/v2/wg';

const servers = [
  'Webglobe_RRP_VMS_LIN',
  'WEBG_RRP_VPS_CENTRUM_LIN',
  'WEBG_RRP_VPS_CENTRUM_WIN',
  'WEBG_VMS_LIN',
  'WEBG_VPS_LIN',
  'WEBG_VPS_WIN'
]

const config = {
  localhost: {
    apiWp,
    servers,
    formName: 'webglobe_cz',
    currency: 'CZK',
    currency_code: 'CZK', // alternative name (like config from api)
    language: 'cz',
    phonePrefix: '+420',
    invoiceLanguage: 'cs',
    invoiceState: 'cz',
    webAdmin: 'https://admin.webglobe.cz/auth/login',
    vmsNamePlaceholder: 'noname',
  },
  cz: {
    apiWp,
    servers,
    formName: 'webglobe_cz',
    currency: 'CZK',
    currency_code: 'CZK', // alternative name (like config from api)
    language: 'cz',
    phonePrefix: '+420',
    invoiceLanguage: 'cs',
    invoiceState: 'cz',
    webAdmin: 'https://admin.webglobe.cz/auth/login',
    vmsNamePlaceholder: 'noname',
  },
  sk: {
    apiWp,
    servers,
    formName: 'webglobe_sk',
    currency: 'EUR',
    currency_code: 'EUR', // alternative name (like config from api)
    language: 'sk',
    phonePrefix: '+421',
    invoiceLanguage: 'sk',
    invoiceState: 'sk',
    webAdmin: 'https://admin.webglobe.sk/auth/login',
    vmsNamePlaceholder: 'noname',
  },
}

const configObj = config[instance] || config['localhost']

export default configObj
