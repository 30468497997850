<script setup></script>

<template>
  <div class="b-das__invalid-input">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none" viewBox="0 0 33 33"><path fill="#F03A49" fill-rule="evenodd" d="M16.5 4.125c-6.835 0-12.375 5.54-12.375 12.375 0 6.834 5.54 12.375 12.375 12.375 6.834 0 12.375-5.54 12.375-12.375S23.335 4.125 16.5 4.125ZM1.375 16.5c0-8.353 6.772-15.125 15.125-15.125S31.625 8.147 31.625 16.5 24.853 31.625 16.5 31.625 1.375 24.853 1.375 16.5Z" clip-rule="evenodd"/><path fill="#F03A49" fill-rule="evenodd" d="M21.597 11.403a1.375 1.375 0 0 1 0 1.944l-8.25 8.25a1.375 1.375 0 0 1-1.944-1.944l8.25-8.25a1.375 1.375 0 0 1 1.944 0Z" clip-rule="evenodd"/><path fill="#F03A49" fill-rule="evenodd" d="M11.403 11.403a1.375 1.375 0 0 1 1.944 0l8.25 8.25a1.375 1.375 0 0 1-1.944 1.944l-8.25-8.25a1.375 1.375 0 0 1 0-1.944Z" clip-rule="evenodd"/></svg>
    </div>
    <div class="text">
      <div class="title">{{ $t('invalid.title') }}</div>
      <div class="desc">{{ $t('invalid.desc') }}</div>
    </div>
  </div>
</template>
