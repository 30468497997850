
export const collapseBouncer = {
  init() {
    const collapses = [...document.querySelectorAll("#packages .b-collapse-wrapper")]

    collapses
      .forEach(collapseWrapper => {
        const innerCollapseAnchor = document.querySelector(`.b-collapse-wrapper a[href="#${collapseWrapper.id}"]`)

        if(!innerCollapseAnchor) {
          return
        }

        const packagesSection = collapseWrapper.closest('#packages')

        innerCollapseAnchor.addEventListener('click', _event => {
          const scrollBackTimeOut = setTimeout(() => {
            packagesSection.scrollIntoView({ behavior: 'smooth' })
            return scrollBackTimeOut
          }, 350)
        })
      })
  }
}