<script setup>
import { ref, defineProps, computed, reactive, onMounted, onUnmounted } from 'vue'
import { v4 as uuidv4 } from 'uuid';
import Button from '@/stories/Button.vue';
import Checkbox from '@/stories/form/Checkbox.vue'
import NxtInput from '@/components/shared/NxtInput.vue'
import NxtInputCompanyAutocomplete from '@/components/shared/NxtInputCompanyAutocomplete.vue'
import NxtInputPhone from '@/components/shared/NxtInputPhone.vue'
import NxtInputCountries from '@/components/shared/NxtInputCountries.vue'
import { createBillProfile, updateBillProfile } from '@/services'
import { useCheckoutStore } from '@/stores/checkout.store';
import { useVuelidate } from '@vuelidate/core'
import { useToggle } from '@vueuse/core'
import {   
  required, 
  email, 
  numeric, 
  alphaNum,
  startsWithCZ,
  startsWithSK,
  city,
  maxLength, 
  minLength,
  companyName,
  firstName,
  lastName,
  getCustomerPostcodeValidation
} from '@/utils/i18n-validators'
import config from '@/config'
import { getFormProfileFromProfile, getRegistrantFromProfile } from '@/utils/checkout'
import { getCountryPrefix } from '@/utils/choices'
import { setCssVariable } from '@/utils/dom'

const props = defineProps({
  profile: {
    type: Object,
    default: () => ({})
  },
  isAddingNewProfile: {
    type: Boolean,
    default: false
  },
  isReadOnly: {
    type: Boolean,
    default: false
  }
});

const formBackground = computed(() => {
  if(props.profile.isActive || props.isAddingNewProfile) {
    return 'light-blue'
  }

  return ''
})

const emit = defineEmits(['onClose'])

const $checkout = useCheckoutStore();

const [isEditCompany, toggleIsEditCompany] = useToggle(false)

const isProfileCompany = computed(() => {
  return props?.profile?.customer_ico
  || props?.profile?.customer_dic
  || props?.profile.customer_icdph
})

const isAddingTemporaryRegistrant = computed(() => $checkout.step4EditMode === 'domainOwner')

const form = reactive(getFormProfileFromProfile(props?.profile));

const isUsingIcDph = ref(
  config.language === 'sk' ||
  props?.profile?.customer_state === 'SK' ||
  props?.profile?.invoice_state === 'SK'
)

const rules = computed(() => {
  return {
    customer_company_name: isEditCompany.value ? { 
      required, 
      minLength: minLength(3),
      maxLength: maxLength(128),
      companyName
    } : {},
    customer_ico: isEditCompany.value ? (() => {
      if(['SK', 'CZ'].includes(form.customer_state)) {
        return {
          required, 
          numeric
        }
      }

      return {
        required
      }
    })() : {},
    customer_dic: isEditCompany.value ? (() => {
      if(isUsingIcDph.value) {
        return {
          required,
          numeric
        }
      }
      if(form.customer_state === 'CZ') {
        return {
          required,
          startsWithCZ
        }
      }
      return {
        required
      }
    })() : {},
    customer_icdph: isEditCompany.value ? (() => {
      if(
        form.customer_icdph.trim() &&
        isUsingIcDph.value
      ) {
        return {
          startsWithSK
        }
      }
      return {}
    })() : {},
    customer_first_name: !isEditCompany.value ? {
      required,
      firstName,
      minLength: minLength(2),
      maxLength: maxLength(128),
    } : {},
    customer_last_name: !isEditCompany.value ? {
      required,
      lastName,
      minLength: minLength(2),
      maxLength: maxLength(128),
    } : {},
    customer_phone: {
      required,
    },
    customer_street: { 
      required, 
      alphaNum,
      minLength: minLength(2),
      maxLength: maxLength(128),
    },
    customer_town: { 
      required, 
      city,
      minLength: minLength(2),
      maxLength: maxLength(128),
    },
    customer_postcode: getCustomerPostcodeValidation(form.customer_state),
    customer_state: { 
      required 
    },
    customer_email: { 
      required, email 
    },
  }
  }
)

const v$ = useVuelidate(rules, form)

const formDataMerged = computed(() => {
  const customerName = isEditCompany.value || isProfileCompany.value
    ? form.customer_company_name
    : `${form.customer_first_name} ${form.customer_last_name}`

  const customerPostCodeSanitized = form.customer_postcode
    .replace(' ', '')
    .replace('-', '')

  const customerPhoneSanizited = form.customer_phone
    .replace(' ', '')

  return {
    customer_name: customerName,
    customer_street: form.customer_street,
    customer_town: form.customer_town,
    customer_postcode: customerPostCodeSanitized,
    customer_state: form.customer_state,
    customer_province: form.customer_province,
    customer_ico: form.customer_ico,
    customer_dic: form.customer_dic,
    customer_icdph: form.customer_icdph,
    customer_email: form.customer_email,
    customer_phone: customerPhoneSanizited,
    customer_post_name: customerName,
    customer_post_street: form.customer_street,
    customer_post_town: form.customer_town,
    customer_post_postcode: customerPostCodeSanitized,
    customer_post_state: form.customer_state,
    customer_post_province: form.customer_province,
  }
})

const isFormSubmitting = ref(false)

const isPhoneValid = ref(null)
const isPhoneValidating = ref(false)

const handleSubmitProfile = async () => {
  const isVuelidate = await v$.value.$validate()

  if(!isVuelidate || isPhoneValid.value !== null && !isPhoneValid.value || isPhoneValidating.value) {
    return
  }

  if(isAddingTemporaryRegistrant.value) {
    if(props.profile?.temporaryIDregistrant) {
      $checkout.listOfTemporaryRegistrants = $checkout.listOfTemporaryRegistrants.map(registrantProfile => {
        if(registrantProfile.temporaryIDregistrant === props.profile?.temporaryIDregistrant) {
          return {
            temporaryIDregistrant: registrantProfile.temporaryIDregistrant,
            ...getRegistrantFromProfile(form)
          }
        }
        return registrantProfile
      })
      emit('onClose')
      return
    }

    $checkout.listOfTemporaryRegistrants = [
      ...$checkout.listOfTemporaryRegistrants,
      {
        temporaryIDregistrant: uuidv4(),
        ...getRegistrantFromProfile(form)
      }
    ]
    emit('onClose')
    return
  }

  try {
    isFormSubmitting.value = true

    const { data } = await (
      props.isAddingNewProfile
        ? createBillProfile(formDataMerged.value)
        : updateBillProfile(props.profile?.IDbillprofile, formDataMerged.value)
    );

    await $checkout.getBillingProfiles()
    $checkout.setProfileAsActive(data.data)

    emit('onClose')

    isFormSubmitting.value = false

  } catch (error) {
    console.error(error);
  }
};

const foxentrySource = computed(() => form?.customer_state?.toLowerCase() || config.language)

const onFillDataHandler = (data) => {
  isUsingIcDph.value = data.country === 'SK'

  form.customer_company_name = data.name
  form.customer_ico = data.registrationNumber
  form.customer_dic = isUsingIcDph.value ? data.taxNumber : data.vatNumber
  form.customer_icdph = isUsingIcDph.value ? data.vatNumber ?? '' : ''
  form.customer_street = data.addressOfficial.data.streetWithNumber
  form.customer_town = data.addressOfficial.data.city
  form.customer_postcode = data.addressOfficial.data.zip
  form.customer_state = data.country.toUpperCase()

  const isCustomerPhoneDirty = form.customer_phone.split(' ').filter(Boolean).length
  form.customer_phone = !isCustomerPhoneDirty ? getCountryPrefix(data.country.toUpperCase()) : form.customer_phone
}

const onSourceChange = (newSource) => {
  form.customer_state = newSource.toUpperCase()
  isUsingIcDph.value = newSource.toUpperCase() === 'SK'
}

function changeCountries(selected) {
  isUsingIcDph.value = selected === 'SK'
  return form.customer_state = selected
}

const registerWrapper = ref(null)

const setRegisterWrapperWidthCssVariable = () => {
  setCssVariable({ 
    element: registerWrapper.value, 
    variable: '--regster-wrapper-width', 
    value: registerWrapper.value.getBoundingClientRect().width 
  })
}

onMounted(() => {
  setRegisterWrapperWidthCssVariable()
  window.addEventListener('resize', setRegisterWrapperWidthCssVariable)
})

onUnmounted(() => {
  window.removeEventListener('resize', setRegisterWrapperWidthCssVariable)
})
</script>

<template>
  <div ref="registerWrapper" class="row g-10 g-md-15">
    <div v-if="props.isAddingNewProfile && !props?.profile?.isMain" class="col-12 pb-5 px-0">
      <Checkbox @change="toggleIsEditCompany()" inputId="userProfileFormIsCompany" :label="$t('auth.register.isCompany')" />
    </div>
    <template v-if="isEditCompany || isProfileCompany || props?.profile?.invoice_companyid">
      <div class="px-md-0">
        <NxtInputCompanyAutocomplete
          v-model="form.customer_company_name"
          :typeObj="'name'"
          :source="foxentrySource"
          @onFillData="onFillDataHandler"
          @onSourceChange="onSourceChange"
          :label="$t('auth.register.form.invoice_name')"
          :v="v$.customer_company_name"
          :background="formBackground"
          :is-disabled="isReadOnly"
          :is-required="!isReadOnly && isEditCompany"
          :is-full="true"
        />
      </div>
      <div class="col-md-6 ps-md-0">
        <NxtInputCompanyAutocomplete
          v-model="form.customer_ico"
          typeObj="registrationNumber"
          :source="foxentrySource"
          @onFillData="onFillDataHandler"
          @onSourceChange="onSourceChange"
          :label="$t('auth.register.form.invoice_companyid')"
          :v="v$.customer_ico"
          :background="formBackground"
          :is-disabled="isReadOnly"
          :is-required="!isReadOnly && isEditCompany"
        />
      </div>
      <div v-if="isUsingIcDph" class="col-md-6 pe-md-0">
        <NxtInput
          :is-disabled="isReadOnly"
          v-model="form.customer_dic"
          :background="formBackground"
          :label="$t('auth.register.form.invoice_tax')"
          :v="v$.customer_dic"
          :is-required="true"
        />
      </div>
      <div :class="isUsingIcDph ? 'px-md-0' : 'col-md-6 pe-md-0'">
        <NxtInputCompanyAutocomplete
          v-if="isUsingIcDph"
          v-model="form.customer_icdph"
          :typeObj="'vatNumber'"
          :source="foxentrySource"
          @onFillData="onFillDataHandler"
          @onSourceChange="onSourceChange"
          :label="$t('auth.register.form.invoice_vat')"
          :background="formBackground"
          :is-disabled="isReadOnly"
          :v="v$.customer_icdph"
          :is-right-aligned="!isUsingIcDph"
        />
        <NxtInputCompanyAutocomplete
          v-else
          v-model="form.customer_dic"
          :typeObj="'vatNumber'"
          :source="foxentrySource"
          @onFillData="onFillDataHandler"
          @onSourceChange="onSourceChange"
          :label="$t('auth.register.form.invoice_tax')"
          :background="formBackground"
          :is-disabled="isReadOnly"
          :v="v$.customer_dic"
          :is-right-aligned="true"
          :is-required="true"
        />
      </div>
    </template>
    <div v-if="isAddingNewProfile ? !isEditCompany : !isProfileCompany" class="col-md-6 ps-md-0">
      <NxtInput 
        :is-disabled="isReadOnly" 
        v-model="form.customer_first_name" 
        :background="formBackground" 
        :label="$t('auth.register.form.first_name')" 
        :v="v$.customer_first_name"
        :is-required="!isReadOnly"
        :foxentry="'name'"
        
        :is-value-capitalized="true"
      />
    </div>
    <div v-if="isAddingNewProfile ? !isEditCompany : !isProfileCompany" class="col-md-6 pe-md-0">
      <NxtInput 
        :is-disabled="isReadOnly" 
        v-model="form.customer_last_name" 
        :background="formBackground" 
        :label="$t('auth.register.form.last_name')" 
        :v="v$.customer_last_name" 
        :is-required="!isReadOnly"
        :foxentry="'surname'"
        
        :is-value-capitalized="true"
      />
    </div>
    <div class="col-md-6 ps-md-0">
      <NxtInput 
        :is-disabled="isReadOnly" 
        v-model="form.customer_email" 
        :background="formBackground" 
        :label="$t('auth.register.form.contact_email')" 
        :v="v$.customer_email"  
        :is-required="!isReadOnly"
      />
    </div>
    <div class="col-md-6 pe-md-0">
      <NxtInputPhone
        :model-value="form.customer_phone"
        :is-disabled="isReadOnly"
        :v="v$.customer_phone"
        @update="fullNumberWithPrefix => form.customer_phone = fullNumberWithPrefix"
        @on-validate-handler="isValid => isPhoneValid = isValid"
        @on-validating="isValidating => isPhoneValidating = isValidating"
        :background="formBackground"
        :label="$t('auth.register.form.contact_phone')"
        :is-required="!isReadOnly"
      />
    </div>
    <div class="col-md-12 px-md-0">
      <NxtInput 
        :is-disabled="isReadOnly" 
        v-model="form.customer_street" 
        :background="formBackground" 
        :label="$t('auth.register.form.invoice_street')" 
        :v="v$.customer_street" 
        :is-required="!isReadOnly" 
        :is-value-capitalized="true"
      />
    </div>
    <div class="col-8 col-md-7 ps-md-0">
      <NxtInput 
        :is-disabled="isReadOnly" 
        v-model="form.customer_town" 
        :background="formBackground" 
        :label="$t('auth.register.form.invoice_town')" 
        :v="v$.customer_town" 
        :is-required="!isReadOnly" 
        :is-value-capitalized="true"
      />
    </div>
    <div class="col-4 col-md-5 pe-md-0">
      <NxtInput 
        :is-disabled="isReadOnly" 
        v-model="form.customer_postcode" 
        :background="formBackground" 
        :label="$t('auth.register.form.invoice_postcode')" 
        :v="v$.customer_postcode"
        :is-required="!isReadOnly" 
      />
    </div>
    <div class="col-md-12 px-md-0">
      <NxtInputCountries
        :variant="isReadOnly ? 'disabled' : ''"
        :model-value="form.customer_state"
        :background="formBackground"
        :v="v$.customer_state"
        @update:model-value="changeCountries"
      />
    </div>
    <div class="col-md-12 pt-5 px-md-0">
      <div class="row gx-10 d-md-flex">
        <Button
          v-if="isReadOnly"
          @click.stop="$emit('onClose')"
          size="medium"
          color="blue"
          :label="$t('close')"
        />
        <template v-else>
          <div class="col-8 pe-5 col-md-4 col-xl-3">
            <Button
              @click.stop="handleSubmitProfile"
              variant="primary"
              size="medium"
              color="blue"
              :label="$t('save_data')"
              :loading="isFormSubmitting"
              :disabled="isFormSubmitting"
              class="w-100 w-md-auto"
            />
          </div>
          <div class="col-4 ps-5 col-md-3 col-xl-2">
            <Button
              @click.stop="$emit('onClose')"
              variant="outline"
              size="medium"
              color="blue"
              :label="$t('dismiss')"
              :disabled="isFormSubmitting"
              class="w-100 w-md-auto"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>