<script setup>
import { watch,inject,ref,onMounted } from 'vue'
import { refDebounced } from '@vueuse/core'
import { usePricingTableStore } from '@/stores/pricing-table.store'
import IconSearch from './svg/Search.vue'

const config = inject('config')
const componentId = inject('componentId')

const pricingTableStore = usePricingTableStore()
</script>

<template>
  <input
    v-model="pricingTableStore.searchTldInput"
    class="domain-search"
    type="text"
    :placeholder="$t('pricingTable.input_search')"
    :disabled="pricingTableStore.selectedTld"
  />
  <button class="tld-button-search">
    <span>{{ $t('search_btn') }}</span>
    <IconSearch />
  </button>
</template>

<style lang="scss" scoped>
.domain-search {
  width: 100%;
  height: 56px;
  border: 0;
  background: none;
  padding: 0 16px;
  border-radius: 100px;
  border: 1px solid #B6B6B6;
  font-size: 16px;
  font-weight: 500;
  z-index: 9;
  outline: none !important;
  @include anim(border-color);
  @media (max-width: $md) {
    height: 46px;
    font-size: 16px;
    padding: 0 50px 0 16px;
  }
  &:focus {
    border: 1px solid $color-primary;
  }
}
.tld-button-search {
    border: 0;
    padding: 0;
    margin: 0;
    background: #02117F;
    color: #fff;
    padding: 0 24px;
    border-radius: 100px;
    font-size: 20px;
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 2px;
    @media (max-width: $md) {
      padding: 0 12px;
    }
    span {
      @media (max-width: $md) {
        display: none;
      }
    }
    svg {
      display: none;
      @media (max-width: $md) {
        display: block;
      }
    }
  }
</style>
