<script setup>
import { defineProps, computed } from 'vue'
import BlockButtonWrapper from '@/stories/block-button-wrapper/BlockButtonWrapper.vue';
import Button from '@/stories/Button.vue';
import PaymentInformation from '@/components/checkout/components/PaymentInformation.vue'
import { useCheckoutStore } from '@/stores/checkout.store'
const $checkout = useCheckoutStore()

const props = defineProps({
  subpage: {
    type: String,
    required: true
  },
  data: {
    type: Object,
    required: true
  }
});

const buttons = computed(() => {
  if(props.subpage === 'success-bank-transfer') {
    return [
      {
        href: props.data.main_content.link.url,
        target: props.data.main_content.link.target,
        variant: 'outline',
        color: 'blue',
        size: 'medium',
        label: props.data.main_content.link.title,
      }
    ]
  }
  if(props.subpage === 'generic-fail') {
    return [
      {
        variant: 'primary',
        color: 'blue',
        size: 'medium',
        label: props.data.main_content.button_text_1,
        loading: $checkout.loading,
        onClickHandler() {
          $checkout.handleSubmitOrder()
        }
      },
      {
        variant: 'outline',
        color: 'blue',
        size: 'medium',
        loading: $checkout.loading,
        label: props.data.main_content.button_text_2,
        'data-bs-toggle': 'modal',
        'data-bs-target': '#paymentAgainModal'
      }
    ]
  }
  return []
})

const isPaymentAgainPossible = computed(() => {
  return props.subpage === 'generic-fail'
})

const iconSuccess = window.webGlobe.global.getSvgDataForImageSrc('<svg width="211" height="211" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="105" cy="105" r="90" fill="#00D081"/><circle opacity=".5" cx="105.498" cy="105.498" r="104.498" stroke="#00D081" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="8 8"/><path fill-rule="evenodd" clip-rule="evenodd" d="M141.923 76.472a4.246 4.246 0 0 1 0 6.005L95.219 129.18a4.246 4.246 0 0 1-6.004 0l-21.23-21.228a4.247 4.247 0 0 1 6.005-6.005l18.227 18.227 43.701-43.702a4.247 4.247 0 0 1 6.005 0Z" fill="#fff"/></svg>');
const iconError = window.webGlobe.global.getSvgDataForImageSrc('<svg width="211" height="211" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="105" cy="105" r="90" fill="#F03A49"/><circle opacity=".5" cx="105.498" cy="105.498" r="104.498" stroke="#F03A49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="8 8"/><path fill-rule="evenodd" clip-rule="evenodd" d="M133.424 76.472a4.246 4.246 0 0 1 0 6.005l-50.95 50.949a4.246 4.246 0 0 1-6.004-6.004l50.949-50.95a4.247 4.247 0 0 1 6.005 0Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M76.47 76.472a4.246 4.246 0 0 1 6.005 0l50.949 50.95a4.245 4.245 0 1 1-6.005 6.004L76.47 82.476a4.246 4.246 0 0 1 0-6.004Z" fill="#fff"/></svg>');
const iconSuccessMobile = window.webGlobe.global.getSvgDataForImageSrc('<svg width="72" height="72" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="36" cy="36" r="29" fill="#00D081"/><circle opacity=".5" cx="36" cy="36" r="35" stroke="#00D081" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="6 6"/><path fill-rule="evenodd" clip-rule="evenodd" d="M47.61 27.058c.52.52.52 1.364 0 1.885L32.942 43.61c-.52.52-1.365.52-1.886 0l-6.666-6.667a1.333 1.333 0 1 1 1.885-1.886L32 40.781l13.724-13.723c.52-.521 1.365-.521 1.886 0Z" fill="#fff"/></svg>');
const iconErrorMobile = window.webGlobe.global.getSvgDataForImageSrc('<svg width="72" height="72" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="36" cy="36" r="29" fill="#F03A49"/><circle opacity=".5" cx="36" cy="36" r="35" stroke="#F03A49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="6 6"/><path fill-rule="evenodd" clip-rule="evenodd" d="M44.94 27.058c.52.52.52 1.364 0 1.885l-16 16a1.333 1.333 0 1 1-1.885-1.886l16-16c.52-.52 1.364-.52 1.885 0Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M27.055 27.058c.52-.521 1.364-.521 1.885 0l16 16a1.333 1.333 0 1 1-1.885 1.885l-16-16a1.333 1.333 0 0 1 0-1.886Z" fill="#fff"/></svg>');
</script>

<template>
  <div class="b-checkout-content b-checkout-content__ended-wrapper">
    <div class="b-checkout-content__ended">
      <div class="b-checkout-content__ended-content">
        <div class="b-checkout-content__ended-content-inner">
          <div class="b-checkout-content__ended-content-title-wrapper">
            <div class="b-checkout-content__ended-content-icon d-lg-none">
              <img v-if="props.data.success" :src="iconSuccessMobile" alt="">
              <img v-else :src="iconErrorMobile" alt="">
            </div>
            <h1 class="b-checkout-content__ended-content-title-text">{{ props.data.main_content.title }}</h1>
          </div>
          <div
            v-if="props.data.main_content?.text1"
            v-html="props.data.main_content.text1"
            class="b-checkout-content__ended-content-text"
          ></div>
        </div>
        <div v-if="props.data.main_content?.box_with_data_html" v-html="props.data.main_content.box_with_data_html"></div>
        <div v-if="props.data.main_content?.text2" class="b-checkout-content__ended-content-inner mt-10">
          <div
            v-html="props.data.main_content.text2"
            class="b-checkout-content__ended-content-text"
          ></div>
        </div>
        <div v-if="buttons.length" class="b-checkout-content__ended-content-action">
          <BlockButtonWrapper desktopPosition="left">
            <Button
              v-for="(button, idx) of buttons"
              :key="`${props.subpage}-button-${idx}`"
              v-bind="button"
              @click="button.onClickHandler ? button.onClickHandler() : null"
            />
          </BlockButtonWrapper>
        </div>
      </div>
      <div class="b-checkout-content__ended-content-icon d-none d-lg-block">
        <img v-if="props.data.success" :src="iconSuccess" alt="">
        <img v-else :src="iconError" alt="">
      </div>
    </div>
  </div>
  <Teleport v-if="isPaymentAgainPossible" to="body">
    <div class="modal modal-repeat-payment fade" id="paymentAgainModal" tabindex="-1" aria-labelledby="paymentAgainModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-sm">
        <div class="modal-content">
          <div class="modal-body">
            <Button class="modal-close" variant="link" color="gray" size="medium" data-bs-dismiss="modal" aria-label="Close" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>' />
            <div class="modal-checkout">
              <PaymentInformation :title="$t('checkout.step5.changePaymentMethod')" />
              <BlockButtonWrapper desktopPosition="right" mobilePosition="right">
                <Button @click="$checkout.handleSubmitOrder()" data-bs-dismiss="modal" :label="props.data.main_content.button_text_1" variant="primary" color="blue" size="medium" />
              </BlockButtonWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
