<script setup>
import { onMounted, computed } from 'vue';
import Steps from './components/Steps.vue';
import Summary from './components/Summary.vue';
import BillingInformation from './components/BillingInformation.vue';
import TitleBanner from '@/stories/_BASE/TitleBanner.vue';
import BlockButtonWrapper from "@/stories/block-button-wrapper/BlockButtonWrapper.vue";
import Button from '@/stories/Button.vue';
import { removeBillProfile } from '@/services'

import { useCheckoutStore } from '@/stores/checkout.store';

const $checkout = useCheckoutStore();

$checkout.getBillingProfiles()

onMounted(() => {
  window.webGlobe?.tooltip?.init()
})

const resetProfileIsToDelete = () => {
  $checkout.currentBillingProfileIDToDelete = null
}

const deleteProfileToDelete = async () => {
  await removeBillProfile($checkout.currentBillingProfileIDToDelete)
  $checkout.getBillingProfiles()
  if ($checkout.currentBillingProfileIDToDelete === $checkout.currentBillingProfileID) {
    $checkout.currentBillingProfileID = null
    $checkout.currentBillingProfileIDCache = null
  }
  $checkout.currentBillingProfileIDToDelete = null
}

const billingProfiles = computed(() => ([
  $checkout.billingProfiles?.main_profile ? {
    ...$checkout.billingProfiles?.main_profile,
    isMain: true
  } : {},
  ...$checkout?.billingProfiles?.bill_profiles?.length ? $checkout.billingProfiles.bill_profiles.map(profile => ({
    ...profile,
    isMain: false
  })) : []
]))
</script>

<template>
  <Steps :step="$checkout.step" />

  <main v-if="$checkout.billingProfiles" class="l-page-main">
    <section class="l-page-content l-page-content--checkout">
      <div class="container">
        <div class="row gy-20 gx-25 isolate">
          <div class="col">
            <div class="l-page-aside-sticky">

              <div class="d-flex flex-column gap-25">

                <BillingInformation
                  :title="$t('checkout.step2.selectBillingProfile')"
                  :profiles="billingProfiles"
                />

              </div>

            </div>
          </div>
          <div class="col-checkout-asside">
            <aside class="l-page-aside-sticky">
              <Summary />
            </aside>
          </div>
        </div>
      </div>
    </section>

    <div class="modal fade" id="deleteBillingModal" tabindex="-1" aria-labelledby="deleteBillingModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-md">
        <div class="modal-content">
          <div class="modal-body">
            <Button variant="link" color="gray" size="medium" class="modal-close" data-bs-dismiss="modal" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>' />
            <div class="modal-remove">
              <TitleBanner
                :title="$t('checkout.deleteProfile.title')"
                titleTag="h4"
                titleSize=""
                titleContainerSize=""
                :text="$t('checkout.deleteProfile.description')"
                textContainerSize=""
                alignContent="center"
                alignContentMobile="center"
                buttonsOnlyStorybook="none"
                buttonsVisibility="both"
                isDivided="none"
              />
              <BlockButtonWrapper desktopPosition="center" mobilePosition="center">
                <Button @click.stop="resetProfileIsToDelete" :label="$t('checkout.deleteProfile.dismiss')" variant="outline" color="blue" size="big" data-bs-dismiss="modal" />
                <Button @click.stop="deleteProfileToDelete" :label="$t('checkout.deleteProfile.deleteAccount')" variant="primary" color="blue" size="big" data-bs-dismiss="modal"  />
              </BlockButtonWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>
