import config from '@/config';
import { wpWg } from '@/axios.js'

export async function getIsServerNameValid(serverName) {
  return await wpWg.post('/general', {
    endpoint: '/order/checkFreeServerName',
    method: 'GET',
    content: {
      form_name: config.formName,
      ['server_name']: `${serverName}.virtual`
    }
  });
}