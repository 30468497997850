<script setup>
import { ref, computed, onMounted, inject } from 'vue'
import {formatPrice} from '@/utils'
import WgDomainFinder from '@/WgDomainFinder.vue'
import IconArrowOpen from './svg/ArrowOpen.vue'
import IconClose from './svg/Close.vue'
import {usePricingTableStore} from '@/stores/pricing-table.store'
import {useGlobalStore} from '@/stores/global.store'
import { getCustomRoundedNumber } from '@/utils/checkout'
const props = defineProps(['tld'])

const $global = useGlobalStore();

const pricingTableStore = usePricingTableStore();

const showDas = ref(false)

const getPrice = computed(() => {
  let price = props.tld.reg_price
  if (props.tld.customer_discount > 0) {
    price = props.tld.reg_customer_price
  }
  return price
})

const getDiscount = computed(() => {
  return props.tld.customer_discount > 0 ? getCustomRoundedNumber(props.tld.customer_discount) : 0;
})

const getFullPrice = computed(() => {
  return props.tld.reg_price
})

const preferred_domain = `{'preferred_domain':'${props.tld.tl_name}', 'autofocus':true}`

const selectTld = (tld) => {
  pricingTableStore.selectedTld = tld?.tl_name
}

const showHideDas = () => {
  showDas.value = !showDas.value
  if (showDas.value) {
    selectTld(props.tld)
  } else {
    selectTld(null)
  }
}

</script>
<template>
  <div class="tld-row">
    <div class="tld-row-item" @click="showHideDas">

      <div class="tld-col tld-col__name">
        .{{ props.tld.tl_name }}
        <!-- <span class="tld-info">
          <IconInfo />
          <span>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </span>
        </span> -->
      </div>
      <div class="tld-col tld-col__sale">
        <div v-if="getDiscount" class="tld-sale-badge">
          -{{ getDiscount }}%
        </div>
      </div>
      <div class="tld-col tld-col__price">
        <span v-if="getDiscount" class="tld-price-old">{{ formatPrice(getFullPrice) }}</span>
        <span class="tld-price-price">{{ formatPrice(getPrice) }}</span>
        <span class="tld-price-currency">{{ $global.currency_symbol }}</span>
      </div>
      <div class="tld-col tld-col__action">
        <span class="tld-row__action">
          <IconArrowOpen v-if="showDas" />
          <IconClose v-else />
        </span>
      </div>
    </div>

    <div v-if="showDas" class="das-wrap">
      <WgDomainFinder
        :configId="pricingTableStore.config.das_config_id"
        :componentId="`pricing-table-${props.tld.tl_name}`"
        :opts="preferred_domain"
        ></WgDomainFinder>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.tld-row {
  .tld-row-item {
    cursor: pointer;
    .tld-col {
      &.tld-col__name {
        font-weight: 700;
        .tld-info {
          position: relative;
          cursor: pointer;
          &:hover {
            span {
              visibility: visible;
              opacity: 1;
            }
          }
          span {
            width: 140px;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 12px;
            background: #000;
            color: #fff;
            border-radius: 4px;
            font-weight: 400;
            padding: 5px;
            visibility: hidden;
            opacity: 0;
            @include anim(all);
            &::after {
              content: '';
              width: 0;
              left: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #000;
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }
      }
      &.tld-col__sale {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 5px;
        .tld-sale-badge {
          background: #F6941A;
          border-radius: 100px;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          min-height: 28px;
          padding: 4px 12px;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: $md) {
            font-size: 12px;
            min-height: 24px;
            padding: 0 8px;
          }
        }
      }
      &.tld-col__price {
        display: flex;
        align-items: flex-end;
        gap: 4px;
        line-height: 1;
        .tld-price-old {
          font-size: 16px;
          text-decoration: line-through;
          color: #999;
          @media (max-width: $md) {
            font-size: 10px;
          }
        }
        .tld-price-price {
          font-weight: 700;
          @media (max-width: $md) {
            font-size: 14px;
          }
        }
        .tld-price-currency {
          font-size: 14px;
          font-weight: 800;
          @media (max-width: $md) {
            font-size: 10px;
          }
        }
      }
      &.tld-col__action {
        padding: 2px 4px 4px;
        display: flex;
        .tld-row__action {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          @media (max-width: $md) {
            justify-content: flex-end;
          }
        }
      }
    }
  }
  .das-wrap {
    max-width: 800px;
    margin: 10px auto;
    position: relative;
  }
}
</style>
