import * as validators from '@vuelidate/validators'
import { i18n } from "@/i18n-instance"
import { foxentryPhoneValidation } from '@/services'
import { removeAccents } from '.'

const { createI18nMessage } = validators

// Create your i18n message instance. Used for vue-i18n@9
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })

// helpers
const validateNameWithMessage = (validationMessagePath) => withI18nMessage(
  (name) => name.trim().split(' ').length === 1,
  { messagePath: () => validationMessagePath }
)

// validators
export const required = withI18nMessage(validators.required)
export const alpha = withI18nMessage(validators.alpha)
export const numeric = withI18nMessage(validators.numeric)
export const requiredIf = (value) => withI18nMessage(validators.requiredIf(value))
export const firstName = validateNameWithMessage('validations.firstNameTooLong')
export const lastName = validateNameWithMessage('validations.lastNameTooLong')
export const city = withI18nMessage(
  (city) => {
    const cityRegex = /^[A-Za-z0-9\- ]+$/;
    return cityRegex.test(removeAccents(city))
  },
  { messagePath: () => 'validations.city' }
)
export const companyName = withI18nMessage(
  (companyName) => {
    const companyNameRegex = /^[A-Za-z0-9\- \,\.\&]+$/;
    return companyNameRegex.test(removeAccents(companyName))
  },
  { messagePath: () => 'validations.companyName' }
)
export const email = withI18nMessage(validators.email)
export const phone = withI18nMessage(
  validators.helpers.withAsync(async (phoneNumber) => {
    const [prefix, ...number] = phoneNumber.split(' ')

    if(!prefix || !number.length) {
      return false
    }

    try {
      const { data } = await foxentryPhoneValidation(
        {
          prefix: prefix,
          number: number.join('')
        },
        {
          validationType: 'extended',
          formatNumber: false
        }
      )

      return data?.response?.result?.isValid;

    } catch (error) {
      console.error(error)
      return false
    }
  }),
  { messagePath: () => 'validations.phoneInvalid' }
)

// auth login
export const requiredLoginUsername = withI18nMessage(validators.required, { messagePath: () => 'validations.requiredLoginUsername' })

export const requiredLoginPassword = withI18nMessage(validators.required, { messagePath: () => 'validations.requiredLoginPassword' })

export const minLength = withI18nMessage(validators.minLength, { withArguments: true })

export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })

export const minValue = withI18nMessage(validators.minValue, { withArguments: true })

export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true })

export const alphaNum = (messagePath) => withI18nMessage(validators.alphaNum, {...messagePath ? { messagePath: () => messagePath } : {}})

const startsWithAlphaNum = (startsWith) => withI18nMessage(
  (inputString) => {
    if (!inputString || typeof inputString !== 'string') return false;
    return inputString.startsWith(startsWith);
  },
  {
    messagePath: () => `validations.startsWithAlphaNum`,
    messageParams: () => ({ prefix: startsWith })
  }
);
export const startsWithCZ = startsWithAlphaNum('CZ')
export const startsWithSK = startsWithAlphaNum('SK')

export const zipCode = withI18nMessage(
  (value) => {
    if (!validators.numeric.$validator(value)) {
      return false;
    }
    const minLengthValidator = validators.minLength(5);
    const maxLengthValidator = validators.maxLength(5);

    return minLengthValidator.$validator(value) && maxLengthValidator.$validator(value);
  },
  {
    messagePath: () => 'validations.zipCode',
  }
)

const zipCodeByCountryCode = (countryCode) => withI18nMessage(
  (inputString) => {
    let zipCodeRegex;
    if(countryCode === 'PL') {
      zipCodeRegex = /^\d{2}\-\d{3}$/;
    }
    return zipCodeRegex.test(inputString)
  },
  {
    messagePath: () => `validations.zipCodeByCountryCode.${countryCode}`,
  }
)

const isPolandZipcode = zipCodeByCountryCode('PL')

export const getCustomerPostcodeValidation = (countryCode) => {
  if(['CZ', 'SK'].includes(countryCode)) {
    return { 
      required, 
      zipCode
    }
  }
  if(['PL'].includes(countryCode)) {
    return {
      required,
      isPolandZipcode
    }
  }
  return {
    required
  }
}
