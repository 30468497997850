<script setup>
const props = defineProps(['tld', 'index'])

import { ref, shallowRef, computed, onMounted, inject, watch } from 'vue'
import { useDasStore } from '@/stores/das.store';
import { useCheckoutStore } from '@/stores/checkout.store';
import { useGlobalStore } from '@/stores/global.store';
import { formatPrice, simpleClone, generateDatalayerDasItem, generateDatalayerItem, getClearDomain} from '@/utils'
import SvgBin from './svg/SvgBin.vue'
import SvgRefresh from './svg/SvgRefresh.vue'
import SvgCart from './svg/SvgCart.vue'
import SvgX from './svg/SvgX.vue'
import SvgCheck from './svg/SvgCheck.vue'
import { watchDebounced } from '@vueuse/core';
import { getPremiumPrice } from '@/services'
import { getCustomRoundedNumber } from '@/utils/checkout'

const componentId = inject('componentId')

const dasStore = useDasStore();
const $checkout = useCheckoutStore();
const $global = useGlobalStore();

const loading = ref(false)
const checkAvailabilityError = ref(false)
const checkAvailabilityFnCounter = ref(0)
const addingToCart = ref(false)
const isVisible = ref(false)
const domain = dasStore.getDas(componentId).searchDomain + '.' + props.tld.tl_name
const cdasTld = dasStore.getDas(componentId).config?.cdas?.[props.tld.tl_name] || null
const loadingPremiumPrices = ref(false)

const generalConfig = computed(() => $global.getConfigBySlug('general'));

const verifiedDomain = computed(() => dasStore.getVerifiedDomain(componentId, domain))

const getPrice = computed(() => {
  if (verifiedDomain.value && verifiedDomain.value.premiumPrices) {
    return verifiedDomain.value.price
  }
  let price = props.tld.reg_price
  if (props.tld.customer_discount > 0) {
    price = props.tld.reg_customer_price
  }
  if (productFromCart.value && productFromCart.value.update_type === 'transfer') {
    price = props.tld.renew_customer_price
  }
  price = cdasTld?.price ?? price
  const minMonths = Math.min(...props.tld.supported_months.filter(month => month >= 12));
  return price / (minMonths / 12);
})

const getDiscount = computed(() => {
  if (verifiedDomain.value && verifiedDomain.value.premiumPrices) {
    return 0
  }
  let discount = cdasTld?.discount ?? props.tld.customer_discount
  if (productFromCart.value && productFromCart.value.update_type === 'transfer') {
    discount = 0
  }
  
  return discount > 0 ? getCustomRoundedNumber(discount) : 0
})

const getFullPrice = computed(() => {
  if (verifiedDomain.value && verifiedDomain.value.premiumPrices) {
    return verifiedDomain.value.price
  }
  const minMonths = Math.min(...props.tld.supported_months.filter(month => month >= 12));
  let price = props.tld.reg_price
  if (productFromCart.value && productFromCart.value.update_type === 'transfer') {
    price = props.tld.renew_price
  }
  return price / (minMonths / 12);
})

const productFromCart = computed(() => {
  return $checkout.getProduct({
    update_name: domain,
    update_type: 'registration'
  }) || $checkout.getProduct({
    update_name: domain,
    update_type: 'transfer'
  })
})



const checkAvailabilityFn = async () => {
  // if (checkAvailabilityFnCounter.value >= 2) return
  loading.value = true
  checkAvailabilityFnCounter.value++
  try {
    await dasStore.checkAvailability(componentId, domain, {
      ...simpleClone({
        tld: props.tld.tl_name,
        index: props.index,
        discount: Number(getFullPrice.value - props.tld.reg_customer_price),
        price: getFullPrice.value,
      })
    })
  } catch(error) {
    checkAvailabilityError.value = true
  } finally {
    loading.value = false
  }
}

async function addToCartCall() {
  try {
    const dasConfig = dasStore.getDas(componentId).config
    const item_pack = dasStore.isCdas(componentId) ? dasConfig.productPack : 'registration'
    await $checkout.addToCart({
      item_name: domain,
      item_pack,
      item_period: dasStore.isCdas(componentId) ? dasConfig.productPeriod : '12',
      item_count: dasStore.isCdas(componentId) ? dasConfig?.productCount : undefined,
      item_type: dasStore.isCdas(componentId) ? dasConfig.productType : 'registration',
      item_cpu: dasStore.isCdas(componentId) ? dasConfig.productCpu : undefined,
      item_ram: dasStore.isCdas(componentId) ? dasConfig.productRam : undefined,
      item_nvme: dasStore.isCdas(componentId) ? dasConfig.productNvme : undefined,
      additional_item: dasStore.isCdas(componentId) ? 'domain' : undefined,
      internal_info: {
        [`${domain}_${item_pack}`]: {sourceId: dasStore.isCdas(componentId) ? 'precheckout' : 'check_avalability'},
      },
    })
  } catch (error) {
    //
  } finally {
    addingToCart.value = false
  }
}

const isCheckSvgShown = shallowRef(false)

const addToCart = async () => {
  if (verifiedDomain.value.available && (!productFromCart.value || dasStore.isCdas(componentId) )) {
    addingToCart.value = true
    try {
      pushDatalayer()
      $checkout.addingToBasket = true
      if (dasStore.isCdas(componentId)) {
        return toCheckout()
      }
      isCheckSvgShown.value = true
      await addToCartCall()
      const removeCheckSvgTimeout = setTimeout(() => {
        isCheckSvgShown.value = false
        return clearTimeout(removeCheckSvgTimeout)
      }, 1400)
    } catch (error) {
      addingToCart.value = false
    }
  }
}

const toCheckout = () => {
  if (dasStore.isCdas(componentId)) {
    addToCartCall()
    $checkout.setStep(1)
  } else {
    dasStore.getDas(componentId).resultList = []
    $global.setRightPanelComp('WgCart')
    // return window.location.href = '/checkout'
  }
}

const removeFromCart = () => {
  if (productFromCart.value) {
    $checkout.removeFromBasket({index: productFromCart.value.id, location_source: 'Check avalability'})
  }
}

const cartOrCheckout = () => {
  if (!productFromCart.value || dasStore.isCdas(componentId)) {
    addToCart()
  } else {
    toCheckout()
  }
}

const tldItem = ref(null)
onMounted(async () => {
  const observer = new IntersectionObserver((entries) => {
    onElementVisibility(entries[0].isIntersecting);
  });

  observer.observe(tldItem.value);
})

function onElementVisibility(state) {
  isVisible.value = state;
}

watchDebounced(
  isVisible,
  () => {
    if (isVisible.value && !verifiedDomain.value) {
      checkAvailabilityFn()
    }
  },
  { debounce: 1000 },
)

watch(verifiedDomain, async () => {
  if (verifiedDomain.value && verifiedDomain.value.premium) {
    try {
      loadingPremiumPrices.value = true
      const data = await getPremiumPrice(dasStore.getDas(componentId).searchDomain, props.tld.tl_name)
      verifiedDomain.value.premiumPrices = data.data?.prices
      verifiedDomain.value.price = data.data?.prices?.register_customer
    } catch(error) {
      console.log('error', error);
    } finally {
      loadingPremiumPrices.value = false
    }
  }
})

const handleClick = () => {
  if (dasStore.isCdas(componentId)) return

  if (productFromCart.value) {
    removeFromCart()
  } else if (verifiedDomain.value?.available) {
    addToCart()
  }
}

function pushDatalayer(event = 'add_to_cart') {
  const prefix = dasStore.isCdas(componentId) ? 'Precheckout ' : ''
  const prefixId = dasStore.isCdas(componentId) ? 'precheckout_' : ''
  const items = []
  items.push(generateDatalayerDasItem(verifiedDomain.value, generalConfig.value, {quantity: 1}, {prefix, prefixId})) // TODO: datalayer - prerobit generateItems - dava zlu cenu

  let price = getPrice.value
  if(dasStore.isCdas(componentId)) {
    const dasConfig = dasStore.getDas(componentId).config
    items.push(generateDatalayerItem({
      item_list_id: 'precheckout',
      item_list_name: 'Precheckout',
      item_id: dasConfig.productPack,
      item_name: dasConfig.productPack,
      item_category: dasConfig.productType,
      quantity: dasConfig.productCount || 1,
    }, {
      period: dasConfig.productPeriod,
      base_price: $checkout.checkout0.productPrices?.base_price,
      final_price: $checkout.checkout0.productPrices?.final_price,
    }))
    price = $checkout.checkout0.productPrices?.final_price + price
  }
  window.webGlobe.dataLayer.sendData({
    event,
    location_source: `${prefix}Check avalability`,
    currency: generalConfig.value.currency_code,
    value: price,
    items,
  })
}
</script>

<template>
  <div ref="tldItem">
    <div v-if="((loading || !verifiedDomain) && !checkAvailabilityError) || loadingPremiumPrices ">
      <div class="b-das__tld-item">
        <div class="tld-inner">
          <div class="name">
            {{ dasStore.getDas(componentId).searchDomain }}<span>.{{ tld.tl_name }}</span>
          </div>
        </div>
        <div class="tld-right">
          <div class="action">
            <button class="b-button b-button--outline b-button--blue b-button--small b-button--if-label b-button--loading">
              <span class="b-button__label">Loading</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="checkAvailabilityError">
      <div class="b-das__tld-item">
        <div class="tld-inner">
          <div class="name">
            {{ dasStore.getDas(componentId).searchDomain }}<span>.{{ tld.tl_name }}</span>
          </div>
        </div>
        <div class="tld-right">
          <div class="action">
            <button v-if="checkAvailabilityFnCounter <= 1" @click="checkAvailabilityFn" class="refresh  d-none d-md-flex b-button b-button--outline b-button--blue b-button--small b-button--if-label">
              <div class="b-button__icon b-button__icon--left"><SvgRefresh /></div>
              <span class="b-button__label">{{ $t('refresh_btn') }}</span>
            </button>
            <button v-if="checkAvailabilityFnCounter <= 1" @click="checkAvailabilityFn" class="refresh  d-flex d-md-none b-button b-button--outline b-button--blue b-button--small">
              <div class="b-button__icon b-button__icon--left"><SvgRefresh /></div>
            </button>
            <div v-else class="default-text">{{ $t('check_availability_failed') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else @click="handleClick" class="b-das__tld-item" :class="{added : productFromCart && !addingToCart}">
      <div class="tld-inner">
        <div class="name">
          {{ dasStore.getDas(componentId).searchDomain }}<span>.{{ tld.tl_name }}</span>
        </div>
        <div class="badge d-none d-md-flex">
          <span v-if="dasStore.isUpsaleDomain(componentId, tld.tl_name)" class="b-badge b-badge--ninth">{{ $t('upsale') }}</span>
          <span v-if="verifiedDomain?.premium" class="b-badge b-badge--tenth">{{ $t('premium') }}</span>
        </div>
        <div class="right d-flex d-md-none">
          <div class="badge">
            <span v-if="dasStore.isUpsaleDomain(componentId, tld.tl_name)" class="b-badge b-badge--ninth">{{ $t('upsale') }}</span>
            <span v-if="verifiedDomain?.premium" class="b-badge b-badge--tenth">{{ $t('premium') }}</span>
          </div>
          <div v-if="productFromCart?.type === 'transfer'" class="transfer">
            {{ $t('transfer_domain') }}
          </div>
          <div class="right-inner">
            <div v-if="verifiedDomain.available && getDiscount" class="b-badge b-badge--eleventh me-auto d-none d-md-flex">
              -{{ getDiscount }}%
            </div>
            <div v-if="verifiedDomain.available || productFromCart" class="price">
              <span v-if="getDiscount" class="old">
              {{ formatPrice(getFullPrice, generalConfig.prices_round) }} {{ generalConfig.currency_symbol }}</span> {{ formatPrice(getPrice, generalConfig.prices_round) }} <span class="currency">{{ generalConfig.currency_symbol }}</span> <span class="year">{{ $t('price_year') }}</span>
            </div>
            <div v-else-if="!productFromCart" class="occupied">
              {{ $t('ocuppied') }}
            </div>
          </div>
        </div>
      </div>
      <div class="tld-right">
        <div class="right d-none d-md-flex">
          <div v-if="productFromCart?.type === 'transfer'" class="transfer">
            {{ $t('transfer_domain') }}
          </div>
          <div class="right-inner">
            <div v-if="verifiedDomain.available && getDiscount" class="b-badge b-badge--eleventh me-auto d-none d-md-flex">
              -{{ getDiscount }}%
            </div>
            <div v-if="verifiedDomain.available || productFromCart" class="price">
              <span v-if="getDiscount" class="old">
                {{ formatPrice(getFullPrice, generalConfig.prices_round) }} {{ generalConfig.currency_symbol }}</span> {{ formatPrice(getPrice, generalConfig.prices_round) }} <span class="currency">{{ generalConfig.currency_symbol }}</span> <span class="year">{{ $t('price_year') }}</span>
            </div>
            <div v-else-if="!productFromCart" class="occupied">
              {{ $t('ocuppied') }}
            </div>
          </div>
        </div>
        <div class="action">
          <button v-if="productFromCart && !addingToCart" class="remove b-button b-button--link b-button--big b-button--if-label d-none d-md-flex px-0" @click.stop="removeFromCart" :disabled="$checkout.isApiCall">
            <div class="b-button__icon b-button__icon--left"><SvgBin /></div>
            <span class="b-button__label">{{ $t('remove_btn') }}</span> <!-- v-if="verifiedDomain.available && !getDiscount" -->
          </button>
          <button v-if="productFromCart && !addingToCart" class="remove b-button b-button--primary b-button--azure b-button--small d-flex d-md-none px-0" @click.stop="removeFromCart" :disabled="$checkout.isApiCall">
            <div  class="b-button__icon b-button__icon--left">
              <SvgCheck v-if="isCheckSvgShown" />
              <SvgX v-else />
            </div>
          </button>

          <button v-if="verifiedDomain.available" class="add-added b-button b-button--outline b-button--blue b-button--small b-button--if-label" :class="{'b-button--loading': addingToCart}" @click.stop="addToCart" :disabled="$checkout.isApiCall">
            <div class="b-button__label">{{ !dasStore.isCdas(componentId) ? $t('add_btn') : $t('select_btn') }}</div>
          </button>
          <button v-else class="owner b-button b-button--outline b-button--blue b-button--small b-button--mobile-small b-button--if-label" @click.stop="dasStore.switchTransfer(componentId, dasStore.getDas(componentId).searchDomain, tld.tl_name)">
            <div class="b-button__label">{{ $t('owner_btn') }}</div>
          </button>

          <button class="add-cart add-cart-desktop b-button b-button--primary b-button--white b-button--small b-button--if-label" @click.stop="cartOrCheckout">
            <span class="b-button__label">{{ !dasStore.isCdas(componentId) ? $t('add_cart_btn') : $t('select_btn') }}</span>
          </button>
          <button class="add-cart add-cart-mobile b-button b-button--primary b-button--white b-button--small" :class="{'b-button--loading': addingToCart}" @click.stop="cartOrCheckout">
            <div class="b-button__icon b-button__icon--left"><SvgCart /></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
