<script setup>
import { ref, reactive, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@/utils/i18n-validators'
import { closeAllModals, openModal } from '@/utils';
import { submitFreeWebeditorAccount } from '@/services/free-webeditor.service.js'
import Button from '@/stories/Button.vue';
import Checkbox from '@/stories/form/Checkbox.vue'
import NxtInput from '@/components/shared/NxtInput.vue';

const isLoading = ref(false)

const responseData = ref(null)

const form = reactive({
  email: '',
  acceptance: ''
})

watch(form, _form => {
  v$.value.$reset()
}, { deep: true })

const rules = {
  email: { required, email },
  acceptance: {
    required,
    checked: v => v === true
  }
}

const v$ = useVuelidate(rules, form)

const onSubmit = async () => {
  const isVuelidate = await v$.value.$validate()

  if(!isVuelidate) {
    return
  }

  isLoading.value = true

  try {
    const successResponseData = await submitFreeWebeditorAccount(form.email)

    if(!successResponseData?.response?.data?.sucess && successResponseData?.response?.data?.error?.message) {
      responseData.value = {
        success: false,
        errorMessage: successResponseData?.response?.data?.error?.message
      }
      return
    }
    responseData.value = successResponseData
  } catch(e) {
    console.error(e)
    return e
  } finally {
    isLoading.value = false
  }
}

const resetModal = () => {
  isLoading.value = false
  responseData.value = null
  form.email = ''
  form.acceptance = ''
  v$.value.$reset()
}

onMounted(() => {
  window.webGlobe.wgEvent('openFreeWebeditorModal', () => {
    closeAllModals()
    resetModal()
    openModal('freeWebeditorModal');
  })
})
</script>

<template>
   <Teleport to="body">
     <div class="modal modal-lg fade" id="freeWebeditorModal" tabindex="-1" aria-labelledby="freeWebeditorModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body">
            <Button @click="resetModal" variant="link" color="gray" size="medium" class="modal-close" data-bs-dismiss="modal" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>' />
            <div class="b-free-webeditor-modal-content">
              <div class="b-free-webeditor-modal-content__title" v-html="responseData?.success ? $t('free_webeditor_modal.success_title') : $t('free_webeditor_modal.title')"></div>
              <div class="b-free-webeditor-modal-content__description" v-html="responseData?.success ? $t('free_webeditor_modal.success_description') : $t('free_webeditor_modal.description') "></div>
              <div v-if="!responseData?.success" class="b-free-webeditor-modal-content__input">
                <NxtInput v-model="form.email"  type="email" :label="$t('free_webeditor_modal.input_label')" :is-required="true" name="username" :v="v$.email" :error-msg="responseData?.errorMessage" :autofocus="true" />
                <Button 
                  class="b-free-webeditor-modal-content__submit-button" 
                  :label="$t('free_webeditor_modal.button')"
                  color="orange"
                  :loading="isLoading"
                  @click="onSubmit"
                >
                </Button>
              </div>
              <Checkbox
                v-if="!responseData?.success"
                @on-change="checked => form.acceptance = checked || ''"
                v-model="form.acceptance" 
                :label="$t('free_webeditor_modal.terms')"
                class="b-free-webeditor-modal-content__checkbox"
                :v="v$.acceptance"
                :show-error="false"
              />
              <span v-if="!responseData?.success" class="b-free-webeditor-modal-content__comment">{{ $t('free_webeditor_modal.comment') }}</span>
              <Button 
                v-if="responseData?.success"
                class="b-free-webeditor-modal-content__webeditor-button"
                :label="$t('free_webeditor_modal.button_success')"
                color="orange"
                size="big"
                :href="responseData?.url"
                target="_blank"
                @click="closeAllModals()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>