<script setup>
import { useCheckoutStore } from '@/stores/checkout.store'
import { useGlobalStore } from '@/stores/global.store'
import { ref, computed, onMounted } from 'vue'
import { t } from '@/i18n-instance'
import { closeAllModals, generateDatalayerCheckoutItem, simpleClone } from '@/utils'
import Button from '@/stories/Button.vue'
import BlockButtonWrapper from '@/stories/block-button-wrapper/BlockButtonWrapper.vue'
import { useDataLayer } from '@/dataLayer.js'

const props = defineProps({
  product: Object,
  productId: {
    type: Number,
    required: true
  },
  count: {
    type: Number,
    required: true
  },
  useMobileVersion: {
    type: Boolean,
    default: false
  }
})

const $global = useGlobalStore()
const $checkout = useCheckoutStore()
const { userSelectLicenceQuantity } = useDataLayer()

const modal = ref(null)
const maxLimit = ref(500)
const tempLicenses = ref(props.count)
const generalConfig = computed(() => $global.getConfigBySlug('general'));

const setTempLicenses = (licenses) => {
  if (licenses < 1 || licenses > maxLimit.value) {
    return
  }
  tempLicenses.value = licenses
}

const updateLicenseCount = async (count) => {
  if (count < 1 || count > maxLimit.value) {
    return
  }
  const newProduct = simpleClone(props.product)
  const newPrice = newProduct.price_final / newProduct.licences_count * count
  const price_base = newProduct.price_base / newProduct.licences_count * count

  newProduct.price_final = newPrice
  newProduct.price_base = price_base
  newProduct.licences_count = count
  newProduct.discount = newProduct.price_base - newProduct.price_final
  userSelectLicenceQuantity(props.product, count, newProduct)
  await $checkout.updateLicenseCount({index: props.productId, count});
}

const licensesText = computed(() => {
  if(props.count <= 1) {
    return `${props.count} ${t('oneLicence')}`
  }
  if(props.count >= 2 && props.count <= 4) {
    return `${props.count} ${t('twoToFourLicences')}`
  }
  return `${props.count} ${t('fiveAndMoreLicences')}`
})

const iconPlus = window.webGlobe.global.getSvgDataForImageSrc(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><g fill="#2C57DE" fill-rule="evenodd" clip-path="url(#a)" clip-rule="evenodd"><path d="M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2ZM.665 8a7.333 7.333 0 1 1 14.667 0A7.333 7.333 0 0 1 .666 8Z"/><path d="M4.666 8c0-.368.298-.667.667-.667h5.333a.667.667 0 1 1 0 1.334H5.333A.667.667 0 0 1 4.666 8Z"/><path d="M8 4.667c.369 0 .667.298.667.666v5.334a.667.667 0 1 1-1.333 0V5.333c0-.368.298-.666.667-.666Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs></svg>`);
const iconMinus = window.webGlobe.global.getSvgDataForImageSrc(`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><g fill="#2C57DE" fill-rule="evenodd" clip-path="url(#a)" clip-rule="evenodd"><path d="M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2ZM.665 8a7.333 7.333 0 1 1 14.667 0A7.333 7.333 0 0 1 .666 8Z"/><path d="M4.666 8c0-.368.298-.667.667-.667h5.333a.667.667 0 1 1 0 1.334H5.333A.667.667 0 0 1 4.666 8Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs></svg>`);

onMounted(() => {
  if(modal.value) {
    modal.value.addEventListener('hidden.bs.modal', _event => {
      setTempLicenses(null)
    })
  }
})
</script>

<template>
  <div v-if="props.useMobileVersion" ref="modal" class="modal fade" :id="`licencesModal-${props.productId}`" tabindex="-1" :aria-labelledby="`licencesModal-${props.productId}`" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <Button variant="link" color="gray" size="medium" class="modal-close" @click="closeAllModals" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>' />
          <div class="modal-checkout-options">
            <div class="modal-checkout-options__title">{{ $t('checkout.numberOfLicences') }}</div>
            <div class="modal-checkout-options__license">
              <div class="modal-checkout-options__license-outer b-button b-button--outline b-button--light b-button--medium b-button--if-label">
                <div class="modal-checkout-options__license-inner b-button__label">
                  <button
                    class="modal-checkout-options__license-handler b-button b-button--link b-button--blue"
                    @click="setTempLicenses(tempLicenses - 1)"
                    :class="{ disabled: tempLicenses <= 1 }"
                    :disabled="tempLicenses <= 1"
                  >
                    <img :src="iconMinus">
                  </button>
                  <div class="flex-fill">{{ tempLicenses }}</div>
                  <button
                    class="modal-checkout-options__license-handler b-button b-button--link b-button--blue"
                    @click="setTempLicenses(tempLicenses + 1)"
                    :class="{ disabled: tempLicenses === maxLimit }"
                    :disabled="tempLicenses === maxLimit"
                  >
                    <img :src="iconPlus">
                  </button>
                </div>
              </div>
            </div>
            <BlockButtonWrapper desktopPosition="center" mobilePosition="center">
              <Button
                :label="$t('dismiss')"
                variant="outline"
                color="blue"
                size="medium"
                @click="closeAllModals"
              />
              <Button
                :label="$t('save_data')"
                variant="primary"
                color="blue"
                size="medium"
                @click="() => {
                  updateLicenseCount(tempLicenses)
                  closeAllModals()
                }"
              />
            </BlockButtonWrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="b-button b-button--outline b-button--light b-button--small b-button--if-label px-10 cursor-auto">
    <div class="b-button__label d-flex align-items-center gap-5">
      <div class="pe-5">{{ licensesText }}</div>
        <button
          :class="{ disabled: props.count <= 1 }"
          class="b-button b-button--link b-button--blue p-0"
          @click="updateLicenseCount(props.count - 1)"
        >
          <img :src="iconMinus">
        </button>
        <button
          class="b-button b-button--link b-button--blue p-0"
          @click="updateLicenseCount(props.count + 1)"
         >
          <img :src="iconPlus">
        </button>
    </div>
  </div>
</template>
