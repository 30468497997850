<script setup>
import { ref, inject } from 'vue';
import Button from '@/stories/Button.vue';
import TldItem from './TldItem.vue';
import TransferWrap from '../transfer-wrap/TransferWrap.vue';
import InvalidInput from './InvalidInput.vue';
import OnlyFree from '../../search-wrap/toggle-area/OnlyFree.vue'
import { useDasStore } from '@/stores/das.store';
import { useInfiniteScroll } from '@vueuse/core'
import { useCheckoutStore } from '@/stores/checkout.store'

const $checkout = useCheckoutStore()
const dasStore = useDasStore();
const componentId = inject('componentId')

const el = ref(null)

useInfiniteScroll(
  el,
  () => {
    // if (dasStore.getDas(componentId).activeTldList.length === dasStore.getDas(componentId).resultList.length) return
    const das = dasStore.getDas(componentId);
    if (das.activeTldList.length === das.resultList.length) return;
    dasStore.updateResultList(componentId)
  },
  { distance: 10 }
)

const handleContinueToCheckout = () => {
  window.location.href = '/checkout?step=1'
}
</script>

<template>
  <div
    class="b-das__result-wrap"
    :class="{ 'b-das__result-wrap--populated': dasStore.getResults(componentId).length }"
  >
    <template v-if="dasStore.getResults(componentId).length">
      <div class="result-inner" :class="{active : !dasStore.getDas(componentId).transfer}">
        <OnlyFree v-if="dasStore.getDas(componentId).onlyFreeToggle" class="d-md-none pt-10 ps-10" />
        <div v-if="dasStore.getDas(componentId).invalidInput" class="b-das__invalid-domain">
          <InvalidInput />
        </div>
        <div ref="el" class="result-content">
          <div v-if="dasStore.getDas(componentId).preferred_domain" class="preferred">
            <TldItem :tld="dasStore.getTldObject(dasStore.getDas(componentId).preferred_domain, componentId)" />
            <div class="title">{{ $t('similar') }}</div>
          </div>
          <TldItem v-for="(tld, index) in dasStore.getResults(componentId)" :key="tld.tl_name" :tld="tld" :index="index" />
        </div>
        <div
          class="result-bottom"
          :class="{ 'result-bottom--has-cart-items': $checkout.getCartItems.length > 0 && !dasStore.isCdas(componentId) }"
        >
          <p class="result-bottom__disclaimer">{{ dasStore.getDas(componentId).config?.content?.bottom_disclaimer || $t('bottom_disclaimer') }}</p>
          <Button
            class="d-md-none"
            v-if="$checkout.getCartItems.length > 0 && !dasStore.isCdas(componentId)"
            @click="handleContinueToCheckout"
            :label="dasStore.getDas(componentId).config?.content?.continue_order || $t('continue_order')"
            variant="primary"
            color="orange"
            size="small"
            mobile-size="medium"
          />
          <Button
            class="d-none d-md-flex"
            v-if="$checkout.getCartItems.length > 0 && !dasStore.isCdas(componentId)"
            @click="handleContinueToCheckout"
            :label="dasStore.getDas(componentId).config?.content?.continue_order || $t('continue_order')"
            variant="primary"
            color="white"
            size="small"
            mobile-size="medium"
          />
        </div>
      </div>
      <div class="result-inner" :class="{ active : dasStore.getDas(componentId).transfer }">
        <div class="result-content">
          <TransferWrap />
        </div>
      </div>
    </template>
  </div>
</template>
