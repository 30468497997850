<script setup>
import { ref, onMounted, computed } from 'vue';
import {getPeriodLabel, getPriceLabel} from '@/utils/checkout';
import { useCheckoutStore } from '@/stores/checkout.store';
import { useGlobalStore } from '@/stores/global.store';
import Button from '@/stories/Button.vue';
import BlockButtonWrapper from '@/stories/block-button-wrapper/BlockButtonWrapper.vue'
import { closeAllModals, generateDatalayerCheckoutItem, simpleClone } from '@/utils'
import { useDataLayer } from '@/dataLayer.js';

const props = defineProps({
  product: Object,
  productId: Number,
  period: Number,
  useMobileVersion: {
    type: Boolean,
    default: false
  }
})
const $global = useGlobalStore();
const $ = useCheckoutStore();
const { periods: periodsDl } = useDataLayer();
const generalConfig = computed(() => $global.getConfigBySlug('general'));
const buttonRef = ref(null);
const isOpen = ref(false);

const isDomain = computed(() => props.product?.update_pack === 'registration');

const supportedPeriods = computed(() => {
  return isDomain.value ? simpleClone(prices.value) : simpleClone(props.product.supported_periods)
});

const periods = computed(() => {
  const allSupportedPeriods = Object.keys(supportedPeriods.value).map(period => supportedPeriods.value[period]);

  if (props.product && props.product.allowed_periods) {
    const allowedPeriods = props.product.allowed_periods.split(',').map(Number);
    return allSupportedPeriods.filter(period => allowedPeriods.includes(period.period));
  }
  return allSupportedPeriods;
});

const prices = computed(() => {
  return Object.keys(props.product.prices).reduce((result, period) => {
    result[Number(period)] = {
      period: Number(period),
      base_price: props.product.prices[period].base_price,
      final_price: props.product.prices[period].customer_price
    };
    return result;
  }, {});
});

const isDiscountedPeriod = (period) => {
  return period.base_price > period.final_price
}

const getPeriodDiscount = (period) => {
  return Number(((period.base_price - period.final_price) / period.base_price) * 100 * -1).toFixed(0)
};

const updatePeriod = async (period) => {
  const [_, domainTld] = props.product?.name?.split('.')
  periodsDl.userSelectTimePeriod(props.product, period, supportedPeriods.value[period]?.final_price)
  await $.updatePeriod({index: props.productId, period});
}

const isSelectable = computed(() => Object.keys(supportedPeriods.value).length)

const modal = ref(null)

const tempPeriod = ref(null)

const setTempPeriod = (period) => {
  tempPeriod.value = period
}

onMounted(() => {
  if (buttonRef.value) {
    buttonRef.value.addEventListener('show.bs.dropdown', _event => {
      isOpen.value = true;
      periodsDl.userOpenTimePeriod(props.product)
    })
    buttonRef.value.addEventListener('hidden.bs.dropdown', _event => {
      isOpen.value = false;
    })
  }
  if(modal.value) {
    modal.value.addEventListener('hidden.bs.modal', _event => {
      setTempPeriod(null)
    })
  }
})
</script>

<template>
  <!-- MOBILE PERIODS MODAL -->
  <div ref="modal" v-if="props.useMobileVersion" class="modal fade" :id="`mobilePeriods-${props.productId}`" tabindex="-1" :aria-labelledby="`mobilePeriods-${props.productId}`" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <Button
            variant="link"
            color="gray"
            size="medium"
            class="modal-close"
            @click="closeAllModals"
            iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>'
          />
          <div class="modal-checkout-options">
            <div class="modal-checkout-options__title">{{ $t('checkout.periods.title') }}</div>
            <div class="modal-checkout-options__items">
              <div
                v-for="period of periods"
                :key="`mobile-${period.period}`"
                class="b-checkout-order__option"
                :class="{ active: tempPeriod ? period.period === tempPeriod : props.period === period.period }"
                @click="setTempPeriod(period.period)"
              >
                <div class="b-checkout-order__option-title">{{ getPeriodLabel(period.period) }}</div>
                <div class="b-checkout-order__option-price">
                  <div v-if="isDiscountedPeriod(period) && !isDomain" class="b-badge b-badge--twelfth">{{ getPeriodDiscount(period) }}%</div>
                  <span v-if="isDiscountedPeriod(period)" class="b-checkout-order__option-price--old">{{ getPriceLabel(period.base_price) }}</span>
                  <span>{{ getPriceLabel(period.final_price) }}</span>
                </div>
              </div>
            </div>
            <BlockButtonWrapper desktopPosition="center" mobilePosition="center">
              <Button
                :label="$t('dismiss')"
                variant="outline"
                color="blue"
                size="medium"
                @click="closeAllModals"
              />
              <Button
                :label="$t('save_data')"
                variant="primary"
                color="blue"
                size="medium"
                @click="() => {
                  updatePeriod(tempPeriod)
                  closeAllModals()
                }"
              />
            </BlockButtonWrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- DESKTOP DROPDOWN -->
  <div v-else class="dropdown dropdown--periods" :class="{'dropdown--opened': isOpen }" @click="openDropDown">
    <button
      ref="buttonRef"
      class="b-button b-button--dropdown b-button--outline b-button--light b-button--small b-button--if-label px-10"
      :class="{ 'b-button--disabled': !isSelectable }"
      type="button"
      v-bind="isSelectable && {
        'data-bs-toggle':'dropdown',
        'aria-expanded': false
      }"
    >
      <span class="b-button__label">{{ getPeriodLabel(period) }}</span>
    </button>
    <div v-if="isSelectable" class="dropdown-menu">
      <template v-for="period in periods" :key="`desktop-${period.period}`">
        <div @click="updatePeriod(period.period)" class="b-checkout-order__option">
          <div class="b-checkout-order__option-title">{{ getPeriodLabel(period.period) }}</div>
          <div class="b-checkout-order__option-price">
            <div v-if="isDiscountedPeriod(period) && !isDomain" class="b-badge b-badge--twelfth">{{ getPeriodDiscount(period) }}%</div>
            <span v-if="isDiscountedPeriod(period)" class="b-checkout-order__option-price--old">{{ getPriceLabel(period.base_price) }}</span>
            <span>{{ getPriceLabel(period.final_price) }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
