<template>
  <svg class="icon-user" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path fill="#315EED" fill-rule="evenodd"
      d="M10 3.333A3.333 3.333 0 1 0 10 10a3.333 3.333 0 0 0 0-6.667ZM5 6.667a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
      clip-rule="evenodd" />
    <path fill="#315EED" fill-rule="evenodd"
      d="M4.697 12.197A7.5 7.5 0 0 1 17.5 17.5a.833.833 0 0 1-1.667 0 5.833 5.833 0 1 0-11.666 0 .833.833 0 0 1-1.667 0 7.5 7.5 0 0 1 2.197-5.303Z"
      clip-rule="evenodd" />
  </svg>
</template>
