<script setup>
import { onMounted, computed, ref} from "vue";
import { useCheckoutStore } from "@/stores/checkout.store";
import Button from '@/stories/Button.vue';
import Periods from './Periods.vue';
import TitleBanner from '@/stories/_BASE/TitleBanner.vue';
import Grid from "@/stories/_G-COMPONENTS/grid/Grid.vue";
import Wysiwyg from "@/stories/_G-COMPONENTS/wysiwyg/Wysiwyg.vue";
import Licenses from '@/components/checkout/components/Licenses.vue';
import WarningModal from '@/components/shared/WarningModal.vue'
import MobileActionsDropdown from './MobileActionsDropdown.vue'
import IconInfoBlack from '@/components/checkout/components/icon/InfoBlack.vue'

import AdditionalProduct from './AdditionalProduct.vue';

import {getPriceLabel} from '@/utils/checkout';
import {generateDatalayerItem} from '@/utils';
import {useDataLayer} from '@/dataLayer';

const iconPlusBtn = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none"><path fill="#fff" fill-rule="evenodd" d="M19.694 11c0 .506-.41.917-.916.917H3.22a.917.917 0 0 1 0-1.834h15.557c.506 0 .916.41.916.917Z" clip-rule="evenodd"/><path fill="#fff" fill-rule="evenodd" d="M11 2.305c.506 0 .916.41.916.917v15.556a.917.917 0 1 1-1.833 0V3.222c0-.506.41-.917.916-.917Z" clip-rule="evenodd"/></svg>`;
const iconRemove = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="#2C57DE" fill-rule="evenodd" d="M1.666 5c0-.46.373-.833.833-.833h15a.833.833 0 1 1 0 1.666h-15A.833.833 0 0 1 1.666 5Z" clip-rule="evenodd"/><path fill="#2C57DE" fill-rule="evenodd" d="M4.167 4.167c.46 0 .834.373.834.833v11.667c0 .145.08.36.276.556.196.196.411.277.557.277h8.333c.146 0 .361-.081.557-.277.195-.195.277-.41.277-.556V5a.833.833 0 1 1 1.666 0v11.667c0 .687-.335 1.305-.765 1.735-.429.43-1.047.765-1.735.765H5.834c-.688 0-1.306-.336-1.735-.765-.43-.43-.765-1.048-.765-1.735V5c0-.46.373-.833.833-.833Z" clip-rule="evenodd"/><path fill="#2C57DE" fill-rule="evenodd" d="M7.777 2.777c-.195.195-.276.41-.276.556V5a.833.833 0 1 1-1.667 0V3.333c0-.687.335-1.305.765-1.735.43-.43 1.047-.765 1.735-.765h3.333c.688 0 1.306.336 1.735.765.43.43.765 1.048.765 1.735V5a.833.833 0 1 1-1.666 0V3.333c0-.145-.082-.36-.277-.556-.196-.196-.411-.277-.557-.277H8.334c-.146 0-.361.081-.557.277ZM8.333 8.333c.46 0 .834.373.834.834v5a.833.833 0 0 1-1.667 0v-5c0-.46.373-.834.833-.834ZM11.667 8.333c.46 0 .834.373.834.834v5a.833.833 0 0 1-1.667 0v-5c0-.46.373-.834.833-.834Z" clip-rule="evenodd"/></svg>`

const props = defineProps({
  product: Object,
})

const $ = useCheckoutStore();
const dataLayer = useDataLayer();

const discount = computed(() => {
  return (((props.product.price_base - props.product.price_final) / props.product.price_base) * 100 * -1) || 0
});

const isSelectCategory = computed(() => props.product.category === 'select');

const addToBasket = async () => {

  const items = props.product.iframe_body
  if (items) {
    const data = []
    Object.keys(items).forEach((key) => {
      data.push(generateDatalayerItem(items[key].item, items[key].item))
    });
    window.webGlobe.dataLayer.sendData({
       event: 'view_item_list',
       item_list_id: `orderModal${uniqueId.value}`,
       item_list_name: `orderModal${uniqueId.value}`,
       items: data,
     })
    dataLayer.upSellCartOpen(props.product)
  }
}

const removeFromBasket = async () => {
  await $.removeFromBasket({index: props.product.id});
}
const containers = ref({});

const uniqueId = computed(() => `${props.product.update_pack}${props.product.update_name.replace('.', '')}`);

const setContainerRef = (item, index) => {
  return el => {
    if (el) {
      containers.value[index] = el;
    }
  };
};

const itemsForDatalayer = computed(() => {
  const items = props.product.iframe_body
  if (!items) return {}
  const data = []
  Object.keys(items).forEach((key) => {
    data.push(key);
  });
  return data
});

onMounted(() => {
  if (props.product.iframe_body) {
    Object.entries(props.product.iframe_body).forEach(([index, item]) => {
    const container = containers.value[index];
    if (container && item.html) {
      // Použitie `template` elementu pre zachovanie HTML obsahu
      const template = document.createElement('template');
      template.innerHTML = item.html.trim(); // Trim na odstránenie bielych miest, ktoré môžu spôsobiť problémy

      // Výber prvého elementu z template obsahu (v tomto prípade predpokladáme, že HTML obsahuje práve jeden hlavný element)
      const content = template.content.firstChild;

      if (content) {
        container.replaceWith(content);
      }
    }
  });
  }
})

const isHandlingPeriods = computed(() => props.product?.supported_periods && Object.keys(props.product.supported_periods).length > 1)
const isHandlingLicences = computed(() => props.product.licences_count)
</script>

<template>
<div v-show="!product.show_in_checkout_hidden" class="b-checkout-order__subservice" :class="{ 'b-product-in-basket': product.in_basket }">
  <div class="b-checkout-order__subservice-item">
    <div class="b-checkout-order__header b-checkout-order__header--subservice" :class="{ 'b-checkout-order__header--alone': !product.subitems }">
      <div v-if="product.icon" class="b-checkout-order__header-icon" v-html="product.icon"></div>
      <div
        class="b-checkout-order__header-title"
        :class="{
          'b-checkout-order__header-title--period': props.product?.supported_periods,
          'b-checkout-order__header-title--license': isHandlingLicences
        }"
      >
        <h3 class="b-checkout-order__header-title-h3" v-html="product.name"></h3>
        <p v-if="product.description" class="b-checkout-order__p" v-html="product.description"></p>
      </div>

      <!-- DESKTOP OPTIONS -->
      <div
        v-if="product.in_basket"
        class="b-checkout-order__options d-none d-xl-flex"
        :class="{ 'pe-none': $.updatingBasket }"
      >
        <Licenses v-if="isHandlingLicences" :product-id="product.id" :count="product.licences_count" :product="product"/>
        <Periods :period="product.period" :product-id="product.id" :product="product" />
      </div>

      <div v-if="product.in_basket" class="b-checkout-order__price">
        <div class="b-checkout-order__price-inner">
          <div class="b-checkout-order__price-inner-upper">
            <span v-if="discount" class="b-checkout-order__price--old" :class="{'pulse': product.isUpdating}">{{ getPriceLabel(product.price_base) }}</span>
            <span class="b-checkout-order__price--main" :class="{'pulse': product.isUpdating}">{{ getPriceLabel(product.price_final) }}</span>
            <IconInfoBlack
              v-if="product.price_info"
              data-bs-toggle="tooltip" data-bs-placement="top"
              data-bs-custom-class="custom-tooltip"
              :data-bs-title="product.price_info"
            />
            <Button
              data-bs-toggle="modal"
              :data-bs-target="`#removeProductModal-${product.id}`"
              variant="link"
              size="small"
              color="blue"
              class="button-remove p-0 d-none d-xl-flex"
              :class="{ 'pe-none': $.updatingBasket }"
              :iconLeft='iconRemove'
            />
            <MobileActionsDropdown
              v-if="product.in_basket"
              :product-id="props.product.id"
              :is-handling-periods="isHandlingPeriods"
              :is-handling-licences="isHandlingLicences"
              :class="{ 'pe-none': $.updatingBasket }"
            />
          </div>
          <div v-if="product.price_text_under" class="b-checkout-order__price-inner-lower">
            <span class="b-checkout-order__price--info" v-html="product.price_text_under"></span>
          </div>
        </div>
      </div>
      <div
        v-if="!product.in_basket"
        class="b-checkout-order__header-action"
        :class="{ 'pe-none': $.updatingBasket }"
      >
        <Button v-if="isSelectCategory" @click="addToBasket" variant="primary" color="blue" size="small" data-bs-toggle="modal" :data-bs-target="`#orderModal${uniqueId}`" :iconLeft="iconPlusBtn" class="px-18"></Button>
        <Button v-else @click="addToBasket" variant="primary" color="blue" size="small" :iconLeft="iconPlusBtn" class="px-18"></Button>
      </div>
    </div>
    <div v-if="product.subitems" class="b-checkout-order__content b-checkout-order__content--subservice">
      <AdditionalProduct v-for="(item, index) in product.subitems" :key="index" :product="item" />
    </div>
  </div>
</div>
<Teleport to="body">
  <div v-if="isSelectCategory" class="modal modal--ignore-custom-top-offset fade order-modal-special" :id="'orderModal'+uniqueId" tabindex="-1" aria-labelledby="orderModalLabel" aria-hidden="true" :data-view-items="JSON.stringify({item1:'name'})">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <Button variant="link" color="gray" size="medium" class="modal-close" data-bs-dismiss="modal" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>' />
          <TitleBanner
            :title="product.iframe_title"
            titleTag="h4"
            titleSize=""
            titleContainerSize="md"
            :text="product.iframe_text"
            textContainerSize="md"
            justifyContent="center"
            alignContent="center"
            alignContentMobile="center"
            buttonsOnlyStorybook="none"
            buttonsVisibility="both"
          />
          <Grid
            columnDesktop="1"
            columnMobile="1"
            desktopSpacingX="10"
            desktopSpacingY="10"
            mobileSpacingX="10"
            mobileSpacingY="10"
          >

            <Grid
              :columnDesktop="Object.keys(product.iframe_body).length"
              columnMobile="1"
              desktopSpacingX="30"
              desktopSpacingY="30"
              mobileSpacingX="10"
              mobileSpacingY="10"
            >
              <div v-for="(item, index) in product.iframe_body" :key="index" :ref="setContainerRef(item, index)"></div>
            </Grid>

            <Wysiwyg style="text-align: center; font-size: 11px; color: #7D7F8A;">
              <p>{{ product.iframe_footer }}</p>
            </Wysiwyg>
          </Grid>
        </div>
      </div>
    </div>
  </div>
</Teleport>
<Teleport to="body">
  <WarningModal
    v-if="props.product.remove_modal && props.product.in_basket"
    :id="`removeProductModal-${props.product.id}`"
    :title="props.product.remove_title"
    :description="props.product.remove_desc"
    :primary-button="$t('remove')"
    :secondary-button="$t('back')"
    @on-primary-action="removeFromBasket"
    severity="warning"
  />
  <Periods
    v-if="isHandlingPeriods"
    :period="product.period"
    :product-id="product.id"
    :use-mobile-version="true"
    :product="product"
  />
  <Licenses
    v-if="isHandlingLicences"
    :product-id="product.id"
    :count="product.licences_count"
    :use-mobile-version="true"
    :product="product"
  />
</Teleport>
</template>
