import { submitNewsletter } from "@/services/newsletter.service"
import { t } from '@/i18n-instance'

const MAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const newsletter = {
  init() {
      const newsletterForms = [...document.querySelectorAll('.b-newsletter')]
      newsletterForms.forEach(form => {
        const emailInputGroup = form.querySelector('.b-form__group')
        const emailInput = emailInputGroup.querySelector('.b-form-input > input')
        const errorMessage = emailInputGroup.querySelector('.b-form__helper-text')
        const submitButton = form.querySelector('.b-newsletter__submit-button')
        // const confirmButton = form.querySelector('.b-newsletter__button-confirm')
      
        submitButton.addEventListener('click', async () => {
          const emailInputValue = emailInput.value

          if(!emailInputValue) {
            emailInputGroup.classList.add('b-form__group--error')
            errorMessage.innerHTML = t('validations.required')
            errorMessage.classList.remove('d-none')
            return
          }

          const isEmailInvalid = !emailInputValue || !MAIL_REGEX.test(emailInputValue)
        
          if(isEmailInvalid) {
            emailInputGroup.classList.add('b-form__group--error')
            errorMessage.innerHTML = t('validations.email')
            errorMessage.classList.remove('d-none')
            return
          }
        
          submitButton.classList.add('b-button--loading')
          emailInputGroup.classList.remove('b-form__group--error')
          errorMessage.classList.add('d-none')

          const resetNewsletter = () => {
            emailInput.value = ''
            emailInputGroup.classList.remove('b-form__group--error')
            errorMessage.classList.add('d-none')
            form.classList.remove('b-newsletter--is-sent')
          }

          let successTimeout

          try {
            const response = await submitNewsletter(emailInputValue)
            if(response.data.success) {
              form.classList.add('b-newsletter--is-sent')
              successTimeout = setTimeout(() => {
                resetNewsletter()
                return clearTimeout(successTimeout)
              }, 3000)
            } else {
              emailInputGroup.classList.add('b-form__group--error')
              errorMessage.innerHTML = response.data.error.message
              errorMessage.classList.remove('d-none')
            }
          } catch(error) {
            errorMessage.classList.remove('d-none')
            if(error?.response?.data?.message) {
              errorMessage.innerHTML = error.response.data.message
            }
            if(error?.response?.data?.error?.message) {
              errorMessage.innerHTML = error.response.data.error.message
            }
          } finally {
            submitButton.classList.remove('b-button--loading')
          }
        })
        
        // might change mind later
        // confirmButton.addEventListener('click', () => {
        //   resetNewsletter()
        // })
      })
  }
}