export const megaMenu = {
  init() {
    const megaMenu = document.querySelector('#megamenuNavbar');
    if (!megaMenu) return;

    const myMegaMenuMobileCollapse = document.getElementById('navbarMainMenu');
    myMegaMenuMobileCollapse.addEventListener('shown.bs.collapse', _event => {
      document.body.classList.add('mobile-overflow-hidden');
    });
    myMegaMenuMobileCollapse.addEventListener('hidden.bs.collapse', _event => {
      document.body.classList.remove('mobile-overflow-hidden');
    });

    const myCollapses = megaMenu.querySelectorAll('.b-mega-menu.collapse')
    myCollapses.forEach(myCollapse => {
      const handleClickOutside = function(e) {
        if (!megaMenu.contains(e.target)) {
          const bsCollapse = bootstrap.Collapse.getOrCreateInstance(myCollapse);
          bsCollapse.hide();
          window.removeEventListener('click', handleClickOutside);
        }
      };

      myCollapse.addEventListener('shown.bs.collapse', _event => {
        window.removeEventListener('click', handleClickOutside); // Ensure no duplicate listeners
        window.addEventListener('click', handleClickOutside);
      });

      myCollapse.addEventListener('hidden.bs.collapse', _event => {
        window.removeEventListener('click', handleClickOutside);
      });

    });

  },
  closeAll() {
    const megaMenu = document.querySelector('#megamenuNavbar');
    if (!megaMenu) return;

    const myCollapses = megaMenu.querySelectorAll('.b-mega-menu.collapse')
    myCollapses.forEach(myCollapse => {
      const bsCollapse = bootstrap.Collapse.getInstance(myCollapse);
      bsCollapse?.hide();
    });
  }
}
