"use strict"

import Swiper from 'swiper/bundle';

class MySwiper {
  constructor() {
  }

  defaultOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    centeredSlides: true,
    loop: true,
    breakpoints: {
      768: {
        spaceBetween: 30,
        centeredSlides: false,
      },
    },
  }

  init = () => {
    const elements = document.querySelectorAll('[data-swiper]');

    elements.forEach(element => {
      const swiperData = element.getAttribute('data-swiper');
      const swiperOptsData = element.getAttribute('data-swiper-opts');

      // Parse the swiperOptsData as a JSON object
      let swiperOpts = {};
      if (swiperOptsData) {
        try {
          swiperOpts = JSON.parse(swiperOptsData);
        } catch (error) {
          console.error('Error parsing data-swiper-opts:', error);
        }
      }

      const options = {
        ...this.defaultOptions,
        ...swiperOpts,
        ...this[swiperData] || {},
      }

      const swiperInstance = new Swiper(element, options)

      if(options?.marquee) {
        let isDragging = false
        let marqueeInterval

        const startMarquee = () => {
          const marquee = () => {
            if (!isDragging) {
              swiperInstance.slideNext(4000, true)
            }
            marqueeInterval = requestAnimationFrame(marquee)
          }
          marqueeInterval = requestAnimationFrame(marquee)
        }

        const stopMarquee = () => {
          cancelAnimationFrame(marqueeInterval)
        }

        swiperInstance.on('touchStart', () => {
          isDragging = true
          stopMarquee()
        })

        swiperInstance.on('touchEnd', () => {
          isDragging = false
          startMarquee()
        })

        swiperInstance.on('init', () => {
          isDragging = false
          startMarquee()
        })

        swiperInstance.init()
      }


      if(options?.id) {
        window[options.id] = swiperInstance;
      }

      if (options.id && options.swipesCount) {

        const controlsWrapper = document.getElementById(`swiperControls-${options.id}`);

        if (controlsWrapper) {
          initSwipeControls({ controlsWrapper, swiperInstance: window[options.id], swipesCount: options.swipesCount });
        }
      } else {
        new Swiper(element, options);

        if(!options.id) {
          console.warn('Swiper instance options.id is not defined');
        }
        if(!options.swipesCount) {
          console.warn('Swiper instance options.swipesCount is not defined');
        }
      }
    });
  }
}

export const swiper = new MySwiper();

const initSwipeControls = ({ controlsWrapper, swiperInstance, swipesCount }) => {
  const firstSwipeIndicator = controlsWrapper.querySelector('.b-pagination__link--first');
  const currentSwipeIndicator = controlsWrapper.querySelector('.b-pagination__link--current');
  const lastSwipeIndicator = controlsWrapper.querySelector('.b-pagination__link--last');
  const prefixMoreSwipesIndicator = controlsWrapper.querySelector('.b-pagination__dots--prefix');
  const suffixMoreSwipesIndicator = controlsWrapper.querySelector('.b-pagination__dots--suffix');

  const isControlsSetUp = () => 
    [
      firstSwipeIndicator,
      currentSwipeIndicator,
      lastSwipeIndicator,
      prefixMoreSwipesIndicator,
      suffixMoreSwipesIndicator
    ].every(Boolean) && swiperInstance && swipesCount

  if(isControlsSetUp) {
    return
  }
  
  lastSwipeIndicator.addEventListener('click', e => {
    e.preventDefault()
    const lastSlideIndex = parseInt(lastSwipeIndicator.textContent) - 1
    swiperInstance.slideToLoop(lastSlideIndex)
  })

  firstSwipeIndicator.addEventListener('click', e => {
    e.preventDefault()
    const firstSlideIndex = parseInt(firstSwipeIndicator.textContent) - 1
    swiperInstance.slideToLoop(firstSlideIndex)
  })

  // Function to update the current swipe indicator's text/content
  const updateCurrentSwipeIndicator = (realIndex) => {
      currentSwipeIndicator.textContent = realIndex;
  };  
  // Initial setup for the first slide
  if (swiperInstance.realIndex === 1) {
      prefixMoreSwipesIndicator.classList.add('d-none');
      currentSwipeIndicator.classList.add('d-none');
      firstSwipeIndicator.classList.add('b-pagination__link--current');
  } else {
      updateCurrentSwipeIndicator(swiperInstance.realIndex);
  }   

  swiperInstance.on('slideChange', _e => {
    const realIndex = swiperInstance.realIndex + 1;   

    firstSwipeIndicator.classList.remove('b-pagination__link--current');
    currentSwipeIndicator.classList.remove('d-none');
    lastSwipeIndicator.classList.remove('b-pagination__link--current');
    prefixMoreSwipesIndicator.classList.remove('d-none');
    suffixMoreSwipesIndicator.classList.remove('d-none');   

    if (realIndex === 1) {
        prefixMoreSwipesIndicator.classList.add('d-none');
        currentSwipeIndicator.classList.add('d-none');
        firstSwipeIndicator.classList.add('b-pagination__link--current');
    } else if (realIndex === swipesCount) {
        suffixMoreSwipesIndicator.classList.add('d-none');
        currentSwipeIndicator.classList.add('d-none');
        lastSwipeIndicator.classList.add('b-pagination__link--current');
    } else {
        // Handle case for slides in between
        currentSwipeIndicator.classList.add('b-pagination__link--current');
        updateCurrentSwipeIndicator(realIndex);
        if (realIndex > 1 && realIndex < swipesCount) {
            currentSwipeIndicator.classList.remove('d-none');
        }
    }
  });
}

function simulateSwipe(swiper, startX, endX) {
  const container = swiper.el;

  // Create mouse event objects
  const startEvent = new MouseEvent('mousedown', {
    clientX: startX,
  });

  const moveEvent = new MouseEvent('mousemove', {
    clientX: endX,
  });

  const endEvent = new MouseEvent('mouseup', {
    clientX: endX,
  });

  // Dispatch the events to the container
  container.dispatchEvent(startEvent);
  container.dispatchEvent(moveEvent);
  container.dispatchEvent(endEvent);
}
